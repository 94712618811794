import { memo, useEffect, useRef, useState } from 'react'
import {
  selectLocalPeerID,
  // useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Client, Conversation, Message, Paginator } from '@twilio/conversations'
import { Flex, Text } from '@chakra-ui/react'
import { Chat, ChatFormProps } from './Chat'
import { Drawer } from '../drawer'
import { FullPageLoader } from '../loading'
import { messageReceived, useAppDispatch, useAppSelector } from '../../store'
import { RemoveChatUserModal } from '../modal'
// import { MOD_FUNC_PIN_CHAT_MESSAGE } from '../../constants'

type ChatStatus = 'loading' | 'success' | 'failure' | 'warning'

interface TwilioChatProps {
  isOpen: boolean
}

export const _TwilioChat = ({ isOpen }: TwilioChatProps) => {
  const localPeerId = useHMSStore(selectLocalPeerID)

  // const hmsActions = useHMSActions()

  const dispatch = useAppDispatch()

  const { twilioConfig, avatarUrl, displayName } = useAppSelector(
    (state) => state.config
  )

  const [chatStatus, setChatStatus] = useState<ChatStatus>('loading')

  const [conversation, setConversation] = useState<Conversation>()

  const [paginator, setPaginator] = useState<Paginator<Message>>()

  const [messages, setMessages] = useState<Message[]>([])

  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const [participantSidToRemove, setParticipantSidToRemove] = useState('')

  const clientRef = useRef<Client>()

  const sendMessage = (message: string) => {
    if (!conversation) return

    try {
      conversation.sendMessage(message, {
        peerId: localPeerId,
        displayName,
        avatarUrl,
      })
    } catch (err) {
      console.error(err)
    }
  }

  // const sendPinnedMessage = (message: string) => {
  //     try {
  //         hmsActions.sendBroadcastMessage(
  //             `${MOD_FUNC_PIN_CHAT_MESSAGE}${message}`
  //         )

  //         // dispatch(
  //         //     addPinnedMessage({
  //         //         id: v4(),
  //         //         sender: localPeer?.id,
  //         //         senderName: localPeer?.name,
  //         //         message: `${MOD_FUNC_PIN_CHAT_MESSAGE}${message}`,
  //         //         time: new Date(),
  //         //         read: true,
  //         //         ignored: false,
  //         //         type: '',
  //         //     })
  //         // )
  //     } catch (err) {
  //         console.error(err)
  //     }
  // }

  const onSubmit = async ({ pinMessage, message }: ChatFormProps) => {
    if (pinMessage) {
      // sendPinnedMessage(message)
      return
    }

    sendMessage(message)
  }

  const onLoadNext = async () => {
    if (!paginator) return

    if (!paginator.hasPrevPage) return

    try {
      setIsLoadingNext(true)
      const prevPaginator = await paginator.prevPage()
      setMessages((prevState) => [...prevPaginator.items, ...prevState])
      setPaginator(prevPaginator)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingNext(false)
    }
  }

  const onRemoveMessage = (messageSid: string) => {
    if (!messages) return

    try {
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].sid === messageSid) {
          messages[i].remove()
          return
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onRemoveUserFromChat = async (participantSid: string) => {
    setParticipantSidToRemove(participantSid)
  }

  const onRemoveUserConfirmation = async () => {
    if (!conversation || !participantSidToRemove) return

    try {
      await conversation.removeParticipant(participantSidToRemove)

      // toast
    } catch (err) {
      console.error(err)

      // error toast
    } finally {
      setParticipantSidToRemove('')
    }
  }

  useEffect(() => {
    if (
      twilioConfig?.token &&
      twilioConfig.conversationSid &&
      !clientRef.current
    ) {
      const connectionStateChangedHandler = (state: string) => {
        switch (state) {
          case 'connected':
            setChatStatus('success')
            break

          case 'disconnecting':
            setChatStatus('loading')
            break

          case 'disconnected':
            setChatStatus('warning')
            break

          case 'denied':
            setChatStatus('failure')
            break

          default:
          case 'connecting':
            setChatStatus('loading')
            break
        }
      }

      const conversationJoinedHandler = (_conversation: Conversation) => {
        if (_conversation.sid === twilioConfig.conversationSid) {
          setConversation(_conversation)
        }
      }

      const messageAddedHandler = (message: Message) => {
        setMessages((prevState) => [...prevState, message])
        dispatch(messageReceived())
      }

      const messageRemovedHandler = (message: Message) => {
        setMessages((prevState) =>
          prevState.filter((m) => m.sid !== message.sid)
        )
      }

      const client = new Client(twilioConfig.token)
      client.on('connectionStateChanged', connectionStateChangedHandler)
      client.on('conversationJoined', conversationJoinedHandler)
      client.on('messageAdded', messageAddedHandler)
      client.on('messageRemoved', messageRemovedHandler)

      clientRef.current = client
    }
  }, [twilioConfig, dispatch])

  useEffect(() => {
    const loadMessages = async () => {
      if (conversation) {
        try {
          const result = await conversation.getMessages()
          setPaginator(result)
          setMessages(result.items)
        } catch (error) {
          console.error(error)
        }
      }
    }

    loadMessages()
  }, [conversation])

  return (
    <>
      {isOpen ? (
        <Drawer>
          {chatStatus === 'loading' ? <FullPageLoader /> : null}

          {chatStatus === 'success' ? (
            <Chat
              messages={messages}
              hasNext={paginator ? paginator.hasPrevPage : false}
              isLoadingNext={isLoadingNext}
              onLoadNext={onLoadNext}
              onSubmit={onSubmit}
              onRemoveMessage={onRemoveMessage}
              onRemoveUserFromChat={onRemoveUserFromChat}
            />
          ) : null}

          {chatStatus === 'failure' ? (
            <Flex>
              <Text>Error joining conversation</Text>
            </Flex>
          ) : null}

          {chatStatus === 'warning' ? (
            <Flex>
              <Text>Disconnected</Text>
            </Flex>
          ) : null}
        </Drawer>
      ) : null}

      <RemoveChatUserModal
        isOpen={participantSidToRemove !== ''}
        onRemoveUserConfirmation={onRemoveUserConfirmation}
        onClose={() => setParticipantSidToRemove('')}
      />
    </>
  )
}

export const TwilioChat = memo(_TwilioChat)