import { memo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react'

interface RemoveChatUserModalProps {
  isOpen: boolean
  onRemoveUserConfirmation: () => void
  onClose: () => void
}

const _RemoveChatUserModal = ({
  isOpen,
  onRemoveUserConfirmation,
  onClose,
}: RemoveChatUserModalProps) => {
  const onRemoveUserClick = () => {
    onRemoveUserConfirmation()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} isCentered size="xl" onClose={onClose}>
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>Remove Chat User</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody paddingBottom={4}>
          <Text fontSize="lg" marginBottom={2}>
            Are you sure you want to remove this user from chat?
            This action cannot be undone.
          </Text>

          <Button
            backgroundColor="buttonDeny"
            color="white"
            marginRight={4}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={onRemoveUserClick}
          >
            Remove User
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const RemoveChatUserModal = memo(_RemoveChatUserModal)