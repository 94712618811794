// @ts-ignore
import zlib from 'react-zlib-js'
import { Buffer } from 'buffer'
import { ChangeEvent, memo, useMemo, useState } from 'react'
import {
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeers,
  selectPeersByRole,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import {
  Flex,
  Center,
  Text,
  HStack,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Checkbox,
  Switch,
  FormLabel,
} from '@chakra-ui/react'
import { ParticipantGrid } from './ParticipantGrid'
import { ControlButton, ControlButtonText } from '../button'
import { Toast } from '../toast'
import {
  ClearStageModal,
  PromoteGroupModal,
  PromoteGroupFormProps,
} from '../modal'
import { CustomMetadata, NftData, Role } from '../../models'
import {
  DisplayLayout,
  setShowClearStageModal,
  setShowPromoteGroupModal,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import {
  MOD_FUNC_BOT_TOGGLE_BROADCAST,
  MOD_FUNC_BOT_BOUNDARY,
  TOAST_DURATION,
  MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT,
} from '../../constants'

const EXCLUDED_ROLES: string[] = [
  Role.Moderator,
  Role.StageModerator,
  Role.StageMain,
  Role.StageViewer,
  Role.Bot,
]

const _ParticipantSearch = () => {
  const dispatch = useAppDispatch()

  const displayMode = useAppSelector((state) => state.config.displayMode)

  const {
    showClearStageModal,
    showPromoteGroupModal,
    broadcastPeerId,
    botDisplayLayout,
  } = useAppSelector((state) => state.app)

  const toast = useToast()

  const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)
  const peers = useHMSStore(selectPeers)
  const backstageViewerPeers = useHMSStore(
    selectPeersByRole(Role.BackstageViewer)
  )
  const moderatorPeers = useHMSStore(selectPeersByRole(Role.Moderator))
  const stageViewerPeers = useHMSStore(selectPeersByRole(Role.StageViewer))
  const stageMainPeers = useHMSStore(selectPeersByRole(Role.StageMain))
  const hmsActions = useHMSActions()

  const [searchValue, setSearchValue] = useState('')

  const [showHandsRaised, setShowHandsRaised] = useState(false)

  const [characterSearchValue, setCharacterSearchValue] = useState('')

  const [s2CharacterSearch, setS2CharacterSearch] = useState(false)

  const [showS1, setShowS1] = useState(false)

  const [showS1Spec, setShowS1Spec] = useState(false)

  const [showS1GG, setShowS1GG] = useState(false)

  const [showS1Access, setShowS1Access] = useState(false)

  const backstagePeers = [...backstageViewerPeers, ...moderatorPeers]

  const removeableStagePeers = [...stageViewerPeers, ...stageMainPeers]

  const isStageDisplayMode = displayMode !== 'standard'

  const isHost =
    localPeerRoleName === Role.Moderator ||
    localPeerRoleName === Role.StageModerator ||
    localPeerRoleName === Role.Host

  const filterApplied = useMemo(
    () =>
      searchValue !== '' ||
      showHandsRaised ||
      characterSearchValue !== '' ||
      showS1 ||
      showS1Spec ||
      showS1GG ||
      showS1Access,
    [
      searchValue,
      showHandsRaised,
      characterSearchValue,
      showS1,
      showS1Spec,
      showS1GG,
      showS1Access,
    ]
  )

  const filteredPeers = useMemo(
    () =>
      peers.filter((peer) => {
        try {
          if (!peer.metadata) return false

          const metadata = JSON.parse(peer.metadata) as CustomMetadata

          if (!metadata.nfts) return false

          const data = JSON.parse(
            zlib
              .gunzipSync(Buffer.from(metadata.nfts, 'base64'))
              .toString()
          ) as NftData

          return (
            (!searchValue ||
              peer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) &&
            (!showHandsRaised ||
              (metadata && metadata.isHandRaised)) &&
            (!characterSearchValue ||
              (s2CharacterSearch
                ? data.s2c.join().includes(characterSearchValue)
                : data.s1c
                  .join()
                  .includes(characterSearchValue))) &&
            (!showS1 || data.s1h) &&
            (!showS1Spec || data.s1spec) &&
            (!showS1GG || data.s1gg) &&
            (!showS1Access || data.s1a)
          )
        } catch (error) {
          console.error(error)
          console.log('error unzipping: ', peer.metadata)
          return false
        }
      }),
    [
      peers,
      searchValue,
      showHandsRaised,
      characterSearchValue,
      s2CharacterSearch,
      showS1,
      showS1Spec,
      showS1GG,
      showS1Access,
    ]
  )

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const onClearSearchClick = () => setSearchValue('')

  const onTraitInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCharacterSearchValue(event.target.value)
  }

  const onClearTraitSearchClick = () => setSearchValue('')

  const onS2SwitchChange = () => {
    setS2CharacterSearch((prevState) => !prevState)
  }

  const onShowS1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setShowS1(event.target.checked)
  }

  const onShowS1SpecChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowS1Spec(event.target.checked)
  }

  const onShowS1GGChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowS1GG(event.target.checked)
  }

  const onShowS1AccessChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowS1Access(event.target.checked)
  }

  const toggleShowHandsRaised = (value: boolean) => setShowHandsRaised(value)

  const onClearStageClick = () => dispatch(setShowClearStageModal(true))

  const onClearStageClose = () => dispatch(setShowClearStageModal(false))

  const clearStage = (role: Role) => {
    try {
      removeableStagePeers.forEach((peer) => {
        hmsActions.changeRole(peer.id, role, true)
      })
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error removing users from stage"
          />
        ),
      })
    } finally {
      onClearStageClose()
    }
  }

  const onPromoteGroupClick = () => dispatch(setShowPromoteGroupModal(true))

  const onPromoteGroupClose = () => dispatch(setShowPromoteGroupModal(false))

  const promoteGroup = ({
    quantity,
    onlyHandsRaised,
  }: PromoteGroupFormProps) => {
    try {
      const peerPool = peers.filter(
        (peer) =>
          (onlyHandsRaised === false ||
            (peer.metadata &&
              (JSON.parse(peer.metadata) as CustomMetadata)
                .isHandRaised)) &&
          peer.id !== localPeerId &&
          peer.roleName &&
          EXCLUDED_ROLES.indexOf(peer.roleName) === -1
      )

      if (peerPool.length === 0) {
        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              type="error"
              title="No users available to invite"
            />
          ),
        })

        return
      }

      if (quantity >= peerPool.length) {
        peerPool.forEach((peer) =>
          hmsActions.changeRole(peer.id, Role.StageViewer)
        )

        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              type="info"
              title="Stage invites sent"
            />
          ),
        })

        return
      }

      const indexArray = []
      while (indexArray.length < quantity) {
        const random = Math.floor(Math.random() * peers.length)
        if (indexArray.indexOf(random) === -1) {
          indexArray.push(random)
        }
      }

      indexArray.forEach((index) =>
        hmsActions.changeRole(peers[index].id, Role.StageViewer)
      )

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="info"
            title="Stage invites sent"
          />
        ),
      })
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error processing user invites"
          />
        ),
      })
    } finally {
      onPromoteGroupClose()
    }
  }

  const toggleBroadcast = () => {
    try {
      if (broadcastPeerId === localPeerId) {
        hmsActions.sendBroadcastMessage(
          `${MOD_FUNC_BOT_TOGGLE_BROADCAST}${MOD_FUNC_BOT_BOUNDARY}`
        )
      } else {
        hmsActions.sendBroadcastMessage(
          `${MOD_FUNC_BOT_TOGGLE_BROADCAST}${MOD_FUNC_BOT_BOUNDARY}${localPeerId}`
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const dispatchBotLayoutCommand = (displayLayout: DisplayLayout) => {
    try {
      hmsActions.sendBroadcastMessage(
        `${MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT}${MOD_FUNC_BOT_BOUNDARY}${displayLayout}`
      )
    } catch (error) {
      console.error(error)
    }
  }

  if (peers.length === 0 || peers.length === 1) {
    return (
      <Center flex={1}>
        <Text>Just you.</Text>
      </Center>
    )
  }

  return (
    <>
      <Flex flex={1} flexDirection="column">
        <Flex flex={3} flexDirection="column">
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            marginBottom={2}
          >
            <InputGroup>
              <Input
                value={searchValue}
                fontSize={[14, null, 16]}
                maxLength={220}
                placeholder="Search by name"
                autoComplete="off"
                color="white"
                backgroundColor="backgroundPrimary"
                padding={4}
                borderRadius={12}
                border="none"
                _invalid={{
                  border: 'none',
                }}
                _focusVisible={{
                  border: 'none',
                }}
                onChange={onInputChange}
              />

              <InputRightElement height="100%" marginRight={1}>
                <IconButton
                  aria-label="Clear search text"
                  backgroundColor="backgroundPrimary"
                  onClick={onClearSearchClick}
                  disabled={searchValue === ''}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    backspace
                  </ControlButtonText>
                </IconButton>
              </InputRightElement>
            </InputGroup>

            <ControlButton
              label={showHandsRaised ? 'Show all' : 'Show raised'}
              aria-label="Toggle show hands raised"
              backgroundColor={
                showHandsRaised
                  ? 'buttonPrimary'
                  : 'transparent'
              }
              onClick={() =>
                toggleShowHandsRaised(!showHandsRaised)
              }
            >
              <ControlButtonText className="material-symbols-outlined">
                pan_tool
              </ControlButtonText>
            </ControlButton>
          </Flex>

          {isHost ? (
            <Accordion allowToggle marginY={2}>
              <AccordionItem>
                <AccordionButton paddingX={0}>
                  <Flex flex={1}>
                    <Text
                      fontSize={[12, null, 16]}
                      fontWeight={600}
                      userSelect="none"
                      marginBottom={1}
                    >
                      Token filter
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel paddingX={0}>
                  <Flex flexDirection="column">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                    >
                      <InputGroup>
                        <Input
                          value={characterSearchValue}
                          fontSize={[14, null, 16]}
                          maxLength={220}
                          placeholder="Search by character"
                          autoComplete="off"
                          color="white"
                          backgroundColor="backgroundPrimary"
                          padding={4}
                          borderRadius={12}
                          border="none"
                          _invalid={{
                            border: 'none',
                          }}
                          _focusVisible={{
                            border: 'none',
                          }}
                          onChange={
                            onTraitInputChange
                          }
                        />

                        <InputRightElement
                          height="100%"
                          marginRight={1}
                        >
                          <IconButton
                            aria-label="Clear search text"
                            backgroundColor="backgroundPrimary"
                            onClick={
                              onClearTraitSearchClick
                            }
                            disabled={
                              characterSearchValue ===
                              ''
                            }
                          >
                            <ControlButtonText className="material-symbols-outlined">
                              backspace
                            </ControlButtonText>
                          </IconButton>
                        </InputRightElement>
                      </InputGroup>

                      <Flex flexDirection="row">
                        <FormLabel
                          fontSize="2xs"
                          color="textSecondary"
                          htmlFor="series2-search-switch"
                          marginBottom={0}
                        >
                          S2
                        </FormLabel>
                        <Switch
                          id="series2-search-switch"
                          size="sm"
                          sx={{
                            'span.chakra-switch__track[data-checked]':
                            {
                              backgroundColor:
                                'buttonConfirm',
                            },
                          }}
                          isChecked={
                            s2CharacterSearch
                          }
                          onChange={onS2SwitchChange}
                        />
                      </Flex>
                    </Flex>

                    <HStack spacing={3} marginTop={3}>
                      <Checkbox
                        colorScheme="green"
                        isChecked={showS1}
                        onChange={onShowS1Change}
                      >
                        <Text
                          fontSize="2xs"
                          color="textSecondary"
                        >
                          S1 holder
                        </Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme="green"
                        isChecked={showS1Spec}
                        onChange={onShowS1SpecChange}
                      >
                        <Text
                          fontSize="2xs"
                          color="textSecondary"
                        >
                          S1 spec holder
                        </Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme="green"
                        isChecked={showS1GG}
                        onChange={onShowS1GGChange}
                      >
                        <Text
                          fontSize="2xs"
                          color="textSecondary"
                        >
                          S1 GG holder
                        </Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme="green"
                        isChecked={showS1Access}
                        onChange={onShowS1AccessChange}
                      >
                        <Text
                          fontSize="2xs"
                          color="textSecondary"
                        >
                          S1 Access holder
                        </Text>
                      </Checkbox>
                    </HStack>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : null}

          <Text
            fontSize={[12, null, 16]}
            fontWeight={600}
            userSelect="none"
            marginBottom={4}
          >
            {filterApplied
              ? `${filteredPeers.length} Results`
              : `${peers.length} Participants`}
          </Text>

          <Flex flex={1}>
            <ParticipantGrid
              peers={filterApplied ? filteredPeers : peers}
              containerStyle={{ paddingBottom: 16 }}
            />
          </Flex>
        </Flex>

        {isHost && isStageDisplayMode ? (
          <Flex
            flexDirection="column"
            backgroundColor="black"
            margin={-4}
            paddingTop={4}
            paddingBottom={1}
            paddingX={4}
            zIndex={99}
          >
            <HStack spacing={2} flexShrink={0} marginBottom={4}>
              <ControlButton
                label="Clear stage"
                aria-label="Clear stage"
                backgroundColor="buttonDeny"
                disabled={removeableStagePeers.length === 0}
                onClick={onClearStageClick}
              >
                <ControlButtonText className="material-symbols-outlined">
                  group_remove
                </ControlButtonText>
              </ControlButton>

              <ControlButton
                label="Promote group"
                aria-label="Promote group"
                backgroundColor="buttonConfirm"
                onClick={onPromoteGroupClick}
              >
                <ControlButtonText className="material-symbols-outlined">
                  group_add
                </ControlButtonText>
              </ControlButton>

              <ControlButton
                label={
                  broadcastPeerId === localPeerId
                    ? 'End broadcast'
                    : 'Start broadcast'
                }
                aria-label="Toggle broadcast"
                backgroundColor={
                  broadcastPeerId === localPeerId
                    ? 'buttonConfirm'
                    : 'buttonPrimary'
                }
                onClick={toggleBroadcast}
              >
                <ControlButtonText className="material-symbols-outlined">
                  {broadcastPeerId === localPeerId
                    ? 'cast_connected'
                    : 'cast'}
                </ControlButtonText>
              </ControlButton>

              <ControlButton
                label="Stream grid view"
                aria-label="Switch stream to grid view"
                backgroundColor={
                  botDisplayLayout === 'show all'
                    ? 'buttonConfirm'
                    : 'buttonPrimary'
                }
                isDisabled={!!broadcastPeerId}
                onClick={() =>
                  dispatchBotLayoutCommand('show all')
                }
              >
                <ControlButtonText className="material-symbols-outlined">
                  grid_view
                </ControlButtonText>
              </ControlButton>

              <ControlButton
                label="Stream active view"
                aria-label="Switch stream to active view"
                backgroundColor={
                  botDisplayLayout === 'show active'
                    ? 'buttonConfirm'
                    : 'buttonPrimary'
                }
                isDisabled={!!broadcastPeerId}
                onClick={() =>
                  dispatchBotLayoutCommand('show active')
                }
              >
                <ControlButtonText className="material-symbols-outlined">
                  view_sidebar
                </ControlButtonText>
              </ControlButton>

              <ControlButton
                label="Stream focused view"
                aria-label="Switch stream to focused view"
                backgroundColor={
                  botDisplayLayout === 'focused'
                    ? 'buttonConfirm'
                    : 'buttonPrimary'
                }
                isDisabled={!!broadcastPeerId}
                onClick={() =>
                  dispatchBotLayoutCommand('focused')
                }
              >
                <ControlButtonText className="material-symbols-outlined">
                  slideshow
                </ControlButtonText>
              </ControlButton>
            </HStack>

            <Accordion allowToggle>
              <AccordionItem style={{ borderBottomWidth: 0 }}>
                <AccordionButton paddingX={0}>
                  <Flex flex={1}>
                    <Text
                      fontSize={[12, null, 16]}
                      fontWeight={600}
                      userSelect="none"
                    >
                      {`${backstagePeers.length} Backstage`}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel paddingX={0}>
                  <Box width="100%" height="160px">
                    <ParticipantGrid
                      peers={backstagePeers}
                    />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>
        ) : null}
      </Flex>

      {removeableStagePeers.length > 0 ? (
        <ClearStageModal
          isOpen={showClearStageModal}
          onClose={onClearStageClose}
          onSubmit={clearStage}
        />
      ) : null}

      <PromoteGroupModal
        isOpen={showPromoteGroupModal}
        onClose={onPromoteGroupClose}
        onSubmit={promoteGroup}
      />
    </>
  )
}

export const ParticipantSearch = memo(_ParticipantSearch)