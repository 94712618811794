import { useEffect, useState, memo } from 'react'
import {
  Button,
  Flex,
  Image,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react'
import { getIsIos } from '../../utils'
import icon from '../../assets/images/ios-menu.svg'

const _PwaPrompt = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState<any>(null)

  const isDesktop = window.matchMedia('(min-width: 768px)').matches
  const isIos = getIsIos()

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      setSupportsPWA(true)
      setPromptInstall(e)
    }

    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('beforeinstallprompt', handler)
  }, [])

  const onClick = () => {
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }

  if (isIos) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        alignSelf="center"
        width="75%"
        marginTop={8}
      >
        <Text fontSize="sm" color="textSecondary" marginBottom={2}>
          *If viewing on mobile, consider adding to your home screen
          for the best possible viewing experience
        </Text>

        <OrderedList>
          <ListItem fontSize="sm" color="textSecondary">
            <Flex>
              <Text>Tap</Text>
              <Image src={icon} height="20px" marginX={1} />
              <Text>icon</Text>
            </Flex>
          </ListItem>
          <ListItem fontSize="sm" color="textSecondary">
            Tap "Add to Home Screen"
          </ListItem>
        </OrderedList>
      </Flex>
    )
  }

  if (!supportsPWA || isDesktop) {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      alignSelf="center"
      width="75%"
      marginTop={8}
    >
      <Text fontSize="sm" color="textSecondary" marginBottom={4}>
        *If viewing on mobile, consider adding to your home screen for
        the best possible viewing experience
      </Text>

      <Button
        backgroundColor="buttonPrimary"
        color="white"
        onClick={onClick}
      >
        Add to Home
      </Button>
    </Flex>
  )
}

export const PwaPrompt = memo(_PwaPrompt)