import { memo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  ModalFooter,
} from '@chakra-ui/react'

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  message: string
  confirmButtonText?: string
  onConfirmClick: () => void
  onCloseClick: () => void
}

const _ConfirmationModal = ({
  isOpen,
  title,
  message,
  confirmButtonText = 'Confirm',
  onConfirmClick,
  onCloseClick,
}: ConfirmationModalProps) => (
  <Modal isOpen={isOpen} isCentered size="xl" onClose={onCloseClick}>
    <ModalOverlay backdropFilter="blur(32px)" />

    <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
      <ModalHeader>
        <Heading>{title}</Heading>
      </ModalHeader>

      <ModalCloseButton size="2xl" top={6} right={6} />

      <ModalBody>
        <Text fontSize="lg">{message}</Text>
      </ModalBody>

      <ModalFooter gap={4}>
        <Button
          backgroundColor="buttonDeny"
          color="white"
          onClick={onCloseClick}
        >
          Cancel
        </Button>

        <Button
          backgroundColor="buttonConfirm"
          color="white"
          onClick={onConfirmClick}
        >
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export const ConfirmationModal = memo(_ConfirmationModal)