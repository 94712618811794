import { memo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react'

interface AllowHlsAutoplayModalProps {
  isOpen: boolean
  unblockAutoPlay: () => void
}

const _AllowHlsAutoplayModal = ({
  isOpen,
  unblockAutoPlay,
}: AllowHlsAutoplayModalProps) => {
  if (isOpen) {
    return (
      <Modal isOpen isCentered size="xl" onClose={unblockAutoPlay}>
        <ModalOverlay backdropFilter="blur(32px)" />

        <ModalContent
          backgroundColor="backgroundPrimary"
          borderRadius={20}
        >
          <ModalHeader>
            <Heading>Autoplay Error</Heading>
          </ModalHeader>

          <ModalCloseButton size="2xl" top={6} right={6} />

          <ModalBody paddingBottom={4}>
            <Text fontSize="lg" marginBottom={4}>
              Your browser requires confirmation before playing
              audio or video. Please allow autoplay to proceed.
            </Text>

            <Button
              backgroundColor="buttonConfirm"
              color="white"
              onClick={unblockAutoPlay}
            >
              Allow Autoplay
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return null
}

export const AllowHlsAutoplayModal = memo(_AllowHlsAutoplayModal)