import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useHMSActions,
  useHMSStore,
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectHLSState,
  selectRecordingState,
} from '@100mslive/react-sdk'
import { Flex, Text } from '@chakra-ui/react'
import {
  useAppDispatch,
  useAppSelector,
  setShowChatDrawer,
  setErrorMessage,
  setShowParticipantDrawer,
  setUnreadCount,
} from '../../store'
import { StandardLayout } from './StandardLayout'
import { StageLayout } from './StageLayout'
import {
  Page,
  ControlBar,
  CONTROL_BAR_HEIGHT_SINGLE_ROW,
  CONTROL_BAR_HEIGHT_DOUBLE_ROW,
  Drawer,
  ParticipantSearch,
  ControlButtonText,
  TwilioChat,
} from '../../components'
import { NotificationCenter } from '../../notification'
import { Role } from '../../models'
import { QUERY_PARAM_LOGGED_OUT } from '../../constants'

export const MeetingPage = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { showChatDrawer, showParticipantDrawer } = useAppSelector(
    (state) => state.app
  )

  const { roomConfig, roomId, displayMode } = useAppSelector(
    (state) => state.config
  )

  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom)
  const localPeer = useHMSStore(selectLocalPeer)
  const hlsState = useHMSStore(selectHLSState)
  const recordingState = useHMSStore(selectRecordingState)
  const hmsActions = useHMSActions()

  const isAudienceMember =
    (localPeer && localPeer.roleName === Role.Viewer) ||
    (localPeer && localPeer.roleName === Role.HLSViewer) ||
    (localPeer && localPeer.roleName === Role.ChatTimeOut)

  const isHlsAudienceMember =
    localPeer && localPeer.roleName === Role.HLSViewer

  useEffect(() => {
    return () => {
      // This is needed to handle mac touchpad swipe gesture
      if (isConnectedToRoom) {
        hmsActions.leave()
      }
    }
  }, [hmsActions, isConnectedToRoom])

  useEffect(() => {
    // Another users has joined with the same 100ms token
    if (!localPeer) {
      navigate(`/?${QUERY_PARAM_LOGGED_OUT}=true`)
    }
  }, [localPeer, navigate])

  useEffect(() => {
    if (navigate && roomId === '') {
      dispatch(setErrorMessage('Invalid room ID'))
      navigate('/error')
    }
  }, [roomId, dispatch, navigate])

  const toggleChatDrawer = () => {
    if (showParticipantDrawer) {
      dispatch(setShowParticipantDrawer(false))
    }

    dispatch(setShowChatDrawer(!showChatDrawer))
    dispatch(setUnreadCount(0))
  }

  const toggleParticipantDrawer = () => {
    if (showChatDrawer) {
      dispatch(setShowChatDrawer(false))
    }

    dispatch(setShowParticipantDrawer(!showParticipantDrawer))
  }

  return (
    <Page
      accentColor={roomConfig?.primaryColor}
      bannerImageUrl={roomConfig?.bannerImage}
    >
      <Flex
        position="relative"
        flexDirection="row"
        justifyContent="center"
        height={[
          `calc(100% - ${isAudienceMember
            ? CONTROL_BAR_HEIGHT_SINGLE_ROW
            : CONTROL_BAR_HEIGHT_DOUBLE_ROW
          })`,
          null,
          `calc(100% - ${CONTROL_BAR_HEIGHT_SINGLE_ROW})`,
        ]}
        width="100%"
        backgroundColor={
          isHlsAudienceMember && hlsState.running
            ? 'black'
            : 'transparent'
        }
        userSelect="none"
      >
        {displayMode === 'stage' ? <StageLayout /> : <StandardLayout />}

        <TwilioChat isOpen={showChatDrawer} />

        {showParticipantDrawer ? (
          <Drawer
            onCloseClick={toggleParticipantDrawer}
            containerStyle={{
              overflow: 'hidden',
              border: '1px solid black',
            }}
          >
            <ParticipantSearch />
          </Drawer>
        ) : null}

        {recordingState.browser.running ||
          recordingState.hls.running ? (
          <Flex
            flexDirection="row"
            alignItems="center"
            position="absolute"
            top={2}
            left={2}
            backgroundColor="buttonDeny"
            paddingX={2}
            paddingY={1}
            borderRadius={8}
          >
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize="sm"
            >
              radio_button_checked
            </ControlButtonText>
            <Text fontSize="sm" fontWeight={600} marginLeft={1}>
              REC
            </Text>
          </Flex>
        ) : null}
      </Flex>

      <ControlBar
        title={roomConfig?.title || 'Live Stream'}
        subtitle={roomConfig?.subTitle}
        toggleChatDrawer={toggleChatDrawer}
        toggleParticipantDrawer={toggleParticipantDrawer}
      />

      <NotificationCenter />
    </Page>
  )
}