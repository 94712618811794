import { memo, ReactNode, useEffect, useState } from 'react'
import { addHours, set } from 'date-fns'
import * as yup from 'yup'
import { Formik, Form, Field, FieldProps } from 'formik'
import DatePicker from 'react-datepicker'
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Select,
  Switch,
  Flex,
  Textarea,
  Divider,
} from '@chakra-ui/react'
import { TokenGateWizard } from './TokenGateWizard'
import { AdminDropdownModel, NftRuleModel } from '../../services/meetApi'

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

export interface CreateEditRoomFormValues {
  tokenAccessId?: string
  inviteeEmails?: string
  nftGates: NftRuleModel[]
  type: string
  title: string
  subtitle?: string
  description?: string
  startTime?: string | null
  endTime?: string | null
  themeId: string
  chatEnabled: boolean
  adminEmails?: string
  roomId?: string
}

const validationSchema = yup.object().shape({
  tokenAccessId: yup.string().when(['inviteeEmails', 'nftGates'], {
    is: ([inviteeEmails, nftGates]: [any[], any[] | undefined]) =>
      (!inviteeEmails || inviteeEmails.length === 0) &&
      (!nftGates || nftGates.length === 0),
    then: (schema) => schema.required('Token access required'),
    otherwise: (schema) => schema.test(
      'empty-check',
      'Please select token access rule OR invitee list',
      (value) => !value || value.length === 0
    ),
  }),

  inviteeEmails: yup.array().when(['tokenAccessId', 'nftGates'], {
    is: ([tokenAccessId, nftGates]: [string | undefined, any[] | undefined]) =>
      (!tokenAccessId || tokenAccessId.length === 0) &&
      (!nftGates || nftGates.length === 0),
    then: (schema) => schema
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue
          ? originalValue
              .split(/,\s*/)
              .filter((entry: string) => entry && entry.trim().length > 0)
          : [];
      })
      .of(
        yup
          .string()
          .email(({ value }) => `${value} is not a valid email address`)
      ),
    otherwise: (schema) => schema
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue
          ? originalValue
              .split(/,\s*/)
              .filter((entry: string) => entry && entry.trim().length > 0)
          : [];
      })
      .test(
        'empty-check',
        'Please select token access rule OR invitee list',
        (value) => !value || value.length === 0
      ),
  }),

  nftGates: yup.array().when(['tokenAccessId', 'inviteeEmails'], {
    is: ([tokenAccessId, inviteeEmails]: [string | undefined, any[] | undefined]) =>
      (!tokenAccessId || tokenAccessId.length === 0) &&
      (!inviteeEmails || inviteeEmails.length === 0),
    then: (schema) => schema.required('Token access required'),
    otherwise: (schema) => schema.test(
      'empty-check',
      'Please select token access rule OR invitee list',
      (value) => !value || value.length === 0
    ),
  }),

  type: yup.string().required('Meeting type required'),
  title: yup.string().required('Title required'),
  subtitle: yup.string().optional(),
  description: yup.string().optional(),
  startTime: yup.string().optional(),
  endTime: yup.string().optional(),
  themeId: yup.string().required('Theme required'),
  chatEnabled: yup.bool(),
  adminEmails: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue
        ? originalValue
            .split(/,\s*/)
            .filter((entry?: string) => entry && entry.trim().length > 0)
        : [];
    })
    .of(
      yup
        .string()
        .email(({ value }) => `${value} is not a valid email address`)
    )
    .optional(),
}, [
  ['tokenAccessId', 'inviteeEmails'],
  ['tokenAccessId', 'nftGates'],
  ['inviteeEmails', 'nftGates'],
]);

const INITIAL_VALUES: CreateEditRoomFormValues = {
  inviteeEmails: '',
  nftGates: [],
  type: '',
  title: '',
  description: '',
  themeId: 'default',
  chatEnabled: true,
  adminEmails: '',
}

interface CreateEditRoomFormProps {
  tokenWizardData: AdminDropdownModel[]
  isLoading: boolean
  initialValues?: CreateEditRoomFormValues
  onCancelClick: () => void
  onSubmitClick: (values: CreateEditRoomFormValues) => void
}

const _CreateEditRoomForm = ({
  tokenWizardData,
  isLoading,
  initialValues,
  onCancelClick,
  onSubmitClick,
}: CreateEditRoomFormProps) => {
  const [startTime, setStartTime] = useState<Date | null>()

  const [endTime, setEndTime] = useState<Date | null>()

  const [tokenAccess, setTokenAccess] = useState<AdminDropdownModel[]>([])

  const [meetingTypes, setMeetingTypes] = useState<AdminDropdownModel[]>([])

  const [themes, setThemes] = useState<AdminDropdownModel[]>([])

  const [allDay, setAllDay] = useState(false)

  useEffect(() => {
    const accessData = tokenWizardData.find(
      (item) => item.key === 'tokenaccess'
    )
    accessData && setTokenAccess(accessData.values)

    const themeData = tokenWizardData.find((item) => item.key === 'themes')
    themeData && setThemes(themeData.values)

    const typeData = tokenWizardData.find((item) => item.key === 'type')
    typeData && setMeetingTypes(typeData.values)
  }, [tokenWizardData])

  return (
    <>
      <Heading
        as="h2"
        size="xl"
        fontWeight={800}
        textTransform="uppercase"
        userSelect="none"
        marginBottom={4}
      >
        Create Room
      </Heading>

      <Formik
        initialValues={initialValues ? initialValues : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmitClick(values)
          resetForm()
          setStartTime(null)
          setEndTime(null)
        }}
      >
        {({ isValid }) => (
          <Form>
            <VStack spacing={4}>
              <Flex
                flexDirection="row"
                alignItems="center"
                gap={6}
                width="100%"
                marginBottom={2}
              >
                <Field name="tokenAccessId">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isInvalid={
                        !!(
                          form.errors.tokenAccessId &&
                          form.touched.tokenAccessId
                        )
                      }
                    >
                      <FormLabel
                        userSelect="none"
                        color="white"
                      >
                        Token Access
                      </FormLabel>
                      <Select
                        placeholder="Select one"
                        {...field}
                        onChange={(event) => {
                          form.setFieldValue(
                            'inviteeEmails',
                            ''
                          )
                          form.setFieldValue(
                            'nftGates',
                            []
                          )
                          field.onChange(event)
                        }}
                      >
                        {tokenAccess.map(
                          (item) =>
                            item.key && (
                              <option
                                key={item.key}
                                value={item.key}
                              >
                                {item.values}
                              </option>
                            )
                        )}
                        <option value="none">
                          None
                        </option>
                      </Select>
                      {form.errors.tokenAccessId &&
                        form.touched.tokenAccessId ? (
                        <FormErrorMessage>
                          {
                            form.errors
                              .tokenAccessId as ReactNode
                          }
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  )}
                </Field>

                <Heading
                  as="h3"
                  size="md"
                  fontWeight={800}
                  textTransform="uppercase"
                  userSelect="none"
                >
                  Or
                </Heading>

                <Field name="inviteeEmails">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isInvalid={
                        !!(
                          form.errors.inviteeEmails &&
                          form.touched.inviteeEmails
                        )
                      }
                      isDisabled={
                        form.values.tokenAccessId &&
                        form.values.tokenAccessId !== ''
                      }
                    >
                      <FormLabel
                        userSelect="none"
                        color="white"
                      >
                        Invitee Emails
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="gary@veefriends.com, andy@veefriends.com"
                        color="white"
                        autoComplete="none"
                      />
                      {form.errors.inviteeEmails &&
                        form.touched.inviteeEmails ? (
                        <FormErrorMessage>
                          {
                            form.errors
                              .inviteeEmails as ReactNode
                          }
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="nftGates">
                {({ form }: FieldProps) => (
                  <FormControl
                    isInvalid={!!form.errors.nftGates}
                  >
                    <TokenGateWizard
                      data={tokenWizardData}
                      nftGates={form.values.nftGates}
                      isDisabled={
                        form.values.tokenAccessId &&
                        form.values.tokenAccessId !== ''
                      }
                      onCreateGates={(nftGates) =>
                        form.setFieldValue('nftGates', [
                          ...form.values.nftGates,
                          ...nftGates,
                        ])
                      }
                      onDeleteGate={(index) =>
                        form.setFieldValue(
                          'nftGates',
                          form.values.nftGates.toSpliced(
                            index,
                            1
                          )
                        )
                      }
                    />

                    <FormErrorMessage>
                      {form.errors.nftGates as ReactNode}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Divider
                style={{
                  marginTop: 32,
                  marginBottom: 12,
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                }}
              />

              <Flex flexDirection="row" gap={8} width="100%">
                <Field name="type">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        !!(
                          form.errors.type &&
                          form.touched.type
                        )
                      }
                    >
                      <FormLabel
                        userSelect="none"
                        color="white"
                      >
                        Meeting type
                      </FormLabel>
                      <Select
                        {...field}
                        placeholder="Select one"
                      >
                        {meetingTypes.map(
                          (meetingType) =>
                            meetingType.key && (
                              <option
                                key={
                                  meetingType.key
                                }
                                value={
                                  meetingType.key
                                }
                              >
                                {
                                  meetingType.values
                                }
                              </option>
                            )
                        )}
                      </Select>
                      {form.errors.type &&
                        form.touched.type ? (
                        <FormErrorMessage>
                          {
                            form.errors
                              .type as ReactNode
                          }
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  )}
                </Field>

                <Field name="themeId">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        !!(
                          form.errors.themeId &&
                          form.touched.themeId
                        )
                      }
                    >
                      <FormLabel
                        userSelect="none"
                        color="white"
                      >
                        Theme
                      </FormLabel>
                      <Select
                        {...field}
                        placeholder="Select one"
                      >
                        {themes.map(
                          (item) =>
                            item.key && (
                              <option
                                key={item.key}
                                value={item.key}
                              >
                                {item.values}
                              </option>
                            )
                        )}
                      </Select>
                      {form.errors.themeId &&
                        form.touched.themeId ? (
                        <FormErrorMessage>
                          {
                            form.errors
                              .themeId as ReactNode
                          }
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="title">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isRequired
                    isInvalid={
                      !!(
                        form.errors.title &&
                        form.touched.title
                      )
                    }
                  >
                    <FormLabel
                      userSelect="none"
                      color="white"
                    >
                      Title
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder="Title"
                      color="white"
                    />
                    {form.errors.title &&
                      form.touched.title ? (
                      <FormErrorMessage>
                        {form.errors.title as ReactNode}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>

              <Field name="subtitle">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isInvalid={
                      !!(
                        form.errors.subtitle &&
                        form.touched.subtitle
                      )
                    }
                  >
                    <FormLabel
                      userSelect="none"
                      color="white"
                    >
                      Subtitle
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder="Subtitle"
                      color="white"
                    />
                    {form.errors.subtitle &&
                      form.touched.subtitle ? (
                      <FormErrorMessage>
                        {
                          form.errors
                            .subtitle as ReactNode
                        }
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isInvalid={
                      !!(
                        form.errors.description &&
                        form.touched.description
                      )
                    }
                  >
                    <FormLabel
                      userSelect="none"
                      color="white"
                    >
                      Description
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Description"
                      resize="none"
                      color="white"
                    />
                    {form.errors.description &&
                      form.touched.description ? (
                      <FormErrorMessage>
                        {
                          form.errors
                            .description as ReactNode
                        }
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>

              <Flex flexDirection="row" gap={8} width="100%">
                <Field name="startTime">
                  {({ field, form }: FieldProps) => (
                    <Flex
                      flex={1}
                      flexDirection="column"
                      gap={2}
                    >
                      <FormControl
                        isInvalid={
                          !!(
                            form.errors.startTime &&
                            form.touched.startTime
                          )
                        }
                      >
                        <FormLabel
                          userSelect="none"
                          color="white"
                        >
                          Start time
                        </FormLabel>
                        <DatePicker
                          customInput={
                            <Input
                              name={field.name}
                              color="white"
                            />
                          }
                          placeholderText="Start time"
                          selected={startTime}
                          timeFormat="p"
                          dateFormat="Pp"
                          showTimeSelect={!allDay}
                          timeIntervals={15}
                          onChange={(value) => {
                            if (!value) return

                            form.setFieldValue(
                              'startTime',
                              value?.toISOString()
                            )
                            setStartTime(value)

                            if (allDay) {
                              form.setFieldValue(
                                'endTime',
                                set(value, {
                                  hours: 23,
                                  minutes: 59,
                                  seconds: 59,
                                })
                              )
                              setEndTime(
                                set(value, {
                                  hours: 23,
                                  minutes: 59,
                                  seconds: 59,
                                })
                              )
                            } else {
                              form.setFieldValue(
                                'endTime',
                                addHours(
                                  value,
                                  1
                                )
                              )
                              setEndTime(
                                addHours(
                                  value,
                                  1
                                )
                              )
                            }
                          }}
                        />
                        {form.errors.startTime &&
                          form.touched.startTime ? (
                          <FormErrorMessage>
                            {
                              form.errors
                                .startTime as ReactNode
                            }
                          </FormErrorMessage>
                        ) : null}
                      </FormControl>

                      <Flex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <FormLabel
                          userSelect="none"
                          color="white"
                          fontSize="sm"
                          htmlFor="allDay"
                          marginBottom={0}
                        >
                          All day
                        </FormLabel>
                        <Switch
                          id="allDay"
                          size="sm"
                          colorScheme="green"
                          isChecked={allDay}
                          onChange={(event) => {
                            setAllDay(
                              event.target.checked
                            )

                            if (startTime) {
                              const newStart =
                                set(startTime, {
                                  hours: 0,
                                  minutes: 0,
                                  seconds: 0,
                                })
                              const newEnd = set(
                                newStart,
                                {
                                  hours: 23,
                                  minutes: 59,
                                  seconds: 59,
                                }
                              )
                              setStartTime(
                                newStart
                              )
                              setEndTime(newEnd)
                              form.setFieldValue(
                                'startTime',
                                newStart.toISOString()
                              )
                              form.setFieldValue(
                                'endTime',
                                newEnd.toISOString()
                              )
                            }
                          }}
                        />
                      </Flex>
                    </Flex>
                  )}
                </Field>

                <Flex flex={1}>
                  <Field name="endTime">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={
                          !!(
                            form.errors.endTime &&
                            form.touched.endTime
                          )
                        }
                        isDisabled={allDay}
                      >
                        <FormLabel
                          userSelect="none"
                          color="white"
                        >
                          End time
                        </FormLabel>
                        <DatePicker
                          customInput={
                            <Input
                              name={field.name}
                              color="white"
                            />
                          }
                          placeholderText="End time"
                          selected={endTime}
                          timeFormat="p"
                          dateFormat="Pp"
                          showTimeSelect
                          disabled={allDay}
                          timeIntervals={15}
                          onChange={(value) => {
                            form.setFieldValue(
                              'endTime',
                              value?.toISOString()
                            )
                            setEndTime(value)
                          }}
                        />
                        {form.errors.endTime &&
                          form.touched.endTime ? (
                          <FormErrorMessage>
                            {
                              form.errors
                                .endTime as ReactNode
                            }
                          </FormErrorMessage>
                        ) : null}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </Flex>

              <Field name="adminEmails">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isInvalid={
                      !!(
                        form.errors.adminEmails &&
                        form.touched.adminEmails
                      )
                    }
                  >
                    <FormLabel
                      userSelect="none"
                      color="white"
                    >
                      Admin emails
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder="gary@veefriends.com, andy@veefriends.com"
                      color="white"
                      autoComplete="none"
                    />
                    {form.errors.adminEmails &&
                      form.touched.adminEmails ? (
                      <FormErrorMessage>
                        {
                          form.errors
                            .adminEmails as ReactNode
                        }
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>

              <Field name="chatEnabled">
                {({ field }: FieldProps) => (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    gap={4}
                    style={{ marginBottom: 12 }}
                  >
                    <FormLabel
                      userSelect="none"
                      color="white"
                      htmlFor="chatEnabled"
                      marginBottom={0}
                    >
                      Chat enabled
                    </FormLabel>
                    <Switch
                      id="chatEnabled"
                      {...field}
                      isChecked={field.value}
                      colorScheme="green"
                      size="lg"
                    />
                  </FormControl>
                )}
              </Field>

              <Flex
                flexDirection="row"
                justifyContent="flex-start"
                alignSelf="flex-start"
                gap={4}
              >
                <Button
                  backgroundColor="buttonPrimary"
                  color="white"
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  backgroundColor="buttonConfirm"
                  color="white"
                  isLoading={isLoading}
                  disabled={!isValid || isLoading}
                >
                  {initialValues
                    ? 'Save Room'
                    : 'Create Room'}
                </Button>
              </Flex>
            </VStack>
          </Form>
        )}
      </Formik>
    </>
  )
}

export const CreateEditRoomForm = memo(_CreateEditRoomForm)