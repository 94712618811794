import { memo } from 'react'
import {
  useHMSStore,
  selectIsConnectedToRoom,
  selectPeers,
  selectLocalPeer,
  selectIsSomeoneScreenSharing,
  selectPeerScreenSharing,
} from '@100mslive/react-sdk'
import { Center } from '@chakra-ui/react'
import {
  LoadingIndicator,
  PeerGrid,
  EmptyRoomView,
  ShowActiveView,
  ScreenShareView,
} from '../../components'
import { useAppSelector } from '../../store'

const _StandardLayout = () => {
  const displayLayout = useAppSelector((state) => state.app.displayLayout)

  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom)
  const localPeer = useHMSStore(selectLocalPeer)
  const peers = useHMSStore(selectPeers)
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing)
  const presenter = useHMSStore(selectPeerScreenSharing)

  if (!isConnectedToRoom) {
    return (
      <Center height="100%">
        <LoadingIndicator message="Connecting to room..." />
      </Center>
    )
  }

  if (peers.length === 1) {
    return <EmptyRoomView localPeer={localPeer} />
  }

  if (isSomeoneScreenSharing && presenter) {
    return <ScreenShareView presenter={presenter} peers={peers} />
  }

  if (displayLayout === 'show active') {
    return <ShowActiveView peers={peers} />
  }

  if (displayLayout === 'show all') {
    return <PeerGrid peers={peers} />
  }

  return null
}

export const StandardLayout = memo(_StandardLayout)