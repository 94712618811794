import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MeetRoomConfigResponse } from '../services/meetApi'
import { DisplayMode, BotType } from '../models'

interface TwilioConfig {
  token: string
  participantSid: string
  conversationSid: string
}

interface ConfigState {
  // 100ms token
  token: string
  veefriendsToken: string
  // meetingId: guestCode
  guestCodes: { [key: string]: string }
  roomId: string
  role: string
  isChatEnabled: boolean
  displayName: string
  avatarUrl: string
  displayMode: DisplayMode
  roomConfig?: MeetRoomConfigResponse
  twilioConfig?: TwilioConfig
  botType?: BotType
}

const INITIAL_STATE: ConfigState = {
  token: '',
  veefriendsToken: '',
  guestCodes: {},
  roomId: '',
  role: '',
  isChatEnabled: true,
  displayName: '',
  avatarUrl: '',
  displayMode: 'standard',
}

const configSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setVeefriendsToken(state, action: PayloadAction<string>) {
      state.veefriendsToken = action.payload
    },
    addGuestCode(
      state,
      action: PayloadAction<{ meetingId: string; guestCode: string }>
    ) {
      state.guestCodes[action.payload.meetingId] =
        action.payload.guestCode
    },
    setRoomId(state, action: PayloadAction<string>) {
      state.roomId = action.payload
    },
    setRole(state, action: PayloadAction<string>) {
      state.role = action.payload
    },
    setIsChatEnabled(state, action: PayloadAction<boolean>) {
      state.isChatEnabled = action.payload
    },
    setDisplayName(state, action: PayloadAction<string>) {
      state.displayName = action.payload
    },
    setAvatarUrl(state, action: PayloadAction<string>) {
      state.avatarUrl = action.payload
    },
    setDisplayMode(state, action: PayloadAction<DisplayMode>) {
      state.displayMode = action.payload
    },
    setRoomConfig(state, action: PayloadAction<MeetRoomConfigResponse>) {
      state.roomConfig = action.payload
    },
    setTwilioConfig(state, action: PayloadAction<TwilioConfig>) {
      state.twilioConfig = action.payload
    },
    setBotType(state, action: PayloadAction<BotType>) {
      state.botType = action.payload
    },
    resetAppState(state) {
      state = INITIAL_STATE
    },
  },
})

export const {
  setToken,
  setVeefriendsToken,
  addGuestCode,
  setRoomId,
  setRole,
  setIsChatEnabled,
  setDisplayName,
  setAvatarUrl,
  setDisplayMode,
  setRoomConfig,
  setTwilioConfig,
  setBotType,
  resetAppState,
} = configSlice.actions

export const configReducer = configSlice.reducer