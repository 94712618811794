import { memo } from 'react'
import { Center, Flex, IconButton, Text } from '@chakra-ui/react'
import { ControlButtonText } from '../button'

interface ToastProps {
  title: string
  type: 'join' | 'leave' | 'hand' | 'info' | 'error' | 'success' | 'record'
  onClose: () => void
}

const _Toast = ({ title, type, onClose }: ToastProps) => {
  let backgroundColor = 'statusSuccess'
  let icon = 'waving_hand'

  switch (type) {
    case 'leave':
      backgroundColor = 'statusFailure'
      icon = 'logout'
      break
    case 'hand':
      backgroundColor = 'statusWarning'
      icon = 'pan_tool'
      break
    case 'info':
      backgroundColor = 'statusInfo'
      icon = 'info'
      break
    case 'error':
      backgroundColor = 'statusFailure'
      icon = 'error'
      break
    case 'success':
      icon = 'check_circle'
      break
    case 'record':
      backgroundColor = 'statusFailure'
      icon = 'radio_button_checked'
  }

  return (
    <Flex
      alignItems="center"
      width={['20rem', null, '25rem']}
      height="4rem"
      backgroundColor="black"
      borderRadius={12}
      overflow="hidden"
    >
      <Center backgroundColor={backgroundColor} height="100%" padding={4}>
        <ControlButtonText className="material-symbols-outlined">
          {icon}
        </ControlButtonText>
      </Center>

      <Flex flex={1} alignItems="center">
        <Text
          fontSize={['xs', null, 'md']}
          fontWeight={500}
          marginLeft={4}
        >
          {title}
        </Text>
      </Flex>

      <IconButton
        aria-label="Close toast"
        onClick={onClose}
        marginRight={3}
      >
        <ControlButtonText className="material-symbols-outlined">
          close
        </ControlButtonText>
      </IconButton>
    </Flex>
  )
}

export const Toast = memo(_Toast)