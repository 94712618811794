import { memo } from 'react'
import {
  Center,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
} from '@chakra-ui/react'
import {
  HMSTrack,
  selectIsSomeoneScreenSharing,
  selectPeerByID,
  selectPeersByRole,
  selectVideoTrackByPeerID,
  useHMSStore,
  useRemoteAVToggle,
} from '@100mslive/react-sdk'
import { ControlButton, ControlButtonText } from '../button'
import { setPinnedPeer, useAppDispatch, useAppSelector } from '../../store'
import { Role, DisplayMode } from '../../models'

const determineIfPinnable = (
  displayMode: DisplayMode,
  peerRole?: string,
  track?: HMSTrack
) => {
  if (displayMode === 'standard') {
    return true
  }

  if (!track || !track.enabled) {
    return false
  }

  if (
    peerRole === Role.StageModerator ||
    peerRole === Role.StageMain ||
    peerRole === Role.StageViewer
  ) {
    return true
  }

  return false
}

interface PeerControlProps {
  peerId: string
  isVisible: boolean
  small?: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: () => void
}

const _PeerControl = ({
  peerId,
  isVisible,
  small = false,
  onOpen,
  onClose,
  onSubmit,
}: PeerControlProps) => {
  const { displayMode } = useAppSelector((state) => state.config)
  const { pinnedPeerId } = useAppSelector((state) => state.app)

  const dispatch = useAppDispatch()

  const peer = useHMSStore(selectPeerByID(peerId))
  const track = useHMSStore(selectVideoTrackByPeerID(peerId))

  // Stage roles
  const stageModeratorPeers = useHMSStore(
    selectPeersByRole(Role.StageModerator)
  )
  const stageMainPeers = useHMSStore(selectPeersByRole(Role.StageMain))
  const stageViewerPeers = useHMSStore(selectPeersByRole(Role.StageViewer))

  // Standard roles
  const hostPeers = useHMSStore(selectPeersByRole(Role.Host))
  const guestHostPeers = useHMSStore(selectPeersByRole(Role.GuestHost))
  const guestPeers = useHMSStore(selectPeersByRole(Role.Guest))
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing)

  const { volume, setVolume } = useRemoteAVToggle(
    peer?.audioTrack!,
    peer?.videoTrack!,
    console.error
  )

  const volumeDisclosure = useDisclosure()

  const visiblePeers =
    displayMode === 'stage'
      ? [...stageModeratorPeers, ...stageMainPeers, ...stageViewerPeers]
        .length
      : [...hostPeers, ...guestHostPeers, ...guestPeers].length

  const isPinnable = determineIfPinnable(displayMode, peer?.roleName, track)
  const isPinned = pinnedPeerId === peerId

  const onPinUserClick = () => {
    if (isPinned) {
      dispatch(setPinnedPeer(''))
      return
    }

    if (peer) {
      dispatch(setPinnedPeer(peer.id))
    }
  }

  if (isVisible) {
    return (
      <Center
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left={0}
        flexDirection="column"
        backgroundColor="backgroundPrimaryExtraExtraDark"
        backdropFilter="blur(8px)"
        borderRadius={small ? 8 : 12}
      >
        <Popover
          placement="top"
          isOpen={volumeDisclosure.isOpen}
          onOpen={onOpen}
          onClose={() => {
            volumeDisclosure.onClose()
            onClose()
          }}
        >
          <PopoverAnchor>
            <HStack
              spacing={2}
              flexWrap="wrap"
              justifyContent="center"
              marginBottom={2}
            >
              {volume && setVolume ? (
                <PopoverTrigger>
                  <ControlButton
                    height="40px"
                    width="40px"
                    label="Adjust volume"
                    aria-label="Adjust volume"
                    backgroundColor="backgroundPrimaryExtraDark"
                    onClick={volumeDisclosure.onToggle}
                  >
                    <ControlButtonText className="material-symbols-outlined">
                      volume_up
                    </ControlButtonText>
                  </ControlButton>
                </PopoverTrigger>
              ) : null}

              {isPinnable &&
                visiblePeers > 1 &&
                !isSomeoneScreenSharing ? (
                <ControlButton
                  height="40px"
                  width="40px"
                  label={isPinned ? 'Unpin' : 'Pin'}
                  aria-label={isPinned ? 'Unpin' : 'Pin'}
                  backgroundColor={
                    isPinned
                      ? 'buttonConfirm'
                      : 'backgroundPrimaryExtraDark'
                  }
                  onClick={onPinUserClick}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    push_pin
                  </ControlButtonText>
                </ControlButton>
              ) : null}
            </HStack>
          </PopoverAnchor>

          <PopoverContent
            height="30px"
            width="120px"
            backgroundColor="backgroundSecondary"
            border="none"
            borderRadius="full"
          >
            <PopoverBody height="100%" padding={0}>
              <Center height="100%" paddingX={4}>
                <Slider
                  aria-label="Volume slider"
                  defaultValue={volume}
                  min={10}
                  max={100}
                  step={10}
                  onChangeEnd={(value) => {
                    if (setVolume) {
                      setVolume(value)
                    }
                    volumeDisclosure.onToggle()
                    onSubmit()
                  }}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Center>
    )
  }

  return null
}

export const PeerControl = memo(_PeerControl)