import { memo, useState } from 'react'
import { useHMSActions } from '@100mslive/react-sdk'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  Flex,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
import {
  useAppDispatch,
  useAppSelector,
  setShowEndMeetingModal,
} from '../../store'
import { useNavigate, useParams } from 'react-router-dom'

const _EndMeetingModal = () => {
  const navigate = useNavigate()
  const { meetingId } = useParams()
  const dispatch = useAppDispatch()

  const { showEndMeetingModal } = useAppSelector((state) => state.app)

  const [shouldLockRoom, setShouldLockRoom] = useState(false)

  const hmsActions = useHMSActions()

  const onCancelClick = () => dispatch(setShowEndMeetingModal(false))

  const onEndMeetingClick = () => {
    hmsActions.endRoom(shouldLockRoom, 'Thanks for joining!')
    dispatch(setShowEndMeetingModal(false))
    navigate(`/${meetingId}/leave`)
  }

  return (
    <Modal
      isOpen={showEndMeetingModal}
      isCentered
      size="xl"
      onClose={() => dispatch(setShowEndMeetingModal(false))}
    >
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>End Meeting</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody paddingBottom={4}>
          <Text fontSize="lg" marginBottom={2}>
            Are you sure you want to end the meeting for everyone?
          </Text>

          <Flex justifyContent="flex-start" marginBottom={4}>
            <FormLabel
              htmlFor="lock-room"
              color="textSecondary"
              fontSize="sm"
            >
              Also lock room access
            </FormLabel>
            <Switch
              id="lock-room"
              size="md"
              sx={{
                'span.chakra-switch__track[data-checked]': {
                  backgroundColor: 'buttonConfirm',
                },
              }}
              isChecked={shouldLockRoom}
              onChange={({ currentTarget }) =>
                setShouldLockRoom(currentTarget.checked)
              }
            />
          </Flex>

          <Button
            backgroundColor="buttonDeny"
            color="white"
            marginRight={4}
            onClick={onCancelClick}
          >
            Cancel
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={onEndMeetingClick}
          >
            End Meeting
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const EndMeetingModal = memo(_EndMeetingModal)