import { memo } from 'react'
import {
  Flex,
  Center,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  Portal,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  HMSTrack,
  selectIsSomeoneScreenSharing,
  selectLocalPeerID,
  selectPeerByID,
  selectPeerMetadata,
  selectPeersByRole,
  selectVideoTrackByPeerID,
  useHMSActions,
  useHMSStore,
  useRemoteAVToggle,
} from '@100mslive/react-sdk'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ControlButton, ControlButtonText } from '../button'
import { Toast } from '../toast'
import { setPinnedPeer, useAppDispatch, useAppSelector } from '../../store'
import {
  Role,
  STANDARD_ROLES,
  STAGE_ROLES,
  CustomMetadata,
  DisplayMode,
} from '../../models'
import { formatRole } from '../../utils'
import { TOAST_DURATION, MOD_FUNC_LOWER_HAND } from '../../constants'

const determineIfPinnable = (
  displayMode: DisplayMode,
  peerRole?: string,
  track?: HMSTrack
) => {
  if (displayMode === 'standard') {
    return true
  }

  if (!track || !track.enabled) {
    return false
  }

  if (
    peerRole === Role.StageModerator ||
    peerRole === Role.StageMain ||
    peerRole === Role.StageViewer
  ) {
    return true
  }

  return false
}

const validationSchema = yup.object().shape({
  role: yup.string().required(),
})

interface PeerAdminControlProps {
  peerId: string
  isVisible: boolean
  small?: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: () => void
}

const _PeerAdminControl = ({
  peerId,
  isVisible,
  small = false,
  onOpen,
  onClose,
  onSubmit,
}: PeerAdminControlProps) => {
  const { displayMode } = useAppSelector((state) => state.config)
  const { pinnedPeerId } = useAppSelector((state) => state.app)

  const dispatch = useAppDispatch()

  const localPeerId = useHMSStore(selectLocalPeerID)
  const peer = useHMSStore(selectPeerByID(peerId))
  const track = useHMSStore(selectVideoTrackByPeerID(peerId))
  const metadata: CustomMetadata = useHMSStore(selectPeerMetadata(peerId))

  // Stage roles
  const stageModeratorPeers = useHMSStore(
    selectPeersByRole(Role.StageModerator)
  )
  const stageMainPeers = useHMSStore(selectPeersByRole(Role.StageMain))
  const stageViewerPeers = useHMSStore(selectPeersByRole(Role.StageViewer))

  // Standard roles
  const hostPeers = useHMSStore(selectPeersByRole(Role.Host))
  const guestHostPeers = useHMSStore(selectPeersByRole(Role.GuestHost))
  const guestPeers = useHMSStore(selectPeersByRole(Role.Guest))
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing)
  const hmsActions = useHMSActions()

  const {
    isAudioEnabled,
    isVideoEnabled,
    volume,
    toggleAudio,
    toggleVideo,
    setVolume,
  } = useRemoteAVToggle(peer?.audioTrack!, peer?.videoTrack!, console.error)

  const roleDisclosure = useDisclosure()
  const volumeDisclosure = useDisclosure()

  const toast = useToast()

  const roles = displayMode === 'standard' ? STANDARD_ROLES : STAGE_ROLES

  const visiblePeers =
    displayMode === 'stage'
      ? [...stageModeratorPeers, ...stageMainPeers, ...stageViewerPeers]
        .length
      : [...hostPeers, ...guestHostPeers, ...guestPeers].length

  const isLocal = localPeerId === peerId
  const isPinnable = determineIfPinnable(displayMode, peer?.roleName, track)
  const isPinned = pinnedPeerId === peerId

  const controlButtonSize = small ? '30px' : '40px'
  const controlButtonFontSize = small ? 14 : 20
  const controlButtonSpacing = small ? 1 : 2

  const onLowerHandClick = () => {
    try {
      hmsActions.sendDirectMessage(MOD_FUNC_LOWER_HAND, peerId)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeRoleSubmit = async (role: string) => {
    try {
      let force = false
      if (role === Role.Viewer || role === Role.HLSViewer) {
        force = true
      } else if (
        (peer?.roleName === Role.StageMain ||
          peer?.roleName === Role.StageViewer) &&
        role === Role.BackstageViewer
      ) {
        force = true
      } else if (isLocal) {
        force = true
      }

      await hmsActions.changeRole(peerId, role, force)

      if (!force) {
        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              type="info"
              title="Role change request sent"
            />
          ),
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      roleDisclosure.onClose()
      onSubmit()
    }
  }

  const onPinUserClick = () => {
    if (isPinned) {
      dispatch(setPinnedPeer(''))
      return
    }

    if (peer) {
      dispatch(setPinnedPeer(peer.id))
    }
  }

  const onRemoveClick = () => {
    try {
      hmsActions.removePeer(peerId, 'Goodbye!')
    } catch (error) {
      console.error(error)
    }
  }

  if (isVisible) {
    return (
      <Center
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left={0}
        flexDirection="column"
        backgroundColor="backgroundPrimaryExtraExtraDark"
        backdropFilter="blur(8px)"
        borderRadius={small ? 8 : 12}
      >
        <Popover
          placement="top"
          isOpen={volumeDisclosure.isOpen}
          onOpen={onOpen}
          onClose={() => {
            volumeDisclosure.onClose()
            onClose()
          }}
        >
          <PopoverAnchor>
            <HStack
              spacing={controlButtonSpacing}
              flexWrap="wrap"
              justifyContent="center"
              marginBottom={controlButtonSpacing}
            >
              {toggleVideo && isVideoEnabled && !isLocal ? (
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label="Disable video"
                  aria-label="Disable user video"
                  backgroundColor="backgroundPrimaryExtraDark"
                  onClick={toggleVideo}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    videocam_off
                  </ControlButtonText>
                </ControlButton>
              ) : null}

              {toggleAudio && isAudioEnabled && !isLocal ? (
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label="Disable audio"
                  aria-label="Disable user audio"
                  backgroundColor="backgroundPrimaryExtraDark"
                  onClick={toggleAudio}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    mic_off
                  </ControlButtonText>
                </ControlButton>
              ) : null}

              {volume && setVolume ? (
                <PopoverTrigger>
                  <ControlButton
                    height={controlButtonSize}
                    width={controlButtonSize}
                    label="Adjust volume"
                    aria-label="Adjust volume"
                    backgroundColor="backgroundPrimaryExtraDark"
                    onClick={volumeDisclosure.onToggle}
                  >
                    <ControlButtonText
                      className="material-symbols-outlined"
                      fontSize={controlButtonFontSize}
                    >
                      volume_up
                    </ControlButtonText>
                  </ControlButton>
                </PopoverTrigger>
              ) : null}
            </HStack>
          </PopoverAnchor>

          <PopoverContent
            height="30px"
            width="120px"
            backgroundColor="backgroundSecondary"
            border="none"
            borderRadius="full"
          >
            <PopoverBody height="100%" padding={0}>
              <Center height="100%" paddingX={4}>
                <Slider
                  aria-label="Volume slider"
                  defaultValue={volume}
                  min={10}
                  max={100}
                  step={10}
                  onChangeEnd={(value) => {
                    if (setVolume) {
                      setVolume(value)
                    }
                    volumeDisclosure.onToggle()
                    onSubmit()
                  }}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Popover
          placement="bottom"
          isOpen={roleDisclosure.isOpen}
          onOpen={onOpen}
          onClose={() => {
            roleDisclosure.onClose()
            onClose()
          }}
        >
          <PopoverAnchor>
            <HStack
              spacing={controlButtonSpacing}
              flexWrap="wrap"
              justifyContent="center"
            >
              {metadata.isHandRaised && !isLocal ? (
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label="Lower hand"
                  aria-label="Lower user hand"
                  backgroundColor="backgroundPrimaryExtraDark"
                  onClick={onLowerHandClick}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    do_not_touch
                  </ControlButtonText>
                </ControlButton>
              ) : null}

              {isPinnable &&
                visiblePeers > 1 &&
                !isSomeoneScreenSharing ? (
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label={isPinned ? 'Unpin' : 'Pin'}
                  aria-label={isPinned ? 'Unpin' : 'Pin'}
                  backgroundColor={
                    isPinned
                      ? 'buttonConfirm'
                      : 'backgroundPrimaryExtraDark'
                  }
                  onClick={onPinUserClick}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    push_pin
                  </ControlButtonText>
                </ControlButton>
              ) : null}

              <PopoverTrigger>
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label="Change user role"
                  aria-label="Change user role"
                  backgroundColor="backgroundPrimaryExtraDark"
                  onClick={roleDisclosure.onToggle}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    manage_accounts
                  </ControlButtonText>
                </ControlButton>
              </PopoverTrigger>

              {!isLocal ? (
                <ControlButton
                  height={controlButtonSize}
                  width={controlButtonSize}
                  label="Remove from meeting"
                  aria-label="Remove from meeting"
                  backgroundColor="buttonDeny"
                  onClick={onRemoveClick}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    fontSize={controlButtonFontSize}
                  >
                    gavel
                  </ControlButtonText>
                </ControlButton>
              ) : null}
            </HStack>
          </PopoverAnchor>

          <Portal>
            <PopoverContent
              paddingY={1}
              backgroundColor="black"
              border="none"
              borderRadius="full"
            >
              <PopoverBody>
                <Formik
                  initialValues={{ role: '' }}
                  validateOnMount
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    onChangeRoleSubmit(values.role)
                  }}
                >
                  {({ isValid, setValues }) => (
                    <Form>
                      <Flex flexDirection="row">
                        <Select
                          borderRadius="full"
                          fontSize="sm"
                          onChange={({
                            currentTarget,
                          }) => {
                            setValues({
                              role: currentTarget.value,
                            })
                          }}
                        >
                          {[
                            'Select role',
                            ...roles,
                          ].map((role) => (
                            <option
                              key={role}
                              value={
                                role ===
                                  'Select role'
                                  ? ''
                                  : role
                              }
                            >
                              {role ===
                                'Select role'
                                ? role
                                : formatRole(
                                  role
                                )}
                            </option>
                          ))}
                        </Select>

                        <ControlButton
                          type="submit"
                          aria-label="Submit"
                          height={controlButtonSize}
                          width={controlButtonSize}
                          backgroundColor="buttonConfirm"
                          disabled={!isValid}
                          marginLeft={2}
                        >
                          <ControlButtonText
                            className="material-symbols-outlined"
                            fontSize={
                              controlButtonFontSize
                            }
                          >
                            check
                          </ControlButtonText>
                        </ControlButton>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Center>
    )
  }

  return null
}

export const PeerAdminControl = memo(_PeerAdminControl)