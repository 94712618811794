import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import { decode } from 'base-64'
import { FullPageLoader } from '../../components'
import {
  useAppDispatch,
  setVeefriendsToken,
  setDisplayName,
  setAvatarUrl,
  setErrorMessage,
  setToken,
  setRoomId,
  setRole,
  setRoomConfig,
  setDisplayMode,
  useAppSelector,
  setBotType,
  setTwilioConfig,
  addGuestCode,
} from '../../store'
import { meetApi } from '../../services/meetApi'
import { BotType, JWT_100ms, VeeFriendsConfig } from '../../models'
import {
  QUERY_PARAM_BOT_TOKEN,
  QUERY_PARAM_BOT_TYPE,
  QUERY_PARAM_VF_TOKEN,
  QUERY_PARAM_VF_GUEST_CODE,
} from '../../constants'

const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL

const APP_URL = process.env.REACT_APP_URL

export const LandingPage = () => {
  const { meetingId } = useParams()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  // query param data
  const qpBotToken = searchParams.get(QUERY_PARAM_BOT_TOKEN)
  const qpBotType = searchParams.get(QUERY_PARAM_BOT_TYPE) as BotType | null
  const qpVfToken = searchParams.get(QUERY_PARAM_VF_TOKEN)
  const qpGuestCode = searchParams.get(QUERY_PARAM_VF_GUEST_CODE)

  // cookie data
  const data = Cookies.get('veefriends_meet')

  // app state
  const { veefriendsToken } = useAppSelector((state) => state.config)

  // Store query params for hls bot and vf auth token if present
  useEffect(() => {
    if (qpBotToken && qpBotType) {
      dispatch(setVeefriendsToken(qpBotToken))
      dispatch(setBotType(qpBotType))

      return
    }

    if (meetingId && qpGuestCode) {
      dispatch(addGuestCode({ meetingId, guestCode: qpGuestCode }))
      dispatch(setVeefriendsToken(''))

      return
    }

    if (data) {
      const { bearerToken, avatarUrl, username }: VeeFriendsConfig =
        JSON.parse(decode(data))

      dispatch(setVeefriendsToken(bearerToken))
      dispatch(setAvatarUrl(avatarUrl))
      dispatch(setDisplayName(username))

      return
    }

    if (qpVfToken && process.env.NODE_ENV === 'development') {
      const { bearerToken, avatarUrl, username }: VeeFriendsConfig =
        JSON.parse(decode(qpVfToken))

      dispatch(setVeefriendsToken(bearerToken))
      dispatch(setAvatarUrl(avatarUrl))
      dispatch(setDisplayName(username))

      return
    }

    dispatch(setVeefriendsToken(''))
  }, [
    qpBotToken,
    qpBotType,
    qpVfToken,
    qpGuestCode,
    meetingId,
    data,
    dispatch,
  ])

  useEffect(() => {
    ; (async () => {
      try {
        // User agent is a bot, redirect to bot page, token required
        if (meetingId && qpBotType && veefriendsToken !== '') {
          const response = await dispatch(
            meetApi.endpoints.getMeetRoomAdminEndpoint.initiate({
              id: meetingId,
            })
          )

          if (response.isError || !response.data) {
            throw new Error(
              `${qpBotType.toUpperCase()} Bot failed to join room. Invalid token provided.`
            )
          }

          if (!response.data.jwt) {
            throw new Error(
              `${qpBotType.toUpperCase()} Bot failed to join room. Meeting ID is either invalid or no longer in use.`
            )
          }

          const { role, room_id }: JWT_100ms = jwtDecode(
            response.data.jwt
          )

          if (response.data.config) {
            dispatch(setRoomConfig(response.data.config))
          }

          dispatch(setToken(response.data.jwt))
          dispatch(setRoomId(room_id))
          dispatch(setRole(role))
          dispatch(
            setDisplayMode(
              response.data.config?.displayMode === 'stage'
                ? 'stage'
                : 'standard'
            )
          )

          navigate('bot')

          return
        }

        // Guest code found
        else if (meetingId && qpGuestCode) {
          const response = await dispatch(
            meetApi.endpoints.getMeetRoomPrivateEndpoint.initiate({
              id: meetingId,
              code: qpGuestCode,
            })
          )

          const chatResponse = await dispatch(
            meetApi.endpoints.getChatRoomPrivateEndpoint.initiate({
              id: meetingId,
              code: qpGuestCode,
            })
          )

          if (response.isError) throw response.error

          if (chatResponse.isError) throw chatResponse.error

          if (!response.data?.jwt) {
            throw new Error(
              'Meeting ID is either invalid or no longer in use.'
            )
          }

          const { role, room_id }: JWT_100ms = jwtDecode(
            response.data.jwt
          )

          if (response.data.config) {
            dispatch(setRoomConfig(response.data.config))
          }

          if (
            chatResponse.data?.jwt &&
            chatResponse.data.chatParticipantId &&
            chatResponse.data?.config?.chatId
          ) {
            dispatch(
              setTwilioConfig({
                token: chatResponse.data.jwt,
                participantSid:
                  chatResponse.data.chatParticipantId,
                conversationSid:
                  chatResponse.data.config.chatId,
              })
            )
          }

          dispatch(setToken(response.data.jwt))
          dispatch(setRoomId(room_id))
          dispatch(setRole(role))
          dispatch(
            setDisplayMode(
              response.data.config?.displayMode === 'stage'
                ? 'stage'
                : 'standard'
            )
          )

          navigate('preview')

          return
        }

        // Standard app flow
        else if (meetingId && !qpBotType) {
          const response = await dispatch(
            meetApi.endpoints.getMeetRoomEndpoint.initiate({
              id: meetingId,
            })
          )

          const chatResponse = await dispatch(
            meetApi.endpoints.getChatRoomEndpoint.initiate({
              id: meetingId,
            })
          )

          // If 401 unauthorized, redirect to vf auth page
          if (
            response.isError &&
            'status' in response.error &&
            response.error.status === 401
          ) {
            window.location.replace(
              `${LOGIN_REDIRECT_URL}?returnUrl=${APP_URL}${meetingId}`
            )
            return
          }

          // If 400 and not eligible, redirect to not eligible page
          if (
            response.isError &&
            'status' in response.error &&
            response.error.status === 400 &&
            'data' in response.error &&
            'messages' in (response.error.data as any) &&
            (response.error.data as any).messages[0] ===
            'You are not eligible to join this meeting'
          ) {
            navigate('/not-eligible')
            return
          }

          // Throw other non-401 errors
          if (response.isError || !response.data) {
            throw new Error('Error retrieving meeting data')
          }

          if (!response.data.jwt) {
            throw new Error(
              'Meeting ID is either invalid or no longer in use.'
            )
          }

          // Success response
          const { role, room_id }: JWT_100ms = jwtDecode(
            response.data.jwt
          )

          if (response.data.config) {
            dispatch(setRoomConfig(response.data.config))
          }

          if (
            chatResponse.data?.jwt &&
            chatResponse.data.chatParticipantId &&
            chatResponse.data?.config?.chatId
          ) {
            dispatch(
              setTwilioConfig({
                token: chatResponse.data.jwt,
                participantSid:
                  chatResponse.data.chatParticipantId,
                conversationSid:
                  chatResponse.data.config.chatId,
              })
            )
          }

          dispatch(setToken(response.data.jwt))
          dispatch(setRoomId(room_id))
          dispatch(setRole(role))
          dispatch(
            setDisplayMode(
              response.data.config?.displayMode === 'stage'
                ? 'stage'
                : 'standard'
            )
          )

          navigate('preview')

          return
        }

        // Meeting ID not found
        else if (!meetingId) {
          throw new Error('Meeting ID not found')
        }
      } catch (error) {
        let errorMessage = 'Error retrieving meeting data'
        if (error instanceof Error) {
          errorMessage = error.message
        }

        dispatch(setErrorMessage(errorMessage))
        navigate('/error')
      }
    })()
  }, [meetingId, qpBotType, qpGuestCode, veefriendsToken, dispatch, navigate])

  return <FullPageLoader />
}