import { extendTheme, theme as defaultTheme } from '@chakra-ui/react'
import { colors } from './colors'

const customTheme = {
  ...defaultTheme,
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  fonts: {
    ...defaultTheme.fonts,
    heading: 'obviously-narrow',
  },
  colors,
}

export const theme = extendTheme(customTheme)