// @ts-ignore
import zlib from 'react-zlib-js'
import { Buffer } from 'buffer'
import { useState, CSSProperties, memo, useMemo } from 'react'
import {
  selectAudioTrackByPeerID,
  selectIsPeerAudioEnabled,
  selectLocalPeerID,
  selectPeerByID,
  selectPeerMetadata,
  selectPermissions,
  selectTrackByID,
  selectVideoTrackByPeerID,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Video, useBorderAudioLevel } from '@100mslive/roomkit-react'
import { ChakraProps, Box, Center, Text, Avatar } from '@chakra-ui/react'
import screenfull from 'screenfull'
import { TileOverlay } from './TileOverlay'
import { PeerControl, PeerAdminControl } from '../control'
import { ControlButton, ControlButtonText } from '../button'
import { CustomMetadata, NftData } from '../../models'
import { useAppSelector } from '../../store'
import { formatPeerBadges, PeerProps } from '../../utils'

interface VideoTileProps {
  peerId?: string
  trackId?: string
  height?: number
  width?: number
  small?: boolean
  containerStyle?: ChakraProps
  videoStyle?: CSSProperties
  onMouseEnterCallback?: () => void
  onMouseLeaveCallback?: () => void
}

const _VideoTile = ({
  peerId,
  trackId,
  height,
  width,
  small = false,
  containerStyle,
  videoStyle,
  onMouseEnterCallback,
  onMouseLeaveCallback,
}: VideoTileProps) => {
  const { mirrorVideo } = useAppSelector((state) => state.app)

  const trackSelector = trackId
    ? selectTrackByID(trackId)
    : selectVideoTrackByPeerID(peerId)
  const track = useHMSStore(trackSelector)
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId))
  const isAudioMuted = !useHMSStore(selectIsPeerAudioEnabled(peerId))

  const localPeerId = useHMSStore(selectLocalPeerID)
  const peer = useHMSStore(selectPeerByID(peerId))
  const metadata: CustomMetadata = useHMSStore(
    selectPeerMetadata(peerId || '')
  )
  const permissions = useHMSStore(selectPermissions)

  const borderAudioRef = useBorderAudioLevel(audioTrack?.id)

  const [isControlVisible, setIsControlVisible] = useState(false)
  const [isControlActive, setIsControlActive] = useState(false)

  const isVideoMuted = !track?.enabled

  const isVideoDegraded =
    !!track && 'degraded' in track && track.degraded
      ? track.degraded
      : false

  const isLocal = localPeerId === peerId

  const showAdminControls =
    permissions?.removeOthers && permissions.mute && permissions.changeRole

  const overlayFontSize = small ? '14px' : '20px'
  const nameFontSize = small ? '12px' : '16px'
  const namePositionOffset = small ? '0.5em' : '0.75em'
  const controlButtonSize = small ? '30px' : '40px'
  const controlButtonFontSize = small ? 14 : 20

  const badgeConfig: PeerProps = useMemo(() => {
    const partial: PeerProps = {
      moderator: peer?.roleName?.toLowerCase().includes('moderator'),
      guest: metadata.isGuestSpeaker,
    }

    try {
      if (!metadata.nfts) {
        return partial
      }

      const data = JSON.parse(
        zlib.gunzipSync(Buffer.from(metadata.nfts, 'base64')).toString()
      ) as NftData

      return {
        ...partial,
        s1SpecHolder: data.s1spec,
        s1GgHolder: data.s1gg,
        s1AccessHolder: data.s1a,
      }
    } catch (error) {
      console.error(error)
      console.log('error unzipping: ', metadata)

      return partial
    }
  }, [metadata, peer])

  const onMouseEnter = () => setIsControlVisible(true)

  const onMouseLeave = () => setIsControlVisible(false)

  const onOpen = () => setIsControlActive(true)

  const onClose = () => {
    setIsControlActive(false)
    setIsControlVisible(false)
  }

  const onSubmit = () => setIsControlActive(false)

  const onFullscreenClick = () => {
    if (screenfull.isEnabled) {
      const element = document.getElementById(`video-${track?.id}`)
      if (element) {
        screenfull.request(element)
      }
    }
  }

  if (!peerId) {
    return null
  }

  return (
    <Box
      height={height ? `${height}px` : '100%'}
      width={width ? `${width}px` : '100%'}
      {...containerStyle}
    >
      <Box
        ref={borderAudioRef}
        position="relative"
        height="100%"
        width="100%"
        borderRadius={small ? 8 : 12}
        onMouseEnter={() => {
          onMouseEnter()
          if (onMouseEnterCallback) onMouseEnterCallback()
        }}
        onMouseLeave={() => {
          if (isControlActive) return

          onMouseLeave()
          if (onMouseLeaveCallback) onMouseLeaveCallback()
        }}
        overflow="hidden"
      >
        {track ? (
          <Video
            id={`video-${track.id}`}
            trackId={track.id}
            mirror={isLocal && mirrorVideo}
            degraded={isVideoDegraded}
            style={{
              borderRadius: small ? 8 : 12,
              overflow: 'hidden',
              objectFit: 'contain',
              ...videoStyle,
            }}
          />
        ) : null}

        {isVideoMuted || isVideoDegraded ? (
          <Center
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor="backgroundSecondary"
          >
            <Avatar
              name={peer?.name}
              src={metadata.avatarUrl}
              size={small ? 'md' : 'xl'}
              backgroundColor={
                metadata.avatarUrl ? 'black' : 'headingPrimary'
              }
              color="white"
            />
          </Center>
        ) : null}

        {isAudioMuted ? (
          <TileOverlay placement="bottom-right" small={small}>
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize={['12px', null, null, overlayFontSize]}
            >
              mic_off
            </ControlButtonText>
          </TileOverlay>
        ) : null}

        {metadata.isHandRaised ? (
          <TileOverlay placement="top-right" small={small}>
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize={['12px', null, null, overlayFontSize]}
            >
              pan_tool
            </ControlButtonText>
          </TileOverlay>
        ) : null}

        {peer?.name ? (
          <Text
            position="absolute"
            top={namePositionOffset}
            left={namePositionOffset}
            color="white"
            textShadow="2px 2px 8px black"
            fontWeight={600}
            fontSize={['8px', null, null, nameFontSize]}
          >
            {formatPeerBadges(badgeConfig)}
            {isLocal ? 'You' : peer.name}
          </Text>
        ) : null}

        {showAdminControls ? (
          <PeerAdminControl
            peerId={peerId}
            isVisible={isControlVisible}
            small={small}
            onOpen={onOpen}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        ) : null}

        {!showAdminControls && !isLocal ? (
          <PeerControl
            peerId={peerId}
            isVisible={isControlVisible}
            small={small}
            onOpen={onOpen}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        ) : null}

        {isControlVisible &&
          screenfull.isEnabled &&
          localPeerId !== peerId ? (
          <ControlButton
            position="absolute"
            top={2}
            right={2}
            height={controlButtonSize}
            width={controlButtonSize}
            label="Fullscreen"
            aria-label="Fullscreen"
            backgroundColor="backgroundPrimaryExtraExtraDark"
            _hover={{
              backgroundColor: 'backgroundPrimaryExtraDark',
            }}
            onClick={onFullscreenClick}
          >
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize={controlButtonFontSize}
            >
              fullscreen
            </ControlButtonText>
          </ControlButton>
        ) : null}
      </Box>
    </Box>
  )
}

export const VideoTile = memo(_VideoTile)