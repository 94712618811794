import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { decode } from 'base-64'
import { cloneDeep } from 'lodash'
import { Container, useToast, Image, Button, Box } from '@chakra-ui/react'
import {
  CreateEditRoomForm,
  CreateEditRoomFormValues,
} from './CreateEditRoomForm'
import { RoomList } from './RoomList'
import { Page, Toast, Footer } from '../../components'
import {
  useAppDispatch,
  setVeefriendsToken,
  setAvatarUrl,
  setDisplayName,
  useAppSelector,
} from '../../store'
import {
  AdminDropdownModel,
  meetApi,
  RoomDataModel,
} from '../../services/meetApi'
import { VeeFriendsConfig, ADMIN_ROLE, VEEFRIENDS_ROLE } from '../../models'
import { QUERY_PARAM_VF_TOKEN, TOAST_DURATION } from '../../constants'
import logo from '../../assets/images/logo-color.svg'

const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL

const APP_URL = process.env.REACT_APP_URL

export const AdminPage = () => {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const toast = useToast()

  const data = Cookies.get('veefriends_meet')

  const qpVfToken = searchParams.get(QUERY_PARAM_VF_TOKEN)

  const { veefriendsToken } = useAppSelector((state) => state.config)

  const [isLoading, setIsLoading] = useState(true)

  const [isLoadingGuestLink, setIsLoadingGuestLink] = useState(false)

  const [rooms, setRooms] = useState<{ [key: string]: RoomDataModel }>({})

  const [tokenWizardData, setTokenWizardData] = useState<
    AdminDropdownModel[]
  >([])

  const [showRoomForm, setShowRoomForm] = useState(false)

  const [editRoomValues, setEditRoomValues] = useState<
    CreateEditRoomFormValues | undefined
  >(undefined)

  const containerRef = useRef<HTMLDivElement>(null)

  const getRooms = useCallback(async () => {
    try {
      setIsLoading(true)

      const roomsResponse = await dispatch(
        meetApi.endpoints.getActiveRoomsEndpoint.initiate(
          { isLive: false },
          { forceRefetch: true }
        )
      )

      const dropdownResponse = await dispatch(
        meetApi.endpoints.getMeetDropdownsEndpoint.initiate()
      )

      // If 401 unauthorized and token expired, redirect to vf auth page
      // If 401 unauthorized and token valid, redirect to not-found page
      if (
        (roomsResponse.isError &&
          'status' in roomsResponse.error &&
          roomsResponse.error.status === 401) ||
        (dropdownResponse.isError &&
          'status' in dropdownResponse.error &&
          dropdownResponse.error.status === 401)
      ) {
        const { exp }: JwtPayload = jwtDecode(veefriendsToken)
        const tokenExpired = !!exp && Date.now() >= exp * 1000

        if (tokenExpired) {
          window.location.replace(
            `${LOGIN_REDIRECT_URL}?returnUrl=${APP_URL}admin`
          )
          return
        } else {
          navigate('/not-found')
          return
        }
      }

      // Throw other non 401 errors
      if (
        roomsResponse.isError ||
        !roomsResponse.data ||
        !roomsResponse.data.data ||
        dropdownResponse.isError ||
        !dropdownResponse.data
      ) {
        throw new Error('Error fetching room data.')
      }

      // Success response
      setRooms(roomsResponse.data.data)
      setTokenWizardData(dropdownResponse.data)
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error fetching rooms"
          />
        ),
      })
    } finally {
      setIsLoading(false)
    }
  }, [veefriendsToken, dispatch, navigate, toast])

  const handleCreateRoomClick = () => {
    setShowRoomForm(true)
  }

  const handleCreateCancelClick = () => {
    setShowRoomForm(false)

    if (editRoomValues) setEditRoomValues(undefined)
  }

  const handleCreateRoomSubmitClick = async (
    values: CreateEditRoomFormValues
  ) => {
    try {
      setIsLoading(true)

      let tokenAccessId = undefined
      if (values.tokenAccessId === 'none') {
        tokenAccessId = ''
      } else if (
        values.nftGates.length > 0 ||
        (values.inviteeEmails && values.inviteeEmails !== '')
      ) {
        tokenAccessId = undefined
      } else if (values.tokenAccessId !== undefined) {
        tokenAccessId = values.tokenAccessId
      }

      const requestModel = {
        type: values.type,
        tokenAccessId,
        description: values.description,
        roomConfig: {
          title: values.title,
          subTitle: values.subtitle,
          start: values.startTime,
          end: values.endTime,
          chatEnabled: values.chatEnabled,
          displayMode: 'stage',
          themeId: values.themeId,
        },
        invitees: values.inviteeEmails
          ? values.inviteeEmails
            .split(/,\s*/)
            .filter((entry: string) => entry && entry.length > 0)
          : undefined,
        adminInvitees: values.adminEmails
          ? values.adminEmails
            .split(/,\s*/)
            .filter((entry: string) => entry && entry.length > 0)
          : [],
        nftGates: values.nftGates,
      }

      let response
      if (editRoomValues && editRoomValues.roomId) {
        response = await dispatch(
          meetApi.endpoints.updateMeetEndpoint.initiate({
            roomId: editRoomValues.roomId,
            updateMeetRequestModel: requestModel,
          })
        )
      } else {
        response = await dispatch(
          meetApi.endpoints.createMeetingEndpoint.initiate({
            createMeetRequestModel: requestModel,
          })
        )
      }

      if ('error' in response) {
        throw new Error('Error creating/editing room')
      }

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="success"
            title={editRoomValues ? 'Room saved' : 'Room created'}
          />
        ),
      })

      setEditRoomValues(undefined)
      setShowRoomForm(false)
      getRooms()
    } catch (error) {
      console.error(error)

      setIsLoading(false)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error creating/editing room"
          />
        ),
      })
    }
  }

  const handleEditRoomClick = (roomId: string) => {
    const { roomConfig, invitees, admins, nftRules, tokenAccessId } =
      rooms[roomId]

    const values: CreateEditRoomFormValues = {
      roomId,
      title: roomConfig?.title || '',
      subtitle: roomConfig?.subTitle || '',
      description: roomConfig?.description || '',
      startTime: roomConfig?.start,
      endTime: roomConfig?.end,
      themeId: roomConfig?.themeId || '',
      chatEnabled: roomConfig?.chatEnabled || false,
      inviteeEmails:
        invitees && !tokenAccessId ? invitees.join(', ') : undefined,
      adminEmails: admins ? admins.join(', ') : undefined,
      nftGates: nftRules && !tokenAccessId ? nftRules : [],
      tokenAccessId: tokenAccessId || undefined,
      type: roomConfig?.type
        ? roomConfig.type.charAt(0).toUpperCase() +
        roomConfig.type.slice(1)
        : '',
    }

    if (
      values.tokenAccessId === undefined &&
      values.nftGates.length === 0 &&
      values.inviteeEmails === undefined
    ) {
      values.tokenAccessId = 'none'
    }

    setEditRoomValues(values)
    setShowRoomForm(true)

    containerRef.current &&
      containerRef.current.scroll({ top: 0, behavior: 'auto' })
  }

  const handleDeleteRoomClick = async (meetingId: string) => {
    try {
      setIsLoading(true)

      const result = await dispatch(
        meetApi.endpoints.closeMeetRoomEndpoint.initiate({
          id: meetingId,
          deleteTResponseModel: {},
        })
      )

      if ('error' in result) {
        throw new Error('Error deleting selected room')
      }

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="success"
            title="Room deleted"
          />
        ),
      })

      getRooms()
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error deleting room"
          />
        ),
      })

      setIsLoading(false)
    }
  }

  const handleNewGuestLinkClick = async (
    roomId: string,
    meetingId: string,
    friendlyName: string,
    emailAddress?: string
  ) => {
    try {
      setIsLoadingGuestLink(true)

      const response = await dispatch(
        meetApi.endpoints.createMeetingPrivateLinkEndpoint.initiate({
          id: meetingId,
          createPrivateLinkRequestModel: {
            friendlyName,
            emailAddress,
          },
        })
      )

      if ('error' in response) throw response.error

      const {
        code,
        friendlyName: _friendlyname,
        timestamp,
      } = response.data as any

      const newRooms = cloneDeep(rooms)
      const meeting = newRooms[roomId].roomRoles?.find(
        (meeting) => meeting.id === meetingId
      )
      if (meeting) {
        if (!meeting.privateCodes) {
          meeting.privateCodes = {}
        }

        meeting.privateCodes[code] = {
          friendlyName: _friendlyname,
          timestamp,
        }
      }

      setRooms(newRooms)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="success"
            title="Guest Link created"
          />
        ),
      })
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error creating guest link"
          />
        ),
      })
    } finally {
      setIsLoadingGuestLink(false)
    }
  }

  const handleDeleteGuestLinkClick = async (
    roomId: string,
    meetingId: string,
    code: string
  ) => {
    try {
      setIsLoadingGuestLink(true)

      const response = await dispatch(
        meetApi.endpoints.deleteMeetingPrivateLinkEndpoint.initiate({
          id: meetingId,
          code,
          createPrivateLinkRequestModel: {},
        })
      )

      if ('error' in response) throw response.error

      const newRooms = cloneDeep(rooms)
      const meeting = newRooms[roomId].roomRoles?.find(
        (meeting) => meeting.id === meetingId
      )
      if (meeting?.privateCodes) {
        delete meeting.privateCodes[code]
      }
      setRooms(newRooms)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="success"
            title="Guest Link deleted"
          />
        ),
      })
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error deleting guest link"
          />
        ),
      })
    } finally {
      setIsLoadingGuestLink(false)
    }
  }

  useEffect(() => {
    if (data) {
      const {
        bearerToken,
        avatarUrl,
        username,
        roles,
      }: VeeFriendsConfig = JSON.parse(decode(data))

      if (roles.indexOf(ADMIN_ROLE) >= 0 || roles.indexOf(VEEFRIENDS_ROLE) >= 0) {
        dispatch(setVeefriendsToken(bearerToken))
        dispatch(setAvatarUrl(avatarUrl))
        dispatch(setDisplayName(username))
      } else {
        navigate('/not-found')
      }
    } else if (qpVfToken && process.env.NODE_ENV === 'development') {
      const {
        bearerToken,
        avatarUrl,
        username,
        roles,
      }: VeeFriendsConfig = JSON.parse(decode(qpVfToken))

      if (roles.indexOf(ADMIN_ROLE) >= 0 || roles.indexOf(VEEFRIENDS_ROLE) >= 0) {
        dispatch(setVeefriendsToken(bearerToken))
        dispatch(setAvatarUrl(avatarUrl))
        dispatch(setDisplayName(username))
      } else {
        navigate('/not-found')
      }
    } else {
      window.location.replace(
        `${LOGIN_REDIRECT_URL}?returnUrl=${APP_URL}admin`
      )
    }
  }, [data, qpVfToken, dispatch, navigate])

  useEffect(() => {
    if (veefriendsToken !== '') {
      getRooms()
    }
  }, [veefriendsToken, getRooms])

  return (
    <Page
      ref={containerRef}
      accentColor="backgroundPrimary"
      containerStyle={{ overflowY: 'scroll' }}
    >
      <Container
        maxWidth="container.lg"
        paddingY={8}
        minHeight="calc(100% - 7rem)"
      >
        <Image
          src={logo}
          width="200px"
          height="auto"
          marginX="auto"
          marginBottom={12}
        />

        <Box
          backgroundColor="backgroundOverlay"
          backdropFilter="blur(8px)"
          borderRadius={20}
          padding={8}
          marginBottom={4}
        >
          {showRoomForm ? (
            <CreateEditRoomForm
              tokenWizardData={tokenWizardData}
              isLoading={isLoading}
              initialValues={editRoomValues}
              onCancelClick={handleCreateCancelClick}
              onSubmitClick={handleCreateRoomSubmitClick}
            />
          ) : (
            <RoomList
              isLoading={isLoading}
              isLoadingGuestLink={isLoadingGuestLink}
              rooms={rooms}
              onRefreshClick={getRooms}
              onNewGuestLinkClick={handleNewGuestLinkClick}
              onDeleteGuestLinkClick={handleDeleteGuestLinkClick}
              onEditClick={handleEditRoomClick}
              onDeleteClick={handleDeleteRoomClick}
            />
          )}
        </Box>

        {!showRoomForm && (
          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={handleCreateRoomClick}
          >
            Create Room
          </Button>
        )}
      </Container>

      <Footer />
    </Page>
  )
}