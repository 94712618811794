import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HMSMessage } from '@100mslive/react-sdk'
import { CHAT_PINNED_MESSAGES_MAX, PEER_GRID_MAX_TILES } from '../constants'

export type DisplayLayout = 'show all' | 'show active' | 'focused'

interface AppState {
  displayLayout: DisplayLayout
  botDisplayLayout: DisplayLayout
  showChatDrawer: boolean
  showParticipantDrawer: boolean
  showClearStageModal: boolean
  showPromoteGroupModal: boolean
  showSettingsModal: boolean
  showStartHlsModal: boolean
  showStartRtmpModal: boolean
  showEndMeetingModal: boolean
  showEndRecordingModal: boolean
  showEndHlsModal: boolean
  showEndRtmpModal: boolean
  broadcastPeerId: string
  pinnedPeerId: string
  pinnedMessages: HMSMessage[]
  errorMessage: string
  widgetbotToken: string
  mirrorVideo: boolean
  peerGridMaxTiles: number
  unreadCount: number
}

const INITIAL_STATE: AppState = {
  displayLayout: 'show all',
  botDisplayLayout: 'show all',
  showChatDrawer: false,
  showParticipantDrawer: false,
  showClearStageModal: false,
  showPromoteGroupModal: false,
  showSettingsModal: false,
  showStartHlsModal: false,
  showStartRtmpModal: false,
  showEndMeetingModal: false,
  showEndRecordingModal: false,
  showEndHlsModal: false,
  showEndRtmpModal: false,
  broadcastPeerId: '',
  pinnedPeerId: '',
  pinnedMessages: [],
  errorMessage: '',
  widgetbotToken: '',
  mirrorVideo: true,
  peerGridMaxTiles: PEER_GRID_MAX_TILES,
  unreadCount: 0,
}

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    setDisplayLayout(state, action: PayloadAction<DisplayLayout>) {
      state.displayLayout = action.payload
    },
    setBotDisplayLayout(state, action: PayloadAction<DisplayLayout>) {
      state.botDisplayLayout = action.payload
    },
    setShowChatDrawer(state, action: PayloadAction<boolean>) {
      state.showChatDrawer = action.payload
    },
    setShowParticipantDrawer(state, action: PayloadAction<boolean>) {
      state.showParticipantDrawer = action.payload
    },
    setShowClearStageModal(state, action: PayloadAction<boolean>) {
      state.showClearStageModal = action.payload
    },
    setShowPromoteGroupModal(state, action: PayloadAction<boolean>) {
      state.showPromoteGroupModal = action.payload
    },
    setShowSettingsModal(state, action: PayloadAction<boolean>) {
      state.showSettingsModal = action.payload
    },
    setShowStartHlsModal(state, action: PayloadAction<boolean>) {
      state.showStartHlsModal = action.payload
    },
    setShowStartRtmpModal(state, action: PayloadAction<boolean>) {
      state.showStartRtmpModal = action.payload
    },
    setShowEndMeetingModal(state, action: PayloadAction<boolean>) {
      state.showEndMeetingModal = action.payload
    },
    setShowEndRecordingModal(state, action: PayloadAction<boolean>) {
      state.showEndRecordingModal = action.payload
    },
    setShowEndHlsModal(state, action: PayloadAction<boolean>) {
      state.showEndHlsModal = action.payload
    },
    setShowEndRtmpModal(state, action: PayloadAction<boolean>) {
      state.showEndRtmpModal = action.payload
    },
    setBroadcastPeerId(state, action: PayloadAction<string>) {
      state.broadcastPeerId = action.payload
    },
    setPinnedPeer(state, action: PayloadAction<string>) {
      state.pinnedPeerId = action.payload
    },
    addPinnedMessage(state, action: PayloadAction<HMSMessage>) {
      if (state.pinnedMessages.length === CHAT_PINNED_MESSAGES_MAX) {
        state.pinnedMessages = state.pinnedMessages.slice(
          1,
          CHAT_PINNED_MESSAGES_MAX
        )
      }

      state.pinnedMessages = [action.payload, ...state.pinnedMessages]
    },
    clearPinnedMessages(state) {
      state.pinnedMessages = []
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload
    },
    setWidgetbotToken(state, action: PayloadAction<string>) {
      state.widgetbotToken = action.payload
    },
    setMirrorVideo(state, action: PayloadAction<boolean>) {
      state.mirrorVideo = action.payload
    },
    setPeerGridMaxTiles(state, action: PayloadAction<number>) {
      state.peerGridMaxTiles = action.payload
    },
    setUnreadCount(state, action: PayloadAction<number>) {
      state.unreadCount = action.payload
    },
    messageReceived(state, _: PayloadAction<void>) {
      if (!state.showChatDrawer) {
        ++state.unreadCount
      }
    },
  },
})

export const {
  setDisplayLayout,
  setBotDisplayLayout,
  setShowChatDrawer,
  setShowParticipantDrawer,
  setShowClearStageModal,
  setShowPromoteGroupModal,
  setShowSettingsModal,
  setShowStartHlsModal,
  setShowStartRtmpModal,
  setShowEndMeetingModal,
  setShowEndRecordingModal,
  setShowEndHlsModal,
  setShowEndRtmpModal,
  setBroadcastPeerId,
  setPinnedPeer,
  addPinnedMessage,
  clearPinnedMessages,
  setErrorMessage,
  setWidgetbotToken,
  setMirrorVideo,
  setPeerGridMaxTiles,
  setUnreadCount,
  messageReceived,
} = appSlice.actions

export const appReducer = appSlice.reducer