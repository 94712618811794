import { chakra, TabPanel } from '@chakra-ui/react'

export const DrawerPanel = chakra(TabPanel, {
  baseStyle: {
    display: 'flex',
    flex: 1,
    width: '100%',
    paddingTop: 0,
    paddingBottom: 4,
    paddingX: 4,
  },
})