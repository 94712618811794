import { Center, Spinner, Text } from '@chakra-ui/react'

export const LoadingIndicator = ({
  message = 'Loading...',
}: {
  message?: string
}) => {
  return (
    <Center flexDirection="column">
      <Spinner color="white" />
      <Text color="textSecondary" marginTop={8}>
        {message}
      </Text>
    </Center>
  )
}