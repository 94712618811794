import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { decode } from 'base-64'
import {
  Heading,
  Text,
  Image,
  Container,
  useToast,
  Box,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { PublicRoomItem } from './PublicRoomItem'
import {
  ControlButton,
  ControlButtonText,
  Footer,
  Page,
  PwaPrompt,
  Toast,
} from '../../components'
import {
  useAppDispatch,
  resetAppState,
  useAppSelector,
  setVeefriendsToken,
  setAvatarUrl,
  setDisplayName,
} from '../../store'
import {
  RoomDataModel,
  RoomRoleLinkModel,
  meetApi,
} from '../../services/meetApi'
import { VeeFriendsConfig } from '../../models'
import { getPwaDisplayMode } from '../../utils'
import {
  QUERY_PARAM_LOGGED_OUT,
  QUERY_PARAM_VF_TOKEN,
  TOAST_DURATION,
} from '../../constants'
import logo from '../../assets/images/logo-color.svg'

const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL

const APP_URL = process.env.REACT_APP_URL

export const HomePage = () => {
  const [searchParams] = useSearchParams()

  const qpLoggedOut = searchParams.get(QUERY_PARAM_LOGGED_OUT)

  const qpVfToken = searchParams.get(QUERY_PARAM_VF_TOKEN)

  const data = Cookies.get('veefriends_meet')

  const dispatch = useAppDispatch()

  const { veefriendsToken } = useAppSelector((state) => state.config)

  const [showAddToHome, setShowAddToHome] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [rooms, setRooms] = useState<{ [key: string]: RoomDataModel }>({})

  const toast = useToast()

  dispatch(resetAppState())

  // Detect if launched from PWA
  useEffect(() => {
    const displayMode = getPwaDisplayMode()
    setShowAddToHome(displayMode === 'browser')
  }, [])

  useEffect(() => {
    if (qpLoggedOut === 'true') {
      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose: onToastClose }) => (
          <Toast
            onClose={onToastClose}
            type="leave"
            title="You have been logged out"
          />
        ),
      })
    }
  }, [qpLoggedOut, toast])

  const getRooms = useCallback(async () => {
    try {
      setIsLoading(true)

      const response = await dispatch(
        meetApi.endpoints.getActiveRoomsEndpoint.initiate(
          { isLive: true },
          { forceRefetch: true }
        )
      )

      // If 401 unauthorized and token expired, redirect to vf auth page
      if (
        response.isError &&
        'status' in response.error &&
        response.error.status === 401
      ) {
        const { exp }: JwtPayload = jwtDecode(veefriendsToken)
        const tokenExpired = !!exp && Date.now() >= exp * 1000

        if (tokenExpired) {
          window.location.replace(
            `${LOGIN_REDIRECT_URL}?returnUrl=${APP_URL}admin`
          )
          return
        }

        throw response.error
      }

      // Throw other non 401 errors
      if (response.isError || !response.data || !response.data.data) {
        throw new Error('Error fetching room data.')
      }

      // Success response
      setRooms(response.data.data)
    } catch (error) {
      console.error(error)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="error"
            title="Error fetching rooms"
          />
        ),
      })
    } finally {
      setIsLoading(false)
    }
  }, [veefriendsToken, dispatch, toast])

  useEffect(() => {
    if (data) {
      const { bearerToken, avatarUrl, username }: VeeFriendsConfig =
        JSON.parse(decode(data))

      dispatch(setVeefriendsToken(bearerToken))
      dispatch(setAvatarUrl(avatarUrl))
      dispatch(setDisplayName(username))
    } else if (qpVfToken && process.env.NODE_ENV === 'development') {
      const { bearerToken, avatarUrl, username }: VeeFriendsConfig =
        JSON.parse(decode(qpVfToken))

      dispatch(setVeefriendsToken(bearerToken))
      dispatch(setAvatarUrl(avatarUrl))
      dispatch(setDisplayName(username))
    } else {
      window.location.replace(
        `${LOGIN_REDIRECT_URL}?returnUrl=${APP_URL}`
      )
    }
  }, [data, qpVfToken, dispatch])

  useEffect(() => {
    if (veefriendsToken !== '') {
      getRooms()
    }
  }, [veefriendsToken, getRooms])

  return (
    <Page
      accentColor="backgroundPrimary"
      containerStyle={{ overflowY: 'scroll' }}
    >
      <Container
        maxWidth="container.lg"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100% - 7rem)"
        userSelect="none"
      >
        <Image src={logo} width="25%" height="auto" marginY={12} />

        <Box
          position="relative"
          width="100%"
          backgroundColor="backgroundOverlay"
          backdropFilter="blur(8px)"
          borderRadius={20}
          padding={8}
          marginBottom={12}
        >
          <ControlButton
            label="Refresh"
            aria-label="Refresh"
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
            }}
            onClick={() => getRooms()}
          >
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize="sm"
            >
              refresh
            </ControlButtonText>
          </ControlButton>

          {isLoading || Object.keys(rooms).length === 0 ? (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={4}
              minHeight="151px"
              padding={8}
            >
              {isLoading ? (
                <Spinner size="lg" />
              ) : (
                <>
                  <Heading
                    as="h2"
                    size="xl"
                    fontWeight={800}
                    textTransform="uppercase"
                    userSelect="none"
                  >
                    No Active Livestreams
                  </Heading>

                  <Text fontSize="lg" color="textSecondary">
                    There are currently no active
                    livestreams available. Please check back
                    again at a later time!
                  </Text>
                </>
              )}
            </Flex>
          ) : (
            <Flex flexDirection="column" gap={4}>
              <Heading
                as="h2"
                size="xl"
                fontWeight={800}
                textTransform="uppercase"
                userSelect="none"
              >
                Live Meetings
              </Heading>

              {Object.keys(rooms).map((key) => {
                const { roomRoles, roomConfig } = rooms[key]

                let role: RoomRoleLinkModel | undefined =
                  undefined

                roomRoles?.forEach((roomRole) => {
                  if (
                    roomRole.role?.toLowerCase() ===
                    'viewer'
                  ) {
                    role = roomRole
                  }
                })

                if (role !== undefined && roomConfig?.title) {
                  return (
                    <PublicRoomItem
                      key={key}
                      // @ts-ignore
                      id={role.id}
                      title={roomConfig.title}
                      subtitle={roomConfig?.subTitle}
                    />
                  )
                }

                return null
              })}
            </Flex>
          )}
        </Box>

        {showAddToHome ? <PwaPrompt /> : null}
      </Container>

      <Footer />
    </Page>
  )
}