import {
  chakra,
  Box,
  Flex,
  Text,
  useCheckbox,
  UseCheckboxProps,
} from '@chakra-ui/react'

interface CheckboxProps extends UseCheckboxProps {
  label: string
}

export const Checkbox = ({ label, ...props }: CheckboxProps) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props)

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      maxWidth="200px"
      cursor="pointer"
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
        border={state.isChecked ? '2px solid' : '1px solid'}
        borderColor={
          state.isChecked ? 'textSecondary' : 'whiteAlpha.200'
        }
        borderRadius={4}
        height={5}
        width={5}
        {...getCheckboxProps()}
      >
        {state.isChecked && (
          <Box
            height={3}
            width={3}
            backgroundColor="buttonConfirm"
            borderRadius={1}
          />
        )}
      </Flex>
      <Text
        {...getLabelProps()}
        color={state.isChecked ? 'textPrimary' : 'whiteAlpha.500'}
        noOfLines={1}
        maxW="200px"
      >
        {label}
      </Text>
    </chakra.label>
  )
}