import { memo } from 'react'
import { HMSPeer } from '@100mslive/react-sdk'
import { Box, Center, Text } from '@chakra-ui/react'
import { VideoTile } from '../tile'

interface EmptyRoomViewProps {
  localPeer?: HMSPeer
}

const _EmptyRoomView = ({ localPeer }: EmptyRoomViewProps) => {
  return (
    <Center flexDirection="column" height="100%">
      <Box height="50%" width="50%">
        <VideoTile
          peerId={localPeer?.id}
          trackId={localPeer?.videoTrack}
        />
      </Box>
      <Text size="lg" color="white" marginTop={8}>
        It looks like you're the first one here.
      </Text>
    </Center>
  )
}

export const EmptyRoomView = memo(_EmptyRoomView)