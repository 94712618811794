import { useState, useCallback, CSSProperties } from 'react'
import { ChakraProps, Box } from '@chakra-ui/react'
import { Video } from '@100mslive/roomkit-react'

interface VideoFeedProps {
  mirrorVideo: boolean
  trackId?: string
  degraded?: boolean
  containerStyle?: ChakraProps
  videoStyle?: CSSProperties
}

export const VideoFeed = ({
  mirrorVideo,
  trackId,
  degraded = false,
  containerStyle,
  videoStyle,
}: VideoFeedProps) => {
  const [height, setHeight] = useState(0)

  const ref = useCallback((node: HTMLDivElement) => {
    if (node) {
      setHeight(node.offsetWidth * (3 / 4))
    }
  }, [])

  return (
    <Box ref={ref} {...containerStyle}>
      <Video
        mirror={mirrorVideo}
        trackId={trackId}
        degraded={degraded}
        style={{
          borderRadius: 20,
          overflow: 'hidden',
          ...videoStyle,
          height,
        }}
      />
    </Box>
  )
}