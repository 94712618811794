import React, { memo, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Button,
  Flex,
  FormLabel,
  Switch,
  FormControl,
  FormErrorMessage,
  Input,
  useClipboard,
  Text,
  ModalFooter,
} from '@chakra-ui/react'
import { useGetBotRoomIdEndpointQuery } from '../../services/meetApi'
import { useAppSelector } from '../../store'
import { ControlButtonText } from '../button'

const APP_URL = process.env.REACT_APP_URL

const validationSchema = yup.object().shape({
  rtmpUrl: yup.string().required('RTMP URL required'),
})

export interface StartRtmpProps {
  rtmpUrl: string
  chatEmbedId: string
  shouldRecord: boolean
  removeBranding: boolean
}

const initialValues: StartRtmpProps = {
  rtmpUrl: '',
  chatEmbedId: '',
  shouldRecord: false,
  removeBranding: false,
}

interface StartRtmpModalProps {
  isOpen: boolean
  onStartClick: (props: StartRtmpProps) => void
  onCloseClick: () => void
}

const _StartRtmpModal = ({
  isOpen,
  onStartClick,
  onCloseClick,
}: StartRtmpModalProps) => {
  const { roomId, veefriendsToken } = useAppSelector((state) => state.config)

  const [removeBranding, setRemoveBranding] = useState(false)

  const [botLink, setBotLink] = useState('Stream link')

  const { isLoading, isError, data } = useGetBotRoomIdEndpointQuery({
    roomId,
  })

  const { onCopy, hasCopied } = useClipboard(botLink)

  useEffect(() => {
    if (!isLoading && data?.id && veefriendsToken) {
      setBotLink(
        `${APP_URL}${data.id}?botToken=${veefriendsToken}&botType=${removeBranding ? 'rtmpUnbranded' : 'rtmp'
        }`
      )
    }
  }, [isLoading, data, veefriendsToken, removeBranding])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onStartClick,
  })

  return (
    <Modal isOpen={isOpen} isCentered size="2xl" onClose={onCloseClick}>
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>RTMP Streaming</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody paddingBottom={4}>
          <Flex justifyContent="flex-start" marginBottom={2}>
            <FormLabel
              htmlFor="removeBranding"
              color="textSecondary"
              fontSize="sm"
              width="135px"
            >
              Remove branding
            </FormLabel>
            <Switch
              id="removeBranding"
              name="removeBranding"
              size="md"
              sx={{
                'span.chakra-switch__track[data-checked]': {
                  backgroundColor: 'buttonConfirm',
                },
              }}
              isChecked={formik.values.removeBranding}
              onChange={(event) => {
                formik.handleChange(event)
                setRemoveBranding(event.currentTarget.checked)
              }}
            />
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="flex-end"
            gap={4}
            marginBottom={6}
          >
            <Flex flex={1} flexDirection="column">
              <FormLabel
                userSelect="none"
                color="white"
                marginBottom={1}
              >
                Stream Link
              </FormLabel>
              <Text
                color="textSecondary"
                userSelect="none"
                maxWidth="350px"
                noOfLines={1}
              >
                {botLink}
              </Text>
            </Flex>

            <Button
              color="white"
              width={130}
              isLoading={isLoading}
              isDisabled={isError}
              rightIcon={
                <ControlButtonText
                  className="material-symbols-outlined"
                  fontSize="sm"
                  color={
                    hasCopied ? 'buttonConfirm' : 'white'
                  }
                >
                  {hasCopied ? 'done' : 'file_copy'}
                </ControlButtonText>
              }
              onClick={onCopy}
            >
              Copy Link
            </Button>
          </Flex>

          <FormControl
            isRequired
            isInvalid={
              formik.touched.rtmpUrl && !!formik.errors.rtmpUrl
            }
            marginBottom={2}
          >
            <FormLabel userSelect="none" color="white">
              RTMP URL
            </FormLabel>
            <Input
              id="rtmpUrl"
              name="rtmpUrl"
              placeholder="URL"
              value={formik.values.rtmpUrl}
              onChange={formik.handleChange}
            />
            <FormErrorMessage>
              {formik.errors.rtmpUrl}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel userSelect="none" color="white">
              Chat Relay
            </FormLabel>
            <Input
              id="chatEmbedId"
              name="chatEmbedId"
              placeholder="Chat embed ID"
              value={formik.values.chatEmbedId}
              onChange={formik.handleChange}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter gap={4}>
          <Button
            backgroundColor="buttonDeny"
            color="white"
            onClick={onCloseClick}
          >
            Cancel
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={formik.submitForm}
          >
            Start Multistream
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const StartRtmpModal = memo(_StartRtmpModal)