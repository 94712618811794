import { memo, useEffect, useRef, useState } from 'react'
import {
  HMSNotificationTypes,
  selectIsLocalScreenShared,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeerMetadata,
  selectPeersByRole,
  selectRecordingState,
  useAVToggle,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from '@100mslive/react-sdk'
import { useToast } from '@chakra-ui/react'
import { Toast } from '../components'
import {
  useAppDispatch,
  useAppSelector,
  addPinnedMessage,
  setBroadcastPeerId,
  DisplayLayout,
  setBotDisplayLayout,
} from '../store'
import { formatRole } from '../utils'
import { CustomMetadata, Role, NOTIFICATION_TYPES } from '../models'
import {
  TOAST_DURATION,
  HANDS_RAISED_COUNTER_DURATION,
  MOD_FUNC_LOWER_HAND,
  MOD_FUNC_LOWER_ALL_HANDS,
  MOD_FUNC_END_SCREENSHARE,
  MOD_FUNC_BOT_TOGGLE_BROADCAST,
  MOD_FUNC_BOT_BOUNDARY,
  MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT,
  STAGE_AUDIENCE_BREAKPOINT,
  MOD_FUNC_PIN_CHAT_MESSAGE,
  MOD_FUNC_BOT_ACK,
} from '../constants'

const _NotificationCenter = () => {
  const dispatch = useAppDispatch()

  const { displayMode } = useAppSelector((state) => state.config)
  const { showChatDrawer } = useAppSelector((state) => state.app)

  const notification = useHMSNotifications(NOTIFICATION_TYPES)
  const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)
  const isLocalScreenShared = useHMSStore(selectIsLocalScreenShared)
  const localMetadata: CustomMetadata = useHMSStore(
    selectPeerMetadata(localPeerId)
  )
  const viewers = useHMSStore(selectPeersByRole(Role.Viewer))
  const hlsViewers = useHMSStore(selectPeersByRole(Role.HLSViewer))
  const recordingState = useHMSStore(selectRecordingState)
  const hmsActions = useHMSActions()

  const {
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    toggleAudio,
    toggleVideo,
  } = useAVToggle()

  const [handsRaised, setHandsRaised] = useState(new Set<string>())
  const [recordingInProgress, setRecordingInProgress] = useState(false)

  const toast = useToast()

  // Show hand raise toasts for all in standard mode
  // Only show hand raise toasts in stage mode for....
  //    - Moderators
  //    - If audience is less than 50
  const shouldDisplayHandToasts =
    (displayMode === 'stage' &&
      (localPeerRoleName === Role.Moderator ||
        localPeerRoleName === Role.StageModerator) &&
      viewers.length + hlsViewers.length <= STAGE_AUDIENCE_BREAKPOINT) ||
    displayMode === 'standard'

  const shouldDisplayAttendanceToasts = displayMode === 'standard'

  let timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (localPeerRoleName === Role.Bot) return

    if (recordingState.browser.running && !recordingInProgress) {
      setRecordingInProgress(true)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            onClose={onClose}
            type="record"
            title="Meeting is now being recorded"
          />
        ),
      })

      return
    }

    if (!recordingState.browser.running && recordingInProgress) {
      setRecordingInProgress(false)
    }
  }, [localPeerRoleName, recordingState, recordingInProgress, toast])

  useEffect(() => {
    if (handsRaised.size === 0) {
      return
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setHandsRaised(new Set<string>())
    }, HANDS_RAISED_COUNTER_DURATION)
  }, [handsRaised])

  useEffect(() => {
    if (!notification) {
      return
    }

    switch (notification.type) {
      case HMSNotificationTypes.PEER_JOINED: {
        if (
          shouldDisplayAttendanceToasts ||
          notification.data.roleName === Role.StageModerator ||
          notification.data.roleName === Role.Moderator
        ) {
          toast({
            position: 'bottom-left',
            duration: TOAST_DURATION,
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                onClose={onClose}
                type="join"
                title={`${notification.data.name} has joined the meeting`}
              />
            ),
          })
        }
        break
      }

      case HMSNotificationTypes.PEER_LEFT: {
        if (
          shouldDisplayAttendanceToasts ||
          notification.data.roleName === Role.StageModerator ||
          notification.data.roleName === Role.Moderator
        ) {
          toast({
            position: 'bottom-left',
            duration: TOAST_DURATION,
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                onClose={onClose}
                type="leave"
                title={`${notification.data.name} has left the meeting`}
              />
            ),
          })
        }
        break
      }

      case HMSNotificationTypes.METADATA_UPDATED: {
        if (!notification.data.metadata) break

        const metadata: CustomMetadata = JSON.parse(
          notification.data.metadata
        )

        if (
          shouldDisplayHandToasts &&
          metadata.isHandRaised &&
          !(localPeerId === notification.data.id)
        ) {
          if (!handsRaised.has(notification.data.id)) {
            const newHandsRaised = new Set(handsRaised)
            newHandsRaised.add(notification.data.id)
            setHandsRaised(newHandsRaised)

            let title: string = notification.data.name
            switch (handsRaised.size) {
              case 0:
                title += ' raised hand'
                break
              case 1:
                title += ' and 1 other raised their hands'
                break
              default:
                title += ` and ${handsRaised.size} others raised their hands`
            }

            toast({
              position: 'bottom-left',
              duration: TOAST_DURATION,
              isClosable: true,
              render: ({ onClose }) => (
                <Toast
                  onClose={onClose}
                  type="hand"
                  title={title}
                />
              ),
            })
          }
        }
        break
      }

      case HMSNotificationTypes.ROLE_UPDATED: {
        if (localPeerId === notification.data.id) {
          toast({
            position: 'bottom-left',
            duration: TOAST_DURATION,
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                onClose={onClose}
                type="info"
                title={`Role updated to ${formatRole(
                  notification.data.roleName
                )}`}
              />
            ),
          })

          if (
            notification.data.roleName === Role.Viewer ||
            notification.data.roleName === Role.HLSViewer ||
            notification.data.roleName === Role.ChatTimeOut
          ) {
            if (isLocalScreenShared) {
              hmsActions.setScreenShareEnabled(false)
            }

            if (isLocalAudioEnabled && toggleAudio) {
              toggleAudio()
            }

            if (isLocalVideoEnabled && toggleVideo) {
              toggleVideo()
            }
          }
        }
        break
      }

      case HMSNotificationTypes.NEW_MESSAGE: {
        if (showChatDrawer) {
          hmsActions.setMessageRead(true)
        }

        // ignore messages not from moderators or bots
        if (
          notification.data.senderRole !== Role.Moderator &&
          notification.data.senderRole !== Role.StageModerator &&
          notification.data.senderRole !== Role.Host &&
          notification.data.senderRole !== Role.Bot
        ) {
          break
        }

        // ack message came from bot to moderator
        if (
          (localPeerRoleName === Role.Moderator ||
            localPeerRoleName === Role.StageModerator ||
            localPeerRoleName === Role.Host) &&
          notification.data.senderRole === Role.Bot &&
          notification.data.message.startsWith(MOD_FUNC_BOT_ACK)
        ) {
          const parts = (notification.data.message as string).split(
            MOD_FUNC_BOT_BOUNDARY
          )
          const botFunc = parts[1]

          if (
            botFunc === MOD_FUNC_BOT_TOGGLE_BROADCAST &&
            parts.length === 3
          ) {
            dispatch(setBroadcastPeerId(parts[2]))

            break
          }

          if (botFunc === MOD_FUNC_BOT_TOGGLE_BROADCAST) {
            dispatch(setBroadcastPeerId(''))

            break
          }

          if (botFunc === MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT) {
            dispatch(setBotDisplayLayout(parts[2] as DisplayLayout))

            break
          }

          break
        }

        // message is toggle bot broadcast function
        if (
          notification.data.message.startsWith(
            MOD_FUNC_BOT_TOGGLE_BROADCAST
          )
        ) {
          const parts = (notification.data.message as string).split(
            MOD_FUNC_BOT_BOUNDARY
          )

          if (parts.length > 1) {
            dispatch(setBroadcastPeerId(parts[1]))
            hmsActions.sendBroadcastMessage(
              `${MOD_FUNC_BOT_ACK}${MOD_FUNC_BOT_BOUNDARY}${MOD_FUNC_BOT_TOGGLE_BROADCAST}${MOD_FUNC_BOT_BOUNDARY}${parts[1]}`
            )
          } else {
            dispatch(setBroadcastPeerId(''))
            hmsActions.sendBroadcastMessage(
              `${MOD_FUNC_BOT_ACK}${MOD_FUNC_BOT_BOUNDARY}${MOD_FUNC_BOT_TOGGLE_BROADCAST}`
            )
          }

          break
        }

        // message is toggle bot display layout function
        if (
          notification.data.message.startsWith(
            MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT
          )
        ) {
          const parts = (notification.data.message as string).split(
            MOD_FUNC_BOT_BOUNDARY
          )

          if (parts.length <= 1) break

          dispatch(setBotDisplayLayout(parts[1] as DisplayLayout))
          hmsActions.sendBroadcastMessage(
            `${MOD_FUNC_BOT_ACK}${MOD_FUNC_BOT_BOUNDARY}${MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT}${MOD_FUNC_BOT_BOUNDARY}${parts[1]}`
          )

          break
        }

        // message is lower all hands function
        if (notification.data.message === MOD_FUNC_LOWER_ALL_HANDS) {
          hmsActions.setMessageRead(true, notification.data.id)
          hmsActions.changeMetadata({
            ...localMetadata,
            isHandRaised: false,
          })

          break
        }

        // direct message is lower hand function
        if (
          notification.data.recipientPeer === localPeerId &&
          notification.data.message === MOD_FUNC_LOWER_HAND
        ) {
          hmsActions.setMessageRead(true, notification.data.id)
          hmsActions.changeMetadata({
            ...localMetadata,
            isHandRaised: false,
          })

          break
        }

        // direct messsage is end screenshare function
        if (
          notification.data.recipientPeer === localPeerId &&
          notification.data.message === MOD_FUNC_END_SCREENSHARE &&
          isLocalScreenShared
        ) {
          hmsActions.setMessageRead(true, notification.data.id)
          hmsActions.setScreenShareEnabled(false)

          break
        }

        // message is should be pinned function
        if (
          notification.data.message.startsWith(
            MOD_FUNC_PIN_CHAT_MESSAGE
          )
        ) {
          dispatch(addPinnedMessage(notification.data))

          break
        }

        break
      }

      case HMSNotificationTypes.RECONNECTING: {
        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              type="info"
              title="Connection interrupted. Reconnecting..."
            />
          ),
        })

        break
      }

      case HMSNotificationTypes.RECONNECTED: {
        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              type="success"
              title="Reconnected"
            />
          ),
        })

        break
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notification,
    localPeerId,
    localMetadata,
    shouldDisplayHandToasts,
    shouldDisplayAttendanceToasts,
    dispatch,
    toast,
  ])

  return null
}

export const NotificationCenter = memo(_NotificationCenter)