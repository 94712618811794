import { memo } from 'react'
import {
  Box,
  Flex,
  Text,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  Portal,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ControlButton, ControlButtonText } from '../button'
import { HLSQualityLevel } from '../../models'

const validationSchema = yup.object().shape({
  qualityIndex: yup.string().required(),
})

interface HLSControlProps {
  levels: HLSQualityLevel[]
  currentLevelText: string
  isVideoLive: boolean
  isVisible: boolean
  onOpen: () => void
  onClose: () => void
  onSelect: (value: string) => void
  onGoLiveClick: () => void
}

const _HLSControl = ({
  levels,
  currentLevelText,
  isVideoLive,
  isVisible,
  onOpen,
  onClose,
  onSelect,
  onGoLiveClick,
}: HLSControlProps) => {
  const qualityDisclosure = useDisclosure()

  const onChangeQualitySubmit = async (value: string) => {
    onSelect(value)
    qualityDisclosure.onClose()
  }

  if (isVisible) {
    return (
      <Box position="absolute" top={4} left={4}>
        <Popover
          placement="bottom"
          isOpen={qualityDisclosure.isOpen}
          onOpen={onOpen}
          onClose={() => {
            qualityDisclosure.onClose()
            onClose()
          }}
        >
          <PopoverAnchor>
            <HStack
              spacing={2}
              flexWrap="wrap"
              justifyContent="center"
            >
              <PopoverTrigger>
                <ControlButton
                  label="Stream quality"
                  aria-label="Stream quality"
                  onClick={qualityDisclosure.onToggle}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    settings
                  </ControlButtonText>
                </ControlButton>
              </PopoverTrigger>

              <Text color="white" fontSize="xs" fontWeight={600}>
                Quality: {currentLevelText}
              </Text>

              {!isVideoLive ? (
                <ControlButton
                  label="Go live"
                  aria-label="Go live"
                  onClick={onGoLiveClick}
                >
                  <ControlButtonText
                    className="material-symbols-outlined"
                    color="buttonDeny"
                  >
                    radio_button_checked
                  </ControlButtonText>
                </ControlButton>
              ) : null}
            </HStack>
          </PopoverAnchor>

          <Portal>
            <PopoverContent
              paddingY={1}
              backgroundColor="backgroundSecondary"
              border="none"
              borderRadius="full"
            >
              <PopoverBody>
                <Formik
                  initialValues={{ qualityIndex: '' }}
                  validateOnMount
                  validationSchema={validationSchema}
                  onSubmit={(values) =>
                    onChangeQualitySubmit(
                      values.qualityIndex
                    )
                  }
                >
                  {({ isValid, setValues }) => (
                    <Form>
                      <Flex flexDirection="row">
                        <Select
                          borderRadius="full"
                          onChange={({
                            currentTarget,
                          }) => {
                            setValues({
                              qualityIndex:
                                currentTarget.value,
                            })
                          }}
                        >
                          <option value="">
                            Select quality level
                          </option>

                          <option value="auto">
                            Auto
                          </option>

                          {levels.map(
                            (level, index) => (
                              <option
                                key={
                                  level.height
                                }
                                value={index}
                              >
                                {`${level.height}p`}
                              </option>
                            )
                          )}
                        </Select>

                        <ControlButton
                          type="submit"
                          aria-label="Submit"
                          height="40px"
                          width="40px"
                          backgroundColor="buttonConfirm"
                          disabled={!isValid}
                          marginLeft={2}
                        >
                          <ControlButtonText className="material-symbols-outlined">
                            check
                          </ControlButtonText>
                        </ControlButton>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    )
  }

  return null
}

export const HLSControl = memo(_HLSControl)