import { chakra, Tab } from '@chakra-ui/react'

export const DrawerTab = chakra(Tab, {
  baseStyle: {
    color: 'textSecondary',
    fontSize: [12, null, 16],
    width: ['8rem', null, '10rem'],
    _selected: {
      color: 'textPrimary',
      backgroundColor: 'backgroundPrimaryLight',
    },
  },
})