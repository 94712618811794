import { memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Center, Text } from '@chakra-ui/react'
import {
  selectIsConnectedToRoom,
  selectIsSomeoneScreenSharing,
  selectPeersByRole,
  selectPeerScreenSharing,
  useHMSStore,
  usePreviewJoin,
} from '@100mslive/react-sdk'
import { useAppSelector, useAppDispatch, setErrorMessage } from '../../store'
import { Role } from '../../models'
import {
  FullPageLoader,
  Page,
  PeerGrid,
  ScreenShareView,
  ShowActiveView,
  VideoTile,
} from '../../components'
import { NotificationCenter } from '../../notification'
import { formatBotName } from '../../utils'

const _HLSBotPage = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { botType, token, displayMode, roomConfig } = useAppSelector(
    (state) => state.config
  )

  const { broadcastPeerId, botDisplayLayout } = useAppSelector(
    (state) => state.app
  )

  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom)

  // Stage roles
  const stageModerators = useHMSStore(selectPeersByRole(Role.StageModerator))
  const stageMains = useHMSStore(selectPeersByRole(Role.StageMain))
  const stageViewers = useHMSStore(selectPeersByRole(Role.StageViewer))

  // Standard roles
  const guests = useHMSStore(selectPeersByRole(Role.Guest))
  const guestHosts = useHMSStore(selectPeersByRole(Role.GuestHost))
  const hosts = useHMSStore(selectPeersByRole(Role.Host))

  const presenter = useHMSStore(selectPeerScreenSharing)
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing)

  const peers =
    displayMode === 'stage'
      ? [...stageModerators, ...stageMains, ...stageViewers]
      : [...guests, ...guestHosts, ...hosts]

  const { join } = usePreviewJoin({
    name: formatBotName(botType),
    token,
    initialSettings: {
      isAudioMuted: true,
      isVideoMuted: true,
    },
    captureNetworkQualityInPreview: false,
    handleError: (error) => {
      console.error(error)
      dispatch(
        setErrorMessage(
          'Error joining the specified room. Please check the eligiblity link and try again.'
        )
      )
      navigate('/error')
    },
  })

  useEffect(() => {
    if (token) {
      join()
    }
  }, [token, join])

  const renderContent = () => {
    if (peers.length === 0) {
      return (
        <Center height="100%" width="100%">
          <Text fontSize="3xl" color="textSecondary">
            Meeting starting soon...
          </Text>
        </Center>
      )
    }

    if (isSomeoneScreenSharing && presenter) {
      return <ScreenShareView presenter={presenter} peers={peers} />
    }

    if (broadcastPeerId) {
      return <VideoTile peerId={broadcastPeerId} />
    }

    if (peers.length === 1) {
      return <VideoTile peerId={peers[0].id} />
    }

    if (botDisplayLayout === 'show active') {
      return <ShowActiveView peers={peers} />
    }

    if (botDisplayLayout === 'focused') {
      return <ShowActiveView peers={peers} hidePeerList />
    }

    return <PeerGrid peers={peers} />
  }

  if (!isConnectedToRoom) {
    return <FullPageLoader />
  }

  return (
    <>
      {botType === 'rtmpUnbranded' ? (
        <Box
          height="100%"
          width="100%"
          backgroundColor="black"
          padding={4}
        >
          {renderContent()}
        </Box>
      ) : (
        <Page
          accentColor={roomConfig?.primaryColor}
          bannerImageUrl={roomConfig?.bannerImage}
          containerStyle={{ padding: 4 }}
        >
          {renderContent()}
        </Page>
      )}

      <NotificationCenter />
    </>
  )
}

export const HLSBotPage = memo(_HLSBotPage)