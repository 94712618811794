import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

const MEET_API_URL = process.env.REACT_APP_MEET_API_URL

export const emptySplitApiMeet = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: MEET_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).config.veefriendsToken

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'meet',
})