import { useAVToggle } from '@100mslive/react-sdk'
import { HStack } from '@chakra-ui/react'
import {
  useAppDispatch,
  useAppSelector,
  setShowSettingsModal,
} from '../../store'
import { ControlButton, ControlButtonText } from '../button'

export const AVToggle = () => {
  const dispatch = useAppDispatch()

  const { showSettingsModal } = useAppSelector((state) => state.app)

  const {
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    toggleAudio,
    toggleVideo,
  } = useAVToggle()

  const toggleSettingsModal = () =>
    dispatch(setShowSettingsModal(!showSettingsModal))

  return (
    <HStack spacing={4} justifyContent="center" padding={4}>
      {toggleVideo ? (
        <ControlButton
          label={
            isLocalVideoEnabled
              ? 'Turn off webcam'
              : 'Turn on webcam'
          }
          aria-label="Toggle webcam"
          onClick={toggleVideo}
        >
          <ControlButtonText className="material-symbols-outlined">
            {isLocalVideoEnabled ? 'videocam' : 'videocam_off'}
          </ControlButtonText>
        </ControlButton>
      ) : null}

      {toggleAudio ? (
        <ControlButton
          label={
            isLocalAudioEnabled
              ? 'Turn off microphone'
              : 'Turn on microphone'
          }
          aria-label="Toggle microphone"
          onClick={toggleAudio}
        >
          <ControlButtonText className="material-symbols-outlined">
            {isLocalAudioEnabled ? 'mic' : 'mic_off'}
          </ControlButtonText>
        </ControlButton>
      ) : null}

      <ControlButton
        label="Show settings"
        aria-label="Toggle settings modal"
        onClick={toggleSettingsModal}
      >
        <ControlButtonText className="material-symbols-outlined">
          settings
        </ControlButtonText>
      </ControlButton>
    </HStack>
  )
}