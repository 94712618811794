import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useHMSStore,
  usePreviewJoin,
  selectLocalPeer,
  selectIsLocalVideoEnabled,
} from '@100mslive/react-sdk'
import { useBorderAudioLevel } from '@100mslive/roomkit-react'
import { Box, Center, Avatar } from '@chakra-ui/react'
import { ConnectionIndicator } from '../connection'
import { LoadingIndicator } from '../loading'
import { VideoFeed } from './VideoFeed'
import { AVToggle } from '../control'
import { useAppDispatch, setErrorMessage, useAppSelector } from '../../store'

interface PreviewTileProps {
  token: string
  name: string
  avatarUrl?: string
}

export const PreviewTile = ({ token, name, avatarUrl }: PreviewTileProps) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { mirrorVideo } = useAppSelector((state) => state.app)

  const localPeer = useHMSStore(selectLocalPeer)
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled)

  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack)

  const { preview } = usePreviewJoin({
    name,
    token,
    captureNetworkQualityInPreview: true,
    handleError: (error) => {
      console.error(error)
      dispatch(
        setErrorMessage(
          'Error joining the specified room. Please check the eligiblity link and try again.'
        )
      )
      navigate('/error')
    },
  })

  useEffect(() => {
    if (token && preview) {
      preview()
    }
  }, [token, preview])

  if (localPeer) {
    return (
      <Box
        ref={borderAudioRef}
        position="relative"
        borderRadius={20}
        backgroundColor="backgroundSecondary"
      >
        <VideoFeed
          mirrorVideo={mirrorVideo}
          trackId={localPeer.videoTrack}
          videoStyle={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />

        <ConnectionIndicator peerId={localPeer.id} />

        {!isVideoOn ? (
          <Center
            position="absolute"
            top={0}
            height="100%"
            width="100%"
          >
            <Avatar
              name={name}
              src={avatarUrl}
              size="xl"
              backgroundColor={
                avatarUrl ? 'black' : 'headingPrimary'
              }
              color="white"
            />
          </Center>
        ) : null}

        <AVToggle />
      </Box>
    )
  }

  return (
    <Center paddingY={16}>
      <LoadingIndicator message="Activating camera..." />
    </Center>
  )
}