import { useCallback, useState, memo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  selectHLSState,
  selectIsLocalScreenShared,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeerCount,
  selectPeerMetadata,
  selectPeersScreenSharing,
  selectRTMPState,
  selectRecordingState,
  useAVToggle,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import {
  chakra,
  Flex,
  HStack,
  Heading,
  Text,
  Image,
  Button,
  Tooltip,
  Circle,
  Box,
  useToast,
} from '@chakra-ui/react'
import { ControlButton, ControlButtonText } from '../button'
import { meetApi } from '../../services/meetApi'
import {
  useAppDispatch,
  useAppSelector,
  setDisplayLayout,
  setShowSettingsModal,
  setShowEndMeetingModal,
  setShowStartHlsModal,
  setShowEndRecordingModal,
  setShowEndHlsModal,
  setShowStartRtmpModal,
  setShowEndRtmpModal,
} from '../../store'
import { CustomMetadata, Role } from '../../models'
import {
  MOD_FUNC_END_SCREENSHARE,
  MOD_FUNC_LOWER_ALL_HANDS,
  RAISE_HAND_COOLDOWN,
  TOAST_DURATION,
} from '../../constants'
import logo from '../../assets/images/logo-right.svg'
import { Toast } from '../toast'
import {
  StartHlsModal,
  ConfirmationModal,
  StartRtmpModal,
  StartRtmpProps,
  EndRtmpModal,
} from '../modal'
import { formatUnreadCount } from '../../utils'

export const CONTROL_BAR_HEIGHT_SINGLE_ROW = '4.5rem'
export const CONTROL_BAR_HEIGHT_DOUBLE_ROW = '7rem'

const APP_URL = process.env.REACT_APP_URL

const DrawerToggle = chakra(Button, {
  baseStyle: {
    position: 'relative',
    backgroundColor: 'buttonPrimary',
    height: '40px',
    borderRadius: 'full',
    _hover: {
      backgroundColor: 'buttonPrimaryHover',
    },
    color: 'white',
  },
})

interface ControlBarProps {
  title: string
  subtitle?: string | null
  toggleChatDrawer: () => void
  toggleParticipantDrawer: () => void
}

const _ControlBar = ({
  title,
  subtitle,
  toggleChatDrawer,
  toggleParticipantDrawer,
}: ControlBarProps) => {
  const { meetingId } = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const toast = useToast()

  const {
    displayLayout,
    showChatDrawer,
    showParticipantDrawer,
    showSettingsModal,
    showStartHlsModal,
    showStartRtmpModal,
    showEndRecordingModal,
    showEndHlsModal,
    showEndRtmpModal,
    unreadCount,
  } = useAppSelector((state) => state.app)

  const { roomId, veefriendsToken, roomConfig } = useAppSelector(
    (state) => state.config
  )

  const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)
  const metadata: CustomMetadata = useHMSStore(
    selectPeerMetadata(localPeerId)
  )
  const isLocalScreenShared = useHMSStore(selectIsLocalScreenShared)
  const presenters = useHMSStore(selectPeersScreenSharing)
  const peerCount = useHMSStore(selectPeerCount)
  const recordingState = useHMSStore(selectRecordingState)
  const hlsState = useHMSStore(selectHLSState)
  const rtmpState = useHMSStore(selectRTMPState)
  const hmsActions = useHMSActions()

  const {
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    toggleAudio,
    toggleVideo,
  } = useAVToggle()

  const [canRaiseHand, setCanRaiseHand] = useState(true)
  const [isRecLoading, setIsRecLoading] = useState(false)
  const [isHlsLoading, setIsHlsLoading] = useState(false)
  const [isRtmpLoading, setIsRtmpLoading] = useState(false)
  const [recordingInProgress, setRecordingInProgress] = useState(false)
  const [hlsInProgress, setHlsInProgress] = useState(false)
  const [rtmpInProgress, setRtmpInProgress] = useState(false)
  const [relayInProgress, setRelayInProgress] = useState(false)

  const isAudienceMember =
    localPeerRoleName === Role.Viewer ||
    localPeerRoleName === Role.HLSViewer ||
    localPeerRoleName === Role.ChatTimeOut

  const isHost =
    localPeerRoleName === Role.Moderator ||
    localPeerRoleName === Role.StageModerator ||
    localPeerRoleName === Role.Host

  const canShareScreen =
    localPeerRoleName === Role.StageModerator ||
    localPeerRoleName === Role.StageMain ||
    localPeerRoleName === Role.Host ||
    localPeerRoleName === Role.GuestHost

  const toggleLayout = () => {
    if (displayLayout === 'show active') {
      dispatch(setDisplayLayout('show all'))
    } else {
      dispatch(setDisplayLayout('show active'))
    }
  }

  const toggleScreenShare = () => {
    try {
      if (isLocalScreenShared) {
        hmsActions.setScreenShareEnabled(false)

        return
      }

      if (presenters.length > 0) {
        presenters.forEach((presenter) =>
          hmsActions.sendDirectMessage(
            MOD_FUNC_END_SCREENSHARE,
            presenter.id
          )
        )
      }

      hmsActions.setScreenShareEnabled(true)
    } catch (error) {
      console.error(error)
    }
  }

  const toggleRaiseHand = useCallback(async () => {
    const newMetadata = {
      ...metadata,
      isHandRaised: !metadata.isHandRaised,
    }
    await hmsActions.changeMetadata(newMetadata)

    if (newMetadata.isHandRaised && !isHost) {
      setCanRaiseHand(false)
      setTimeout(() => {
        setCanRaiseHand(true)
      }, RAISE_HAND_COOLDOWN)
    }
  }, [hmsActions, metadata, isHost])

  const toggleSettingsModal = () => {
    dispatch(setShowSettingsModal(!showSettingsModal))
  }

  const handleLowerAllHandsClick = () => {
    try {
      hmsActions.sendBroadcastMessage(MOD_FUNC_LOWER_ALL_HANDS)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExitMeetingClick = () => {
    hmsActions.leave()
    navigate(`/${meetingId}/leave`)
  }

  const handleEndMeetingClick = () => dispatch(setShowEndMeetingModal(true))

  const handleEndRecording = () => {
    try {
      dispatch(setShowEndRecordingModal(false))
      setIsRecLoading(true)
      hmsActions.stopRTMPAndRecording()
    } catch (err) {
      console.error(err)
      setIsRecLoading(false)
    }
  }

  const toggleRecording = async () => {
    try {
      if (recordingState.browser.running || recordingState.hls.running) {
        dispatch(setShowEndRecordingModal(true))
        return
      }

      setIsRecLoading(true)

      const response = await dispatch(
        meetApi.endpoints.getBotRoomIdEndpoint.initiate(
          { roomId },
          { forceRefetch: true }
        )
      )

      if (response.isError || !response.data || !response.data.id) {
        throw new Error('Error starting recording.')
      }

      hmsActions.startRTMPOrRecording({
        meetingURL: `${APP_URL}${response.data.id}?botToken=${veefriendsToken}&botType=recording`,
        record: true,
      })
    } catch (error) {
      setIsRecLoading(false)
      console.error(error)
    }
  }

  const handleEndRecordingModalClose = () => {
    dispatch(setShowEndRecordingModal(false))
  }

  const handleStartHlsStream = async (shouldRecord: boolean) => {
    try {
      dispatch(setShowStartHlsModal(false))

      if (recordingState.browser.running || rtmpState.running) {
        recordingState.browser.running && setIsRecLoading(true)
        rtmpState.running && setIsRtmpLoading(true)
        hmsActions.stopRTMPAndRecording()
      }

      setIsHlsLoading(true)
      if (shouldRecord) {
        setIsRecLoading(true)
      }

      const response = await dispatch(
        meetApi.endpoints.getBotRoomIdEndpoint.initiate(
          { roomId },
          { forceRefetch: true }
        )
      )

      if (response.isError || !response.data || !response.data.id) {
        throw new Error('Error starting HLS stream.')
      }

      hmsActions.startHLSStreaming({
        variants: [
          {
            meetingURL: `${APP_URL}${response.data.id}?botToken=${veefriendsToken}&botType=hls`,
          },
        ],
        recording: shouldRecord
          ? { singleFilePerLayer: true }
          : undefined,
      })
    } catch (error) {
      setIsHlsLoading(false)
      setIsRecLoading(false)
      console.error(error)
    }
  }

  const handleEndHlsStream = () => {
    try {
      dispatch(setShowEndHlsModal(false))

      setIsHlsLoading(true)
      if (recordingInProgress) {
        setIsRecLoading(true)
      }

      hmsActions.stopHLSStreaming()
    } catch (err) {
      console.error(err)
      setIsHlsLoading(false)
    }
  }

  const toggleHlsStream = () => {
    if (hlsState.running) {
      dispatch(setShowEndHlsModal(true))
      return
    }

    setIsHlsLoading(true)
    dispatch(setShowStartHlsModal(true))
  }

  const handleStartHlsModalClose = () => {
    dispatch(setShowStartHlsModal(false))
    setIsHlsLoading(false)
  }

  const handleEndHlsModalClose = () => dispatch(setShowEndHlsModal(false))

  const handleStartChatRelay = async (chatEmbedId?: string) => {
    try {
      const result = await dispatch(
        meetApi.endpoints.startRelayEndpoint.initiate(
          {
            roomId,
            chatEmbedId,
          },
          { forceRefetch: true }
        )
      )

      if (result.isError) throw result.error
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setShowEndRtmpModal(false))
    }
  }

  const handleEndChatRelay = async () => {
    try {
      const result = await dispatch(
        meetApi.endpoints.endRelayEndpoint.initiate(
          { roomId },
          { forceRefetch: true }
        )
      )

      if (result.isError) throw result.error
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setShowEndRtmpModal(false))
    }
  }

  const handleStartRtmpStream = async ({
    rtmpUrl,
    chatEmbedId,
    shouldRecord,
    removeBranding,
  }: StartRtmpProps) => {
    try {
      dispatch(setShowStartRtmpModal(false))

      setIsRtmpLoading(true)

      if (shouldRecord) {
        setIsRecLoading(true)
      }

      const response = await dispatch(
        meetApi.endpoints.getBotRoomIdEndpoint.initiate(
          { roomId },
          { forceRefetch: true }
        )
      )

      if (response.isError || !response.data || !response.data.id) {
        throw new Error('Error starting RTMP stream.')
      }

      await hmsActions.startRTMPOrRecording({
        meetingURL: `${APP_URL}${response.data.id
          }?botToken=${veefriendsToken}&botType=${removeBranding ? 'rtmpUnbranded' : 'rtmp'
          }`,
        rtmpURLs: [rtmpUrl],
        record: shouldRecord,
      })

      const relayResponse = await dispatch(
        meetApi.endpoints.startRelayEndpoint.initiate({
          roomId,
          chatEmbedId,
        })
      )

      if (relayResponse.isError) throw relayResponse.error
    } catch (error) {
      console.error(error)

      setIsRtmpLoading(false)
      setIsRecLoading(false)

      toast({
        position: 'bottom-left',
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose: onToastClose }) => (
          <Toast
            onClose={onToastClose}
            type="error"
            title="Error starting RTMP stream"
          />
        ),
      })
    }
  }

  const handleEndMultistream = () => {
    try {
      dispatch(setShowEndRtmpModal(false))

      setIsRtmpLoading(true)
      if (recordingInProgress) {
        setIsRecLoading(true)
      }

      hmsActions.stopRTMPAndRecording()
    } catch (err) {
      console.error(err)
      setIsRtmpLoading(false)
    }
  }

  const toggleRtmpStream = async () => {
    if (rtmpState.running) {
      try {
        setIsRtmpLoading(true)

        const response = await dispatch(
          meetApi.endpoints.getRelayIsListeningEndpoint.initiate(
            { roomId },
            { forceRefetch: true }
          )
        )

        if (response.isError) throw response.error

        setRelayInProgress(!!response.data?.data?.isListening)

        dispatch(setShowEndRtmpModal(true))
      } catch (error) {
        console.error(error)
      } finally {
        setIsRtmpLoading(false)
      }

      return
    }

    setIsRtmpLoading(true)
    dispatch(setShowStartRtmpModal(true))
  }

  const handleStartRtmpModalClose = () => {
    dispatch(setShowStartRtmpModal(false))
    setIsRtmpLoading(false)
  }

  const handleEndRtmpModalClose = () => dispatch(setShowEndRtmpModal(false))

  useEffect(() => {
    if (hlsState.running && isHlsLoading && !hlsInProgress) {
      setIsHlsLoading(false)
      setHlsInProgress(true)
      return
    }

    if (!hlsState.running && isHlsLoading && hlsInProgress) {
      setIsHlsLoading(false)
      setHlsInProgress(false)
    }
  }, [hlsState, isHlsLoading, hlsInProgress])

  useEffect(() => {
    const isRecording =
      recordingState.browser.running || recordingState.hls.running

    if (isRecording && isRecLoading && !recordingInProgress) {
      setIsRecLoading(false)
      setRecordingInProgress(true)
      return
    }

    if (!isRecording && isRecLoading && recordingInProgress) {
      setIsRecLoading(false)
      setRecordingInProgress(false)
      return
    }
  }, [recordingState, isRecLoading, recordingInProgress])

  useEffect(() => {
    if (rtmpState.running && isRtmpLoading && !rtmpInProgress) {
      setIsRtmpLoading(false)
      setRtmpInProgress(true)
      return
    }

    if (!rtmpState.running && isRtmpLoading && rtmpInProgress) {
      setIsRtmpLoading(false)
      setRtmpInProgress(false)
    }
  }, [rtmpState, isRtmpLoading, rtmpInProgress])

  return (
    <>
      <Flex
        flexDirection={
          isAudienceMember ? 'row' : ['column', null, 'row']
        }
        alignItems="center"
        backgroundColor="backgroundSecondary"
        height={[
          isAudienceMember
            ? CONTROL_BAR_HEIGHT_SINGLE_ROW
            : CONTROL_BAR_HEIGHT_DOUBLE_ROW,
          null,
          CONTROL_BAR_HEIGHT_SINGLE_ROW,
        ]}
        paddingX={4}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          display={['none', null, null, 'flex']}
          userSelect="none"
        >
          <Image
            src={logo}
            height="2.5rem"
            width="auto"
            marginRight={4}
          />

          <Flex
            flexDirection="column"
            alignItems="flex-start"
            flexShrink={0}
          >
            {subtitle ? (
              <Heading
                as="h2"
                size={['xs', null, null, null, null, 'sm']}
                color="white"
                textTransform="uppercase"
                maxWidth="350px"
                noOfLines={1}
              >
                {subtitle}
              </Heading>
            ) : null}

            <Heading
              as="h1"
              size={['md', null, null, null, null, 'lg']}
              color="white"
              textTransform="uppercase"
              maxWidth={[
                '250px',
                null,
                null,
                null,
                null,
                '400px',
              ]}
              noOfLines={1}
            >
              {title}
            </Heading>
          </Flex>
        </Flex>

        <HStack
          spacing={[1, null, 3, null, null, 4]}
          flex={1}
          borderLeftWidth={[0, null, null, 1]}
          borderColor="rgba(255, 255, 255, 0.5)"
          paddingLeft={[0, null, null, 6]}
          marginLeft={[0, null, null, null, 6]}
        >
          {toggleVideo && !isAudienceMember ? (
            <ControlButton
              label={
                isLocalVideoEnabled
                  ? 'Turn off webcam'
                  : 'Turn on webcam'
              }
              aria-label="Toggle webcam"
              backgroundColor={
                isLocalVideoEnabled
                  ? 'buttonPrimary'
                  : 'buttonDeny'
              }
              onClick={toggleVideo}
            >
              <ControlButtonText className="material-symbols-outlined">
                {isLocalVideoEnabled
                  ? 'videocam'
                  : 'videocam_off'}
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {toggleAudio && !isAudienceMember ? (
            <ControlButton
              label={
                isLocalAudioEnabled
                  ? 'Turn off microphone'
                  : 'Turn on microphone'
              }
              aria-label="Toggle microphone"
              backgroundColor={
                isLocalAudioEnabled
                  ? 'buttonPrimary'
                  : 'buttonDeny'
              }
              onClick={toggleAudio}
            >
              <ControlButtonText className="material-symbols-outlined">
                {isLocalAudioEnabled ? 'mic' : 'mic_off'}
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {canShareScreen ? (
            <ControlButton
              label={
                isLocalScreenShared
                  ? 'End screen share'
                  : 'Start screen share'
              }
              aria-label="Toggle screen share"
              onClick={toggleScreenShare}
            >
              <ControlButtonText className="material-symbols-outlined">
                {isLocalScreenShared
                  ? 'stop_screen_share'
                  : 'screen_share'}
              </ControlButtonText>
            </ControlButton>
          ) : null}

          <ControlButton
            label={
              metadata.isHandRaised ? 'Lower hand' : 'Raise hand'
            }
            aria-label="Toggle raise hand"
            onClick={toggleRaiseHand}
            disabled={!canRaiseHand && !metadata.isHandRaised}
          >
            <ControlButtonText className="material-symbols-outlined">
              {metadata.isHandRaised
                ? 'do_not_touch'
                : 'pan_tool'}
            </ControlButtonText>
          </ControlButton>

          <ControlButton
            label={
              displayLayout === 'show active'
                ? 'Switch to grid view'
                : 'Switch to active view'
            }
            aria-label="Toggle display mode"
            onClick={toggleLayout}
          >
            <ControlButtonText className="material-symbols-outlined">
              {displayLayout === 'show active'
                ? 'grid_view'
                : 'view_sidebar'}
            </ControlButtonText>
          </ControlButton>

          {isHost ? (
            <ControlButton
              label="Lower all hands"
              aria-label="Lower all hands"
              onClick={handleLowerAllHandsClick}
            >
              <ControlButtonText className="material-symbols-outlined">
                sign_language
              </ControlButtonText>
            </ControlButton>
          ) : null}

          <ControlButton
            label="Exit meeting"
            aria-label="Exit meeting"
            backgroundColor="buttonDeny"
            onClick={handleExitMeetingClick}
          >
            <ControlButtonText className="material-symbols-outlined">
              logout
            </ControlButtonText>
          </ControlButton>

          {isHost ? (
            <ControlButton
              label="End meeting"
              aria-label="End meeting"
              backgroundColor="buttonDeny"
              onClick={handleEndMeetingClick}
            >
              <ControlButtonText className="material-symbols-outlined">
                cancel
              </ControlButtonText>
            </ControlButton>
          ) : null}
        </HStack>

        <HStack
          spacing={[1, null, 3, null, null, 4]}
          flex={[1, 1, 0]}
          flexShrink={0}
          flexDirection="row"
        >
          {!isAudienceMember ? (
            <ControlButton
              label="Show settings"
              aria-label="Toggle settings modal"
              onClick={toggleSettingsModal}
            >
              <ControlButtonText className="material-symbols-outlined">
                settings
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {isHost ? (
            <ControlButton
              label={
                recordingState.browser.running ||
                  recordingState.hls.running
                  ? 'End recording'
                  : 'Start recording'
              }
              aria-label="Toggle HLS stream"
              backgroundColor={
                recordingState.browser.running ||
                  recordingState.hls.running
                  ? 'buttonDeny'
                  : 'buttonPrimary'
              }
              isLoading={isRecLoading}
              isDisabled={hlsState.running || rtmpState.running}
              onClick={toggleRecording}
            >
              <ControlButtonText className="material-symbols-outlined">
                radio_button_checked
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {isHost ? (
            <ControlButton
              label={
                hlsState.running
                  ? 'End HLS stream'
                  : 'Start HLS stream'
              }
              aria-label="Toggle HLS stream"
              backgroundColor={
                hlsState.running
                  ? 'buttonConfirm'
                  : 'buttonPrimary'
              }
              isLoading={isHlsLoading}
              onClick={toggleHlsStream}
            >
              <ControlButtonText className="material-symbols-outlined">
                {hlsState.running ? 'tv' : 'live_tv'}
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {isHost ? (
            <ControlButton
              label="RTMP streaming"
              aria-label="Toggle RTMP stream modal"
              backgroundColor={
                rtmpState.running
                  ? 'buttonConfirm'
                  : 'buttonPrimary'
              }
              isLoading={isRtmpLoading}
              isDisabled={hlsState.running}
              onClick={toggleRtmpStream}
            >
              <ControlButtonText className="material-symbols-outlined">
                {rtmpState.running ? 'tv' : 'connected_tv'}
              </ControlButtonText>
            </ControlButton>
          ) : null}

          {roomConfig && roomConfig.chatEnabled ? (
            <Box position="relative">
              <ControlButton
                label={
                  showChatDrawer ? 'Hide chat' : 'Show chat'
                }
                aria-label="Toggle chat panel"
                onClick={toggleChatDrawer}
              >
                <ControlButtonText className="material-symbols-outlined">
                  chat
                </ControlButtonText>
              </ControlButton>

              {unreadCount > 0 ? (
                <Circle
                  position="absolute"
                  top={-1}
                  right={-1}
                  size="20px"
                  backgroundColor="buttonDeny"
                >
                  <Text
                    fontSize="2xs"
                    fontWeight={800}
                    color="white"
                  >
                    {formatUnreadCount(unreadCount)}
                  </Text>
                </Circle>
              ) : null}
            </Box>
          ) : null}

          <Tooltip
            label={
              showParticipantDrawer
                ? 'Hide viewers'
                : 'See viewers'
            }
            backgroundColor="backgroundPrimaryExtraDark"
            color="white"
          >
            <DrawerToggle
              onClick={toggleParticipantDrawer}
              marginBottom={[4, null, 0]}
            >
              <Text
                fontSize="xs"
                color="textPrimary"
                marginRight={2}
              >
                {peerCount}
              </Text>

              <ControlButtonText className="material-symbols-outlined">
                group
              </ControlButtonText>
            </DrawerToggle>
          </Tooltip>
        </HStack>
      </Flex>

      <StartHlsModal
        isOpen={showStartHlsModal}
        onStartClick={handleStartHlsStream}
        onCloseClick={handleStartHlsModalClose}
      />

      <StartRtmpModal
        isOpen={showStartRtmpModal}
        onStartClick={handleStartRtmpStream}
        onCloseClick={handleStartRtmpModalClose}
      />

      <ConfirmationModal
        isOpen={showEndRecordingModal}
        title="End Recording"
        message="Are you sure you want to end the recording?"
        confirmButtonText="End Recording"
        onCloseClick={handleEndRecordingModalClose}
        onConfirmClick={handleEndRecording}
      />

      <ConfirmationModal
        isOpen={showEndHlsModal}
        title="End Livestream"
        message="Are you sure you want to end the livestream for all audience members?"
        confirmButtonText="End Livestream"
        onCloseClick={handleEndHlsModalClose}
        onConfirmClick={handleEndHlsStream}
      />

      <EndRtmpModal
        isOpen={showEndRtmpModal}
        relayInProgress={relayInProgress}
        onEndMultistreamClick={handleEndMultistream}
        onCloseClick={handleEndRtmpModalClose}
        onStartChatRelayClick={handleStartChatRelay}
        onEndChatRelayClick={handleEndChatRelay}
      />
    </>
  )
}

export const ControlBar = memo(_ControlBar)