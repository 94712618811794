import {
  useHMSStore,
  selectConnectionQualityByPeerID,
} from '@100mslive/react-sdk'
import { chakra, Box, Text } from '@chakra-ui/react'
import { getConnectionTitle } from './utils'

const IndicatorBox = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    top: 4,
    left: 4,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'backgroundPrimaryExtraDark',
    padding: 2,
    borderRadius: 12,
  },
})

const IndicatorText = chakra(Text, {
  baseStyle: {
    marginLeft: 2,
    color: 'white',
  },
})

interface ConnectionIdicatorProps {
  peerId: string
}

export const ConnectionIndicator = ({ peerId }: ConnectionIdicatorProps) => {
  const downlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(peerId)
  )?.downlinkQuality

  if (downlinkQuality === -1 || !downlinkQuality) {
    return null
  }

  if (downlinkQuality === 0) {
    return (
      <IndicatorBox>
        <span
          className="material-symbols-outlined"
          style={{ color: 'white' }}
        >
          wifi_off
        </span>
        <IndicatorText>
          {getConnectionTitle(downlinkQuality)}
        </IndicatorText>
      </IndicatorBox>
    )
  }

  return (
    <IndicatorBox>
      {/* TODO: Change color based on connection quality */}
      <span
        className="material-symbols-outlined"
        style={{ color: 'white', fontSize: 16 }}
      >
        wifi
      </span>
      <IndicatorText>{getConnectionTitle(downlinkQuality)}</IndicatorText>
    </IndicatorBox>
  )
}