import { memo } from 'react'
import {
  selectLocalPeerID,
  selectPeerByID,
  selectPeerMetadata,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import {
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  Portal,
  HStack,
  useToast,
  Flex,
  Select,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ControlButton, ControlButtonText } from '../button'
import { Toast } from '../toast'
import { CustomMetadata, Role, STANDARD_ROLES, STAGE_ROLES } from '../../models'
import { formatRole } from '../../utils'
import { TOAST_DURATION, MOD_FUNC_LOWER_HAND } from '../../constants'
import { useAppSelector } from '../../store'

const validationSchema = yup.object().shape({
  role: yup.string().required(),
})

interface AudienceControlProps {
  peerId: string
  isVisible: boolean
  onOpen: () => void
  onClose: () => void
}

const _AudienceControl = ({
  peerId,
  isVisible,
  onOpen,
  onClose,
}: AudienceControlProps) => {
  const { displayMode } = useAppSelector((state) => state.config)

  const localPeerId = useHMSStore(selectLocalPeerID)

  const peer = useHMSStore(selectPeerByID(peerId))

  const metadata: CustomMetadata = useHMSStore(selectPeerMetadata(peerId))

  const hmsActions = useHMSActions()

  const toast = useToast()

  const roles = displayMode === 'standard' ? STANDARD_ROLES : STAGE_ROLES

  const isLocal = localPeerId === peerId

  const onLowerHandClick = () => {
    try {
      hmsActions.sendDirectMessage(MOD_FUNC_LOWER_HAND, peerId)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeRoleSubmit = async (role: string) => {
    try {
      let force = false
      if (role === Role.Viewer || role === Role.HLSViewer) {
        force = true
      } else if (
        (peer?.roleName === Role.StageMain ||
          peer?.roleName === Role.StageViewer) &&
        role === Role.BackstageViewer
      ) {
        force = true
      } else if (isLocal) {
        force = true
      }

      await hmsActions.changeRoleOfPeer(peerId, role, force)

      if (!force) {
        toast({
          position: 'bottom-left',
          duration: TOAST_DURATION,
          isClosable: true,
          render: ({ onClose: onToastClose }) => (
            <Toast
              onClose={onToastClose}
              type="info"
              title="Role change request sent"
            />
          ),
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRemoveClick = () => {
    try {
      hmsActions.removePeer(peerId, 'Goodbye!')
    } catch (error) {
      console.error(error)
    }
  }

  if (isVisible) {
    return (
      <Popover onOpen={onOpen} onClose={onClose}>
        <PopoverAnchor>
          <Center
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            left={0}
            backgroundColor="backgroundPrimaryExtraExtraDark"
            backdropFilter="blur(8px)"
            borderRadius={12}
          >
            <PopoverTrigger>
              <ControlButton
                height="40px"
                width="40px"
                label="Show admin controls"
                aria-label="Show admin controls"
              >
                <ControlButtonText className="material-symbols-outlined">
                  settings
                </ControlButtonText>
              </ControlButton>
            </PopoverTrigger>
          </Center>
        </PopoverAnchor>

        <Portal>
          <PopoverContent
            width="auto"
            backgroundColor="black"
            border="none"
            borderRadius="full"
          >
            <PopoverBody>
              <HStack>
                <Formik
                  initialValues={{ role: '' }}
                  validateOnMount
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    onChangeRoleSubmit(values.role)
                  }}
                >
                  {({ isValid, setValues }) => (
                    <Form>
                      <Flex flexDirection="row">
                        <Select
                          borderRadius="full"
                          fontSize="sm"
                          onChange={({
                            currentTarget,
                          }) => {
                            setValues({
                              role: currentTarget.value,
                            })
                          }}
                        >
                          {[
                            'Change role',
                            ...roles,
                          ].map((role) => (
                            <option
                              key={role}
                              value={
                                role ===
                                  'Change role'
                                  ? ''
                                  : role
                              }
                            >
                              {role ===
                                'Change role'
                                ? role
                                : formatRole(
                                  role
                                )}
                            </option>
                          ))}
                        </Select>

                        <ControlButton
                          type="submit"
                          aria-label="Submit"
                          label="Change role"
                          height="40px"
                          width="40px"
                          backgroundColor="buttonConfirm"
                          disabled={!isValid}
                          marginLeft={2}
                        >
                          <ControlButtonText className="material-symbols-outlined">
                            check
                          </ControlButtonText>
                        </ControlButton>
                      </Flex>
                    </Form>
                  )}
                </Formik>

                {metadata.isHandRaised ? (
                  <ControlButton
                    height="40px"
                    width="40px"
                    label="Lower hand"
                    aria-label="Lower user hand"
                    onClick={onLowerHandClick}
                  >
                    <ControlButtonText className="material-symbols-outlined">
                      do_not_touch
                    </ControlButtonText>
                  </ControlButton>
                ) : null}

                <ControlButton
                  height="40px"
                  width="40px"
                  label="Remove from meeting"
                  aria-label="Remove from meeting"
                  backgroundColor="buttonDeny"
                  onClick={onRemoveClick}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    gavel
                  </ControlButtonText>
                </ControlButton>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    )
  }

  return null
}

export const AudienceControl = memo(_AudienceControl)