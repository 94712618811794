import React from 'react'
import { Box, BoxProps, Image } from '@chakra-ui/react'
import { DEFAULT_BANNER_URL } from '../../constants'

interface PageProps {
  accentColor?: string | null
  bannerImageUrl?: string | null
  containerStyle?: BoxProps
}

export const Page = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PageProps>
>(({ accentColor, bannerImageUrl, children, containerStyle }, ref) => {
  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      bgGradient={`linear(to-b, ${accentColor ? accentColor : 'headingPrimary'
        } 0%, backgroundPrimary 33%)`}
      backgroundColor="backgroundPrimary"
      overflow="hidden"
    >
      <Image
        width="100%"
        height="auto"
        objectFit="contain"
        src={bannerImageUrl ? bannerImageUrl : DEFAULT_BANNER_URL}
        alt="VeeFriends Meet Banner"
        mixBlendMode="multiply"
        style={{
          maskImage: 'linear-gradient(180deg, black, transparent)',
          WebkitMaskImage:
            'linear-gradient(180deg, black, transparent)',
        }}
        userSelect="none"
      />

      <Box
        ref={ref}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        {...containerStyle}
      >
        {children}
      </Box>
    </Box>
  )
})