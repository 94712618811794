import { memo, useState } from 'react'
import * as yup from 'yup'
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik'
import { useHMSStore, selectLocalPeerRoleName } from '@100mslive/react-sdk'
import { Message } from '@twilio/conversations'
import {
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  // Checkbox,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
// @ts-ignore
import EmojiPicker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { ChatFeed } from './ChatFeed'
import { ControlButtonText } from '../button'
import { Role } from '../../models'
import { CHAT_COOLDOWN } from '../../constants'

const validationSchema = yup.object().shape({
  message: yup.string().required(),
  pinMessage: yup.bool(),
})

export interface ChatFormProps {
  message: string
  pinMessage: boolean
}

interface ChatProps {
  messages: Message[]
  hasNext: boolean
  isLoadingNext: boolean
  onLoadNext: () => void
  onSubmit: (props: ChatFormProps) => void
  onRemoveMessage: (messageSid: string) => void
  onRemoveUserFromChat: (userSid: string) => void
}

const _Chat = ({ onSubmit, ...props }: ChatProps) => {
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)

  const [canSendMessage, setCanSendMessage] = useState(true)

  const [showDeleteControl, setShowDeleteControl] = useState(false)

  const isHost =
    localPeerRoleName === Role.Moderator ||
    localPeerRoleName === Role.StageModerator ||
    localPeerRoleName === Role.Host

  const onFormSubmit = (
    props: ChatFormProps,
    { resetForm }: FormikHelpers<ChatFormProps>
  ) => {
    if (!canSendMessage) {
      return
    }

    onSubmit(props)

    if (!isHost) {
      setCanSendMessage(false)
      setTimeout(() => {
        setCanSendMessage(true)
      }, CHAT_COOLDOWN)
    }

    resetForm()
  }

  return (
    <Flex flex={1} width="100%" maxWidth="100%" flexDirection="column">
      <ChatFeed showDeleteControl={showDeleteControl} {...props} />

      {isHost ? (
        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          marginRight={4}
          marginTop={2}
          marginBottom={-2}
        >
          <FormLabel
            htmlFor="delete-messages-switch"
            fontSize="xs"
            fontWeight={400}
            color="textSecondary"
            marginBottom={0}
          >
            Delete messages
          </FormLabel>

          <Switch
            id="delete-messages-switch"
            size="sm"
            sx={{
              'span.chakra-switch__track[data-checked]': {
                backgroundColor: 'buttonConfirm',
              },
            }}
            isChecked={showDeleteControl}
            onChange={({ currentTarget }) =>
              setShowDeleteControl(currentTarget.checked)
            }
          />
        </Flex>
      ) : null}

      <Formik
        initialValues={{ message: '', pinMessage: false }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({ setValues, values }) => (
          <Popover>
            <Form
              style={{
                marginTop: 16,
                marginLeft: 16,
                marginRight: 16,
                userSelect: 'none',
                WebkitUserSelect: 'none',
              }}
            >
              <Field name="message">
                {({ field }: FieldProps) => (
                  <PopoverAnchor>
                    <InputGroup>
                      <Input
                        {...field}
                        fontSize={[14, null, 16]}
                        maxLength={220}
                        placeholder="Type your message here"
                        autoComplete="off"
                        color="white"
                        backgroundColor="backgroundPrimary"
                        paddingY={6}
                        paddingRight="6rem"
                        borderRadius={12}
                        border="none"
                        _invalid={{
                          border: 'none',
                        }}
                        _focusVisible={{
                          border: 'none',
                        }}
                      />
                      <InputRightElement
                        height="100%"
                        width="6rem"
                      >
                        <PopoverTrigger>
                          <IconButton
                            aria-label="Emoji picker button"
                            backgroundColor="backgroundPrimary"
                            marginRight={1}
                          >
                            <ControlButtonText className="material-symbols-outlined">
                              mood
                            </ControlButtonText>
                          </IconButton>
                        </PopoverTrigger>

                        <IconButton
                          type="submit"
                          aria-label="Send message"
                          backgroundColor="backgroundPrimary"
                          disabled={
                            field.value === '' ||
                            !canSendMessage
                          }
                        >
                          <ControlButtonText className="material-symbols-outlined">
                            send
                          </ControlButtonText>
                        </IconButton>
                      </InputRightElement>
                    </InputGroup>
                  </PopoverAnchor>
                )}
              </Field>

              <Flex
                justifyContent={
                  !canSendMessage || isHost
                    ? 'space-between'
                    : 'flex-end'
                }
                margin={2}
              >
                {!canSendMessage ? (
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                  >
                    <ControlButtonText
                      className="material-symbols-outlined"
                      fontSize="xs"
                      color="textSecondary"
                      marginRight={1}
                    >
                      timer
                    </ControlButtonText>
                    <Text
                      fontSize="2xs"
                      color="textSecondary"
                    >
                      Slowmode enabled
                    </Text>
                  </Flex>
                ) : null}

                {/* {isHost ? (
                                    <Field name="pinMessage">
                                        {({ field }: FieldProps) => (
                                            <Checkbox
                                                {...field}
                                                isChecked={field.value}
                                                size="sm"
                                                colorScheme="green"
                                            >
                                                <Text
                                                    fontSize="xs"
                                                    color="textSecondary"
                                                >
                                                    Pin message
                                                </Text>
                                            </Checkbox>
                                        )}
                                    </Field>
                                ) : null} */}

                <Text fontSize="2xs" color="textSecondary">
                  {`${values.message.length}/220`}
                </Text>
              </Flex>
            </Form>

            <PopoverContent
              width="100%"
              border="none"
              backgroundColor="transparent"
              paddingBottom={2}
            >
              <PopoverBody padding={0}>
                <EmojiPicker
                  data={data}
                  theme="dark"
                  previewPosition="none"
                  onEmojiSelect={({ native }: any) => {
                    setValues((prevState) => ({
                      ...prevState,
                      message: prevState.message + native,
                    }))
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Formik>
    </Flex>
  )
}

export const Chat = memo(_Chat)