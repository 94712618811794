import { memo } from 'react'
import { Container, Flex, HStack, Link, Text } from '@chakra-ui/react'

const _Footer = () => {
  return (
    <Flex height="7rem" backgroundColor="backgroundSecondary">
      <Container>
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          userSelect="none"
        >
          <HStack spacing={8} marginBottom={4}>
            <Link
              fontSize="sm"
              href="https://veefriends.com/terms-of-use/experience"
              rel="noopener noreferrer"
              isExternal
            >
              Experience Terms
            </Link>
            <Link
              fontSize="sm"
              href="https://veefriends.com/privacy-policy"
              rel="noopener noreferrer"
              isExternal
            >
              Privacy Policy
            </Link>
          </HStack>

          <Text fontSize="xs" color="textSecondary">
            © 2022 VeeFriends. All rights reserved.
          </Text>
        </Flex>
      </Container>
    </Flex>
  )
}

export const Footer = memo(_Footer)