import { Flex } from '@chakra-ui/react'
import { ControlButton, ControlButtonText } from '../button'

interface MessageControlProps {
  isVisible: boolean
  onDeleteClick: () => void
}

export const MessageControl = ({
  isVisible,
  onDeleteClick,
}: MessageControlProps) => {
  if (isVisible) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor="backgroundPrimaryExtraExtraDark"
      >
        <ControlButton
          height="25px"
          width="25px"
          minWidth="25px"
          label="Delete"
          aria-label="Delete message"
          backgroundColor="backgroundSecondary"
          _hover={{ backgroundColor: 'buttonDeny' }}
          onClick={onDeleteClick}
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="16px"
          >
            delete
          </ControlButtonText>
        </ControlButton>
      </Flex>
    )
  }

  return null
}