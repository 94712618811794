export const TOAST_DURATION = 5 * 1000
export const HANDS_RAISED_COUNTER_DURATION = 10 * 1000
export const RAISE_HAND_COOLDOWN = 5 * 1000

export const CHAT_COOLDOWN = 5 * 1000
export const CHAT_PINNED_MESSAGES_MAX = 10

export const VERTICAL_TILES_PER_PAGE = 4
export const HORIZONTAL_TILES_PER_PAGE = 3
export const PEER_GRID_MAX_TILES = 20

export const STAGE_AUDIENCE_BREAKPOINT = 50

export const GUEST_LINK_DURATION = 24 * 60 * 60 // 24 hours

export const MOD_FUNC_BOT_TOGGLE_BROADCAST = 'mod_func_bot_toggle_broadcast'
export const MOD_FUNC_BOT_TOGGLE_DISPLAY_LAYOUT =
  'mod_func_bot_toggle_display_layout'
export const MOD_FUNC_BOT_ACK = 'mod_func_bot_ack'
export const MOD_FUNC_BOT_BOUNDARY = '~~'

export const MOD_FUNC_END_SCREENSHARE = 'mod_func_end_screenshare'
export const MOD_FUNC_PIN_CHAT_MESSAGE = 'mod_func_pin_chat_message'
export const MOD_FUNC_LOWER_HAND = 'mod_func_lower_hand'
export const MOD_FUNC_LOWER_ALL_HANDS = 'mod_func_lower_all_hands'
export const FRIENDLY_LOWER_ALL_HANDS = '*Moderator has lowered all hands.'

export const QUERY_PARAM_BOT_TOKEN = 'botToken'
export const QUERY_PARAM_BOT_TYPE = 'botType'
export const QUERY_PARAM_VF_TOKEN = 'vfToken'
export const QUERY_PARAM_VF_GUEST_CODE = 'code'
export const QUERY_PARAM_LOGGED_OUT = 'loggedOut'