import { memo } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { JSONObject, Message } from '@twilio/conversations'
import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { MessageBubble, MessageBubbleProps } from './MessageBubble'

interface HeaderProps {
  hasNext: boolean
  isLoadingNext: boolean
  onLoadNext: () => void
}

const Header = ({ hasNext, isLoadingNext, onLoadNext }: HeaderProps) => {
  if (hasNext) {
    return (
      <Flex justifyContent="center">
        <Button
          variant="unstyled"
          color="textSecondary"
          fontSize="sm"
          fontWeight={400}
          isLoading={isLoadingNext}
          _loading={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={onLoadNext}
        >
          Load more
        </Button>
      </Flex>
    )
  }
  return null
}

interface ChatFeedProps {
  messages: Message[]
  hasNext: boolean
  isLoadingNext: boolean
  showDeleteControl: boolean
  onLoadNext: () => void
  onRemoveMessage: (messageSid: string) => void
  onRemoveUserFromChat: (userSid: string) => void
}

const _ChatFeed = ({
  messages,
  hasNext,
  isLoadingNext,
  showDeleteControl,
  onLoadNext,
  onRemoveMessage,
  onRemoveUserFromChat,
}: ChatFeedProps) => {
  if (messages.length === 0) {
    return (
      <Center flex={1} userSelect="none">
        <Text>Say hello!</Text>
      </Center>
    )
  }

  const data: MessageBubbleProps[] = []
  let previousSender
  for (let i = 0; i < messages.length; i++) {
    let attributes = messages[i].attributes as JSONObject
    let userId = attributes['peerId']
      ? attributes['peerId']
      : messages[i].participantSid

    data.push({
      participantSid: messages[i].participantSid,
      body: messages[i].body,
      attributes: attributes,
      didSenderChange: previousSender !== userId,
      showDeleteControl: showDeleteControl,
      onRemoveMessage: () => onRemoveMessage(messages[i].sid),
      onRemoveUserFromChat: () => {
        if (messages[i].participantSid) {
          onRemoveUserFromChat(messages[i].participantSid!)
        }
      },
    })

    previousSender = userId
  }

  return (
    <Flex flex={1}>
      <Virtuoso
        data={data}
        initialTopMostItemIndex={data.length - 1}
        followOutput="smooth"
        itemContent={(_, props) => <MessageBubble {...props} />}
        components={{
          Header: () => (
            <Header
              hasNext={hasNext}
              isLoadingNext={isLoadingNext}
              onLoadNext={onLoadNext}
            />
          ),
        }}
        style={{
          flex: 1,
          marginRight: 16,
          marginLeft: 16,
        }}
      />
    </Flex>
  )
}

export const ChatFeed = memo(_ChatFeed)