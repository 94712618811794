import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import { ControlButton, ControlButtonText } from '../../components'
import { DEFAULT_BANNER_URL } from '../../constants'

const APP_URL = process.env.REACT_APP_URL

export interface PublicRoomItemProps {
  id: string
  title?: string | null
  subtitle?: string | null
  accentColor?: string | null
  bannerImageUrl?: string | null
}

export const PublicRoomItem = ({
  id,
  title,
  subtitle,
  accentColor,
  bannerImageUrl,
}: PublicRoomItemProps) => {
  const MEETING_URL = `${APP_URL}${id}`

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      gap={4}
      width="100%"
      backgroundColor="black"
      padding={4}
      borderRadius={20}
    >
      <Flex
        flex={1}
        position="relative"
        userSelect="none"
        bgGradient={`linear(to-b, ${accentColor ? accentColor : 'headingPrimary'
          } 0%, backgroundPrimary 100%)`}
        backgroundColor="backgroundPrimary"
        overflow="hidden"
        borderRadius={20}
      >
        <Image
          width="100%"
          height="auto"
          objectFit="contain"
          src={bannerImageUrl ? bannerImageUrl : DEFAULT_BANNER_URL}
          alt="VeeFriends Meet Banner"
          mixBlendMode="multiply"
        />

        <Flex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="100%"
        >
          <Heading
            as="h3"
            fontSize="24px"
            fontWeight={800}
            textTransform="uppercase"
            textAlign="center"
            maxWidth="80%"
          >
            {title}
          </Heading>
        </Flex>
      </Flex>

      <Flex
        flex={2}
        flexDirection="column"
        justifyContent="center"
        gap={4}
      >
        <Box>
          <Text fontSize="xs" color="textSecondary" userSelect="none">
            Meeting ID
          </Text>
          <Text fontSize="md">{id}</Text>
        </Box>

        {subtitle && (
          <Box>
            <Text
              fontSize="xs"
              color="textSecondary"
              userSelect="none"
            >
              Description
            </Text>
            <Text fontSize="md">{subtitle}</Text>
          </Box>
        )}
      </Flex>

      <ControlButton
        as={Link}
        href={MEETING_URL}
        target="_blank"
        rel="noopener noreferrer"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="buttonConfirm"
        label="Join room"
        aria-label="Join room"
      >
        <ControlButtonText
          className="material-symbols-outlined"
          fontSize="sm"
        >
          login
        </ControlButtonText>
      </ControlButton>
    </Flex>
  )
}