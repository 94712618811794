import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex, Container, Image } from '@chakra-ui/react'
import { usePreviewJoin } from '@100mslive/react-sdk'
import { PreviewFormValues, PreviewForm } from './PreviewForm'
import { Page, PreviewTile, Footer, PwaPrompt } from '../../components'
import {
  useAppDispatch,
  useAppSelector,
  setErrorMessage,
  setDisplayName,
} from '../../store'
import { getPwaDisplayMode } from '../../utils'
import { INITIAL_CUSTOM_METADATA } from '../../models'
import logo from '../../assets/images/logo-color.svg'

export const PreviewPage = () => {
  const navigate = useNavigate()

  const { meetingId } = useParams()

  const dispatch = useAppDispatch()

  const { token, displayName, avatarUrl, twilioConfig, guestCodes } =
    useAppSelector((state) => state.config)

  const [shouldJoin, setShouldJoin] = useState(false)

  const [showAddToHome, setShowAddToHome] = useState(false)

  const { enableJoin, join } = usePreviewJoin({
    name: displayName,
    token,
    initialSettings: {
      isAudioMuted: true,
      isVideoMuted: true,
    },
    captureNetworkQualityInPreview: true,
    metadata: JSON.stringify({
      ...INITIAL_CUSTOM_METADATA,
      avatarUrl,
      participantSid: twilioConfig ? twilioConfig.participantSid : '',
      isGuestSpeaker:
        meetingId !== undefined &&
        Object.keys(guestCodes).includes(meetingId),
    }),
    handleError: (error) => {
      console.error(error)
      dispatch(
        setErrorMessage(
          'Error joining the specified room. Please check the meeting ID and try again.'
        )
      )
      navigate('/error')
    },
  })

  // Detect if launched from PWA
  useEffect(() => {
    const displayMode = getPwaDisplayMode()
    setShowAddToHome(displayMode === 'browser')
  }, [])

  useEffect(() => {
    if (displayName && shouldJoin) {
      join()
      navigate(`/${meetingId}/join`)
    }
  }, [displayName, shouldJoin, meetingId, join, navigate])

  const onJoin = async ({ displayName }: PreviewFormValues) => {
    dispatch(setDisplayName(displayName))
    setShouldJoin(true)
  }

  return (
    <Page
      accentColor="backgroundPrimary"
      containerStyle={{ overflowY: 'scroll' }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        minHeight="calc(100% - 7rem)"
        width="100%"
        paddingTop={8}
        paddingBottom={16}
        userSelect="none"
      >
        <Image
          src={logo}
          maxWidth={['33%', null, 'unset']}
          marginBottom={[4, null, 8]}
        />

        <Container display="flex" flexDirection="column">
          <PreviewTile
            token={token}
            name={displayName}
            avatarUrl={avatarUrl}
          />

          <PreviewForm
            initialValues={{ displayName }}
            onSubmit={onJoin}
            disabled={!enableJoin}
          />

          {showAddToHome ? <PwaPrompt /> : null}
        </Container>
      </Flex>

      <Footer />
    </Page>
  )
}