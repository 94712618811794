import { Heading, Text, Image, Container } from '@chakra-ui/react'
import { Page } from '../../components'
import { setToken, setWidgetbotToken, useAppDispatch } from '../../store'
import pig from '../../assets/images/pig.gif'

export const NotFoundPage = () => {
  const dispatch = useAppDispatch()

  dispatch(setToken(''))
  dispatch(setWidgetbotToken(''))

  return (
    <Page
      accentColor="backgroundPrimary"
      containerStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        userSelect="none"
      >
        <Image
          height="250px"
          width="auto"
          objectFit="contain"
          src={pig}
          marginBottom={12}
        />

        <Heading
          size="2xl"
          fontWeight={800}
          color="textPrimary"
          textTransform="uppercase"
          textAlign="center"
          marginBottom={2}
        >
          You must be lost...
        </Heading>

        <Text fontSize="lg" color="textSecondary" textAlign="center">
          The requested page does not exist.
        </Text>
      </Container>
    </Page>
  )
}