/**
 * Retrieves the connection title based on the connection score.
 * @param connectionScore The score representing the connection quality.
 * @returns The corresponding connection title.
 * @throws {Error} If an invalid connection score is provided.
 */
export const getConnectionTitle = (connectionScore: number) => {
  switch (connectionScore) {
    case 0:
      return 'Reconnecting'
    case 1:
      return 'Very Bad'
    case 2:
      return 'Bad'
    case 3:
      return 'Moderate'
    case 4:
      return 'Good'
    case 5:
      return 'Excellent'
    default:
      return 'Network Unknown'
  }
}