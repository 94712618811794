/**
 * Retrieves the display mode of the Progressive Web App (PWA).
 * 
 * @returns {string} The display mode of the PWA ('twa', 'standalone', or 'browser').
 * @throws {Error} If an error occurs during the process.
 */
export const getPwaDisplayMode = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches

  if (document.referrer.startsWith('android-app://')) {
    return 'twa'
  } else if (
    // @ts-ignore
    ('standalone' in window.navigator && window.navigator.standalone) ||
    isStandalone
  ) {
    return 'standalone'
  }

  return 'browser'
}

/**
 * Function to check if the current browser is Safari on iOS.
 * 
 * @returns {boolean} Returns true if the current browser is Safari on iOS, false otherwise.
 * @throws {Error} Throws an error if unable to determine the browser information.
 */
export const getIsIos = () => {
  const ua = window.navigator.userAgent
  const webkit = !!ua.match(/WebKit/i)
  const isIPad = !!ua.match(/iPad/i)
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i)

  return isSafari
}