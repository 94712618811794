import { memo } from 'react'
import { motion } from 'framer-motion'
import { Flex, IconButton, BoxProps } from '@chakra-ui/react'
import { Card, ControlButtonText } from '../../components'

const MotionCard = motion(Card)

interface DrawerProps {
  containerStyle?: BoxProps
  onCloseClick?: () => void
}

const _Drawer = ({
  containerStyle,
  onCloseClick,
  children,
}: React.PropsWithChildren<DrawerProps>) => {
  return (
    <MotionCard
      display="flex"
      width={['unset', null, '28rem']}
      marginY={4}
      position={['absolute', null, null, 'relative']}
      top={[2, null, null, 'unset']}
      bottom={[2, null, null, 'unset']}
      right={[2, null, null, 'unset']}
      left={[2, null, 'unset']}
      initial={{
        x: 500,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      {...containerStyle}
    >
      <Flex
        flex={1}
        margin={onCloseClick ? 4 : 0}
        marginTop={onCloseClick ? 12 : 0}
      >
        {children}
      </Flex>

      {onCloseClick && (
        <IconButton
          aria-label="Close side drawer button"
          position="absolute"
          top={1}
          right={1}
          backgroundColor="transparent"
          borderRadius="full"
          onClick={onCloseClick}
        >
          <ControlButtonText className="material-symbols-outlined">
            close
          </ControlButtonText>
        </IconButton>
      )}
    </MotionCard>
  )
}

export const Drawer = memo(_Drawer)