import { chakra, IconButton } from '@chakra-ui/react'

export const PaginationButton = chakra(IconButton, {
  baseStyle: {
    variant: 'outline',
    borderColor: 'white',
    backgroundColor: 'buttonPrimary',
    _hover: {
      backgroundColor: 'buttonPrimaryHover',
    },
  },
})