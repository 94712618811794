import { Heading, Text, Image, Container } from '@chakra-ui/react'
import { Page } from '../../components'
import {
  setToken,
  setWidgetbotToken,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import robot from '../../assets/images/robot.gif'

export const ErrorPage = () => {
  const dispatch = useAppDispatch()
  const { errorMessage } = useAppSelector((state) => state.app)

  dispatch(setWidgetbotToken(''))
  dispatch(setToken(''))

  return (
    <Page
      accentColor="backgroundPrimary"
      containerStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        userSelect="none"
      >
        <Image
          height="250px"
          width="auto"
          objectFit="contain"
          src={robot}
          marginBottom={12}
        />

        <Heading
          size="2xl"
          fontWeight={800}
          color="textPrimary"
          textTransform="uppercase"
          textAlign="center"
          marginBottom={2}
        >
          Recharging our batteries...
        </Heading>

        <Text fontSize="lg" color="textSecondary" textAlign="center">
          Our team is working quickly to resolve the issue.
        </Text>

        {errorMessage ? (
          <Text
            fontSize="xs"
            color="textSecondary"
            textAlign="center"
          >
            {errorMessage}
          </Text>
        ) : null}
      </Container>
    </Page>
  )
}