import { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
  HMSPeer,
  selectDominantSpeaker,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Box, Flex } from '@chakra-ui/react'
import { PeerList } from './PeerList'
import { VideoTile } from '../tile'

interface ShowActiveViewProps {
  peers: HMSPeer[]
  pinnedPeer?: HMSPeer
  hidePeerList?: boolean
}

const _ShowActiveView = ({
  peers,
  pinnedPeer,
  hidePeerList = false,
}: ShowActiveViewProps) => {
  // Get the current dominant speaker using the HMS store
  const dominantSpeaker = useHMSStore(selectDominantSpeaker)

  const [isPeerListActive, setIsPeerListActive] = useState(false)

  // Ref to keep track of the latest dominant speaker
  const latestDominantSpeakerRef = useRef(dominantSpeaker)
  if (dominantSpeaker) {
    latestDominantSpeakerRef.current = dominantSpeaker
  }

  // If the latest dominant speaker is not in the peer list, default to the first peer
  if (
    latestDominantSpeakerRef.current &&
    peers
      .map((peer) => peer.id)
      .indexOf(latestDominantSpeakerRef.current.id) === -1
  ) {
    latestDominantSpeakerRef.current = peers[0]
  }

  let activeSpeaker: HMSPeer
  if (pinnedPeer) {
    activeSpeaker = pinnedPeer
  } else {
    activeSpeaker = latestDominantSpeakerRef.current || peers[0]
  }

  // Initialize a count for each peer's active audio
  const initialCount: { id: string; count: number }[] = peers.map((peer) => ({
    id: peer.id,
    count: 0,
  }))
  const dominantSpeakerCounts = useRef(initialCount)

  // Update the dominant speaker count when a new dominant speaker is detected
  useEffect(() => {
    if (isPeerListActive) return

    let added = false

    dominantSpeakerCounts.current.forEach((item) => {
      if (item.id === activeSpeaker.id) {
        item.count = item.count + 1
        added = true
      }
    })

    if (!added) {
      dominantSpeakerCounts.current.push({
        id: activeSpeaker.id,
        count: 1,
      })
    }

    // Sort the list of speakers based on the count
    dominantSpeakerCounts.current.sort((a, b) =>
      a.count > b.count ? -1 : 1
    )
  }, [activeSpeaker, isPeerListActive])

  const peerList = useMemo(
    () =>
      peers.filter((peer) => {
        if (pinnedPeer) {
          return (
            peer.id !== pinnedPeer.id &&
            dominantSpeakerCounts.current
              .filter((item) => item.id !== pinnedPeer.id)
              .map((item) => item.id)
              .indexOf(peer.id) !== -1
          )
        }

        return (
          peer.id !== activeSpeaker.id &&
          dominantSpeakerCounts.current
            .filter((item) => item.id !== activeSpeaker.id)
            .map((item) => item.id)
            .indexOf(peer.id) !== -1
        )
      }),
    [peers, activeSpeaker.id, pinnedPeer, dominantSpeakerCounts]
  )

  const onMouseEnterCallback = () => setIsPeerListActive(true)

  const onMouseLeaveCallback = () => setIsPeerListActive(false)

  return (
    <Flex
      flexDirection={['column', null, 'row']}
      justifyContent="center"
      height="100%"
    >
      <Flex
        flex={[1, null, 'unset']}
        height="100%"
        width={['unset', null, 'calc((100vh - 2rem - 5.5rem) * (4/3))']}
      >
        <VideoTile
          peerId={activeSpeaker.id}
          trackId={activeSpeaker.videoTrack}
        />
      </Flex>

      {!hidePeerList ? (
        <Box
          height={
            peers.length - 1 > 5
              ? ['250px', null, '100%']
              : ['125px', null, '100%']
          }
          width={
            peers.length - 1 > 5
              ? ['100%', null, '250px', null, '360px']
              : ['100%', null, '125px', null, '180px']
          }
          marginLeft={[0, null, 4]}
          marginTop={[4, null, 0]}
        >
          <PeerList
            peers={peerList}
            additionalCount={
              dominantSpeakerCounts.current.length -
              peerList.length -
              1
            }
            onMouseEnterCallback={onMouseEnterCallback}
            onMouseLeaveCallback={onMouseLeaveCallback}
          />
        </Box>
      ) : null}
    </Flex>
  )
}

export const ShowActiveView = memo(_ShowActiveView)