import { memo, useEffect, useState } from 'react'
import * as yup from 'yup'
import { format } from 'date-fns';
import { useFormik } from 'formik'
import {
  Center,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useClipboard,
  Link,
  Spinner,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react'
import { ControlButton, ControlButtonText } from '../../components'
import { RoomDataModel, RoomRoleLinkModel } from '../../services/meetApi'
import { formatGuestLinkExpiration } from '../../utils'

const HIDDEN_ROLES = ['__internal_recorder', 'stage-bot', 'chat-time-out']

const APP_URL = process.env.REACT_APP_URL

interface RoleListItemProps {
  id: string
  role: string
}

const _RoleListItem = ({ id, role }: RoleListItemProps) => {
  const MEETING_URL = `${APP_URL}${id}`

  const { onCopy, hasCopied } = useClipboard(MEETING_URL)

  return (
    <Flex
      flexDirection="row"
      width="100%"
      backgroundColor="black"
      paddingY={4}
      paddingX={6}
      borderRadius={20}
    >
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        userSelect="none"
      >
        <Text fontSize="xs" color="textSecondary">
          Role
        </Text>
        <Text fontSize="md">{role}</Text>
      </Flex>

      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        marginX={4}
      >
        <Text fontSize="xs" color="textSecondary" userSelect="none">
          Meeting ID
        </Text>
        <Text fontSize="md">{id}</Text>
      </Flex>

      <HStack>
        <ControlButton
          label="Copy meeting link"
          aria-label="Copy meeting link"
          onClick={onCopy}
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="sm"
            color={hasCopied ? 'buttonConfirm' : 'white'}
          >
            {hasCopied ? 'done' : 'file_copy'}
          </ControlButtonText>
        </ControlButton>

        <ControlButton
          as={Link}
          href={MEETING_URL}
          target="_blank"
          rel="noopener noreferrer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="buttonConfirm"
          label="Join room"
          aria-label="Join room"
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="sm"
          >
            login
          </ControlButtonText>
        </ControlButton>
      </HStack>
    </Flex>
  )
}

const RoleListItem = memo(_RoleListItem)

interface GuestLinkItemProps extends GuestLinkData {
  endDate?: string | null
  onDeleteGuestLinkClick: (meetingId: string, code: string) => void
}

const _GuestLinkItem = ({
  friendlyName,
  role,
  meetingId,
  privateCode,
  timestamp,
  endDate,
  onDeleteGuestLinkClick,
}: GuestLinkItemProps) => {
  const MEETING_URL = `${APP_URL}${meetingId}?code=${privateCode}`

  const { onCopy, hasCopied } = useClipboard(MEETING_URL)

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <HStack flex={1}>
        <Flex flex={1} flexDirection="column" justifyContent="center">
          <Text fontSize="xs" color="textSecondary">
            Friendly Name
          </Text>
          <Text fontSize="md">
            {friendlyName ? friendlyName : privateCode}
          </Text>
        </Flex>

        <Flex flex={1} flexDirection="column" justifyContent="center">
          <Text fontSize="xs" color="textSecondary">
            Role
          </Text>
          <Text fontSize="md">{role}</Text>
        </Flex>

        <Flex flex={1} flexDirection="column" justifyContent="center">
          <Text fontSize="xs" color="textSecondary">
            Expires
          </Text>
          <Text fontSize="md">
            {formatGuestLinkExpiration(timestamp, endDate)}
          </Text>
        </Flex>
      </HStack>

      <HStack flexShrink={0} marginLeft={4}>
        <ControlButton
          label="Copy guest link"
          aria-label="Copy guest link"
          onClick={onCopy}
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="sm"
            color={hasCopied ? 'buttonConfirm' : 'white'}
          >
            {hasCopied ? 'done' : 'file_copy'}
          </ControlButtonText>
        </ControlButton>

        <ControlButton
          label="Delete guest link"
          aria-label="Delete guest link"
          backgroundColor="buttonDeny"
          onClick={() =>
            onDeleteGuestLinkClick(meetingId, privateCode)
          }
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="sm"
          >
            delete
          </ControlButtonText>
        </ControlButton>
      </HStack>
    </Flex>
  )
}

const GuestLinkItem = memo(_GuestLinkItem)

const validationSchema = yup.object().shape({
  friendlyName: yup.string().required('Friendly name required'),
  role: yup.string().required('Role required'),
  email: yup.string().optional().email('Enter valid email address'),
})

const initialValues = { friendlyName: '', role: '', email: '' }

interface GuestLinkData {
  meetingId: string
  role: string
  privateCode: string
  friendlyName?: string | null
  timestamp?: number | null
}

interface GuestLinkRoles {
  meetingId: string
  role: string
}

interface GuestLinkListProps {
  guestLinks: GuestLinkData[]
  guestLinkRoles: GuestLinkRoles[]
  endDate?: string | null
  isLoading: boolean
  onNewGuestLinkClick: (
    meetingId: string,
    friendlyName: string,
    emailAddress?: string
  ) => void
  onDeleteGuestLinkClick: (meetingId: string, code: string) => void
}

const GuestLinkList = ({
  guestLinks,
  guestLinkRoles,
  endDate,
  isLoading,
  onNewGuestLinkClick,
  onDeleteGuestLinkClick,
}: GuestLinkListProps) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ role, friendlyName, email }, { resetForm }) => {
      onNewGuestLinkClick(role, friendlyName, email)
      resetForm()
    },
  })

  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor="black"
      paddingY={4}
      paddingX={6}
      borderRadius={20}
      userSelect="none"
    >
      <Heading
        as="h3"
        size="sm"
        fontWeight={800}
        textTransform="uppercase"
        marginBottom={4}
      >
        Guest Links
      </Heading>

      {guestLinks.length === 0 ? (
        <Text>No guest links</Text>
      ) : (
        <VStack spacing={4}>
          {guestLinks.map((link) => (
            <GuestLinkItem
              key={link.privateCode}
              endDate={endDate}
              {...link}
              onDeleteGuestLinkClick={onDeleteGuestLinkClick}
            />
          ))}
        </VStack>
      )}

      <Flex
        flexDirection="row"
        alignItems="flex-start"
        gap={4}
        marginTop={6}
      >
        <FormControl
          isInvalid={!!formik.errors.role && formik.touched.role}
        >
          <Select
            id="role"
            name="role"
            placeholder="Select role"
            value={formik.values.role}
            onChange={formik.handleChange}
          >
            {guestLinkRoles.map((role) => (
              <option key={role.meetingId} value={role.meetingId}>
                {role.role}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={
            !!formik.errors.friendlyName &&
            formik.touched.friendlyName
          }
        >
          <Input
            id="friendlyName"
            name="friendlyName"
            placeholder="Friendly name"
            value={formik.values.friendlyName}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>
            {formik.errors.friendlyName}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.email && formik.touched.email}
        >
          <Input
            id="email"
            name="email"
            placeholder="Email address"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>

        <Button
          flexShrink={0}
          fontSize="xs"
          isLoading={isLoading}
          onClick={formik.submitForm}
        >
          Create
        </Button>
      </Flex>
    </Flex>
  )
}

interface RoomListItemProps {
  roomId: string
  title: string
  startDate?: string | null
  endDate?: string | null
  roles?: RoomRoleLinkModel[] | null
  isLive?: boolean
  isLoading: boolean
  onNewGuestLinkClick: (
    roomId: string,
    meetingId: string,
    friendlyName: string,
    emailAddress?: string
  ) => void
  onDeleteGuestLinkClick: (
    roomId: string,
    meetingId: string,
    code: string
  ) => void
  onEditClick: (roomId: string) => void
  onDeleteClick: (meetingId: string) => void
}

const _RoomListItem = ({
  roomId,
  title,
  startDate,
  endDate,
  roles,
  isLive,
  isLoading,
  onNewGuestLinkClick,
  onDeleteGuestLinkClick,
  onEditClick,
  onDeleteClick,
}: RoomListItemProps) => {
  const [guestLinks, setGuestLinks] = useState<GuestLinkData[]>([])

  const [guestLinkRoles, setGuestLinkRoles] = useState<GuestLinkRoles[]>([])

  const [modMeetingId, setModMeetingId] = useState('')

  useEffect(() => {
    const _guestLinks: GuestLinkData[] = []

    const _guestLinkRoles: GuestLinkRoles[] = []

    roles &&
      roles.forEach((role) => {
        if (role.role === 'moderator' && role.id) {
          setModMeetingId(role.id)
        }

        if (role.role && role.id) {
          _guestLinkRoles.push({
            meetingId: role.id,
            role: role.role,
          })
        }

        if (role.privateCodes && role.id) {
          Object.keys(role.privateCodes).forEach((privateCode) => {
            const { friendlyName, timestamp } =
              role.privateCodes![privateCode]

            _guestLinks.push({
              meetingId: role.id!,
              role: role.role!,
              privateCode,
              friendlyName,
              timestamp,
            })
          })
        }
      })

    setGuestLinks(_guestLinks)

    setGuestLinkRoles(_guestLinkRoles)
  }, [roles])

  return (
    <AccordionItem width="100%">
      <AccordionButton width="100%" paddingY={4}>
        <Flex flex={1} flexDirection="column" alignItems="flex-start">
          <Heading
            as="h2"
            size="md"
            fontWeight={800}
            textAlign="start"
            textTransform="uppercase"
          >
            {`${title}`}
          </Heading>

          {startDate ? (
            <Text
              fontSize="xs"
              fontWeight={500}
              color="textSecondary"
            >
              {format(
                new Date(startDate),
                'LLL dd, yyyy @ h:mmaaa'
              )}
            </Text>
          ) : (
            <Text
              fontSize="xs"
              fontWeight={500}
              color="textSecondary"
            >
              No event
            </Text>
          )}
        </Flex>

        {isLive && (
          <Flex
            alignItems="center"
            gap={1}
            backgroundColor="buttonDeny"
            padding={1}
            borderRadius={6}
            marginRight={4}
          >
            <ControlButtonText
              className="material-symbols-outlined"
              fontSize="sm"
            >
              radio_button_checked
            </ControlButtonText>
            <Text
              fontSize="xs"
              fontWeight={800}
              textTransform="uppercase"
            >
              Live
            </Text>
          </Flex>
        )}

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel>
        <VStack spacing={2}>
          <Text
            fontSize="xs"
            fontWeight={500}
            color="textSecondary"
            alignSelf="flex-start"
          >
            {`Room ID: ${roomId}`}
          </Text>

          {roles &&
            roles.map((_role) => {
              if (
                _role.id &&
                _role.role &&
                HIDDEN_ROLES.indexOf(_role.role) === -1
              ) {
                const { role, id } = _role

                return (
                  <RoleListItem
                    key={id}
                    id={id}
                    role={role}
                  />
                )
              }

              return null
            })}

          <GuestLinkList
            guestLinks={guestLinks}
            guestLinkRoles={guestLinkRoles}
            endDate={endDate}
            isLoading={isLoading}
            onNewGuestLinkClick={(meetingId, friendlyName, email) =>
              onNewGuestLinkClick(
                roomId,
                meetingId,
                friendlyName,
                email
              )
            }
            onDeleteGuestLinkClick={(meetingId, code) =>
              onDeleteGuestLinkClick(roomId, meetingId, code)
            }
          />
        </VStack>

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          gap={2}
          marginTop={4}
        >
          <Button
            backgroundColor="buttonPrimary"
            color="white"
            onClick={() => onEditClick(roomId)}
          >
            Edit Room
          </Button>

          <Button
            backgroundColor="buttonDeny"
            color="white"
            onClick={() => onDeleteClick(modMeetingId)}
          >
            Delete Room
          </Button>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}

const RoomListItem = memo(_RoomListItem)

interface RoomListProps {
  isLoading: boolean
  isLoadingGuestLink: boolean
  rooms: { [key: string]: RoomDataModel }
  onRefreshClick: () => void
  onNewGuestLinkClick: (
    roomId: string,
    meetingId: string,
    friendlyName: string,
    emailAddress?: string
  ) => void
  onDeleteGuestLinkClick: (
    roomId: string,
    meetingId: string,
    code: string
  ) => void
  onEditClick: (roomId: string) => void
  onDeleteClick: (meetingId: string) => void
}

export const RoomList = ({
  isLoading,
  isLoadingGuestLink,
  rooms,
  onRefreshClick,
  onNewGuestLinkClick,
  onDeleteGuestLinkClick,
  onEditClick,
  onDeleteClick,
}: RoomListProps) => {
  if (isLoading) {
    return (
      <Center height="200px">
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex width="100%" justifyContent="space-between">
        <Heading
          as="h2"
          size="xl"
          fontWeight={800}
          textTransform="uppercase"
          userSelect="none"
          marginBottom={4}
        >
          Active Rooms
        </Heading>

        <ControlButton
          label="Refresh"
          aria-label="Refresh"
          marginBottom={4}
          onClick={onRefreshClick}
        >
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize="sm"
          >
            refresh
          </ControlButtonText>
        </ControlButton>
      </Flex>

      {Object.keys(rooms).length === 0 ? (
        <Text marginY={8}>No active rooms</Text>
      ) : (
        <Accordion allowMultiple defaultIndex={[]} width="100%">
          {Object.keys(rooms).map((roomId) => (
            <RoomListItem
              key={roomId}
              roomId={roomId}
              title={
                rooms[roomId].roomConfig?.title ||
                'VF Meet Room'
              }
              startDate={rooms[roomId].roomConfig?.start}
              endDate={rooms[roomId].roomConfig?.end}
              roles={rooms[roomId].roomRoles}
              isLive={rooms[roomId].isLive}
              isLoading={isLoadingGuestLink}
              onNewGuestLinkClick={onNewGuestLinkClick}
              onDeleteGuestLinkClick={onDeleteGuestLinkClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </Accordion>
      )}
    </Flex>
  )
}