import {
  HMSPeer,
  selectDominantSpeaker,
  useHMSStore,
  useVideoList,
} from '@100mslive/react-sdk'
import { ChakraProps, Flex, Text } from '@chakra-ui/react'
import { VideoTile } from '..'
import { useMemo, useRef } from 'react'
import { useAppSelector } from '../../store'

interface PeerGridProps {
  peers: HMSPeer[]
  small?: boolean
  containerStyle?: ChakraProps
}

export const PeerGrid = ({
  peers,
  small = false,
  containerStyle,
}: PeerGridProps) => {
  const { peerGridMaxTiles } = useAppSelector((state) => state.app)

  const dominantSpeaker = useHMSStore(selectDominantSpeaker)

  const latestDominantSpeakerRef = useRef(dominantSpeaker)
  if (dominantSpeaker) {
    latestDominantSpeakerRef.current = dominantSpeaker
  }

  // If latest dominant speaker is no longer included in peer list, default
  // latest dominant speaker to local peer / first peer in list
  if (
    latestDominantSpeakerRef.current &&
    peers
      .map((peer) => peer.id)
      .indexOf(latestDominantSpeakerRef.current.id) === -1
  ) {
    latestDominantSpeakerRef.current = peers[0]
  }

  const visiblePeers = useMemo(() => {
    if (
      peers.length > peerGridMaxTiles &&
      latestDominantSpeakerRef.current
    ) {
      const filteredPeers = peers.filter(
        (peer) => peer.id !== latestDominantSpeakerRef.current?.id
      )

      return [
        ...filteredPeers.slice(0, peerGridMaxTiles - 1),
        latestDominantSpeakerRef.current,
      ]
    }

    return peers
  }, [peers, peerGridMaxTiles])

  const { ref, pagesWithTiles } = useVideoList({
    peers: visiblePeers,
    maxTileCount: peerGridMaxTiles,
    aspectRatio: { height: 3, width: 4 },
  })

  return (
    <Flex
      ref={ref}
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      alignContent="center"
      height="100%"
      width="100%"
      {...containerStyle}
    >
      {pagesWithTiles.length > 0
        ? pagesWithTiles[0].map(
          ({ track, peer, height, width }, index) => {
            if (
              peers.length <= peerGridMaxTiles ||
              index < peerGridMaxTiles - 1
            ) {
              return (
                <VideoTile
                  key={track?.id ? track.id : peer.id}
                  peerId={peer.id}
                  trackId={track?.id}
                  height={height}
                  width={width}
                  small={small}
                  containerStyle={{ padding: 1 }}
                />
              )
            }

            return (
              <Flex height={height} width={width}>
                <Flex
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="backgroundSecondary"
                  margin={1}
                  borderRadius={8}
                >
                  <Text
                    color="white"
                    textShadow="2px 2px 8px black"
                    fontWeight={600}
                    fontSize={['8px', null, null, '12px']}
                  >
                    {`+${peers.length - peerGridMaxTiles
                      } more`}
                  </Text>
                </Flex>
              </Flex>
            )
          }
        )
        : null}
    </Flex>
  )
}