// import { useEffect } from 'react'
import {
  selectIsSomeoneScreenSharing,
  selectLocalPeer,
  // selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeerByID,
  selectPeersByRole,
  // selectPeersByRoles,
  selectPeerScreenSharing,
  // useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Box, Flex, Center, Text, Heading } from '@chakra-ui/react'
import {
  HLSView,
  Card,
  PeerGrid,
  ScreenShareView,
  ShowActiveView,
  VideoTile,
} from '../../components'
import { Role } from '../../models'
import { useAppSelector } from '../../store'

const MainStageView = () => {
  const { pinnedPeerId, displayLayout } = useAppSelector((state) => state.app)

  const stageModeratorPeers = useHMSStore(
    selectPeersByRole(Role.StageModerator)
  )
  const stageMainPeers = useHMSStore(selectPeersByRole(Role.StageMain))
  const stageViewerPeers = useHMSStore(selectPeersByRole(Role.StageViewer))
  const pinnedPeer = useHMSStore(selectPeerByID(pinnedPeerId))
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing)
  const presenter = useHMSStore(selectPeerScreenSharing)
  const localPeer = useHMSStore(selectLocalPeer)

  const isHLSViewer = localPeer && localPeer.roleName === Role.HLSViewer

  const allPeers = [
    ...stageModeratorPeers,
    ...stageMainPeers,
    ...stageViewerPeers,
  ]

  if (isHLSViewer) {
    return (
      <Box height="100%" width="100%">
        <HLSView />
      </Box>
    )
  }

  if (isSomeoneScreenSharing && presenter) {
    return (
      <Flex height="100%" width="100%">
        <ScreenShareView presenter={presenter} peers={allPeers} />
      </Flex>
    )
  }

  if (allPeers.length === 1) {
    return (
      <Box height="100%" width="100%">
        <VideoTile peerId={allPeers[0].id} />
      </Box>
    )
  }

  if (allPeers.length > 1 && pinnedPeer) {
    return (
      <Box height="100%" width="100%" padding={[2, null, null, 0]}>
        <ShowActiveView pinnedPeer={pinnedPeer} peers={allPeers} />
      </Box>
    )
  }

  if (allPeers.length > 1 && displayLayout === 'show active') {
    return (
      <Box height="100%" width="100%" padding={[2, null, null, 0]}>
        <ShowActiveView peers={allPeers} />
      </Box>
    )
  }

  if (allPeers.length > 1) {
    return (
      <Box height="100%" width="100%">
        <PeerGrid peers={allPeers} />
      </Box>
    )
  }

  return (
    <Center height="100%" width="100%">
      <Text fontSize={['lg', null, '2xl']} color="textSecondary">
        Meeting starting soon...
      </Text>
    </Center>
  )
}

const BackstageView = () => {
  const moderators = useHMSStore(selectPeersByRole(Role.Moderator))
  const backstageViewers = useHMSStore(
    selectPeersByRole(Role.BackstageViewer)
  )

  return (
    <Card
      height={['25%', null, null, '100%']}
      width={['100%', null, null, '25%']}
      maxWidth={['unset', null, null, '28rem']}
      marginLeft={[0, null, null, '32px']}
      borderRadius={[0, null, 20]}
    >
      <Heading
        display={['none', null, null, 'block']}
        fontSize="lg"
        color="textPrimary"
        marginLeft={4}
        marginTop={2}
      >
        Backstage
      </Heading>

      <Box
        height={['100%', null, null, 'calc(100% - 38px)']}
        width="100%"
        padding={[0, null, null, 2]}
      >
        <PeerGrid
          peers={[...moderators, ...backstageViewers]}
          containerStyle={{ alignContent: 'flex-start' }}
          small
        />
      </Box>
    </Card>
  )
}

export const StageLayout = () => {
  const { showChatDrawer, showParticipantDrawer } = useAppSelector(
    (state) => state.app
  )

  // const hmsActions = useHMSActions()

  // const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)
  // const stagePeers = useHMSStore(
  //     selectPeersByRoles([
  //         Role.StageModerator,
  //         Role.StageMain,
  //         Role.StageViewer,
  //     ])
  // )

  const isBackstage =
    localPeerRoleName === Role.BackstageViewer ||
    localPeerRoleName === Role.Moderator

  /**
   * TODO: Add this back in when 100ms fixes issue
   */

  // useEffect(() => {
  //     stagePeers.forEach((peer) => {
  //         if (peer.audioTrack && peer.id !== localPeerId) {
  //             hmsActions.setVolume(isBackstage ? 10 : 100, peer.audioTrack)
  //         }
  //     })
  // }, [stagePeers, localPeerId, hmsActions, isBackstage])

  return (
    <Flex
      flexDirection={['column-reverse', null, null, 'row']}
      height="100%"
      width={[
        '100%',
        null,
        null,
        showChatDrawer || showParticipantDrawer
          ? 'calc(100% - 30rem)'
          : '100%',
      ]}
      padding={[0, null, null, 4]}
    >
      <Flex flex={1} height="100%" flexDirection="column">
        <MainStageView />
      </Flex>

      {isBackstage ? <BackstageView /> : null}
    </Flex>
  )
}