import {
    chakra,
    forwardRef,
    IconButton,
    IconButtonProps,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import React from 'react'

const ControlButtonBase = chakra(IconButton, {
    baseStyle: {
        backgroundColor: 'buttonPrimary',
        height: '40px',
        width: '40px',
        minWidth: '30px',
        borderRadius: 'full',
        _hover: {
            backgroundColor: 'buttonPrimaryHover',
        },
    },
})

interface ControlButtonProps extends IconButtonProps {
    label?: string
}

export const ControlButton = forwardRef(
    (
        {
            label,
            children,
            ...props
        }: React.PropsWithChildren<ControlButtonProps>,
        ref
    ) => {
        if (label) {
            return (
                <Tooltip
                    label={label}
                    backgroundColor="black"
                    color="white"
                    userSelect="none"
                >
                    <ControlButtonBase ref={ref} {...props}>
                        {children}
                    </ControlButtonBase>
                </Tooltip>
            )
        }

        return (
            <ControlButtonBase ref={ref} {...props}>
                {children}
            </ControlButtonBase>
        )
    }
)

export const ControlButtonText = chakra(Text, {
    baseStyle: {
        color: 'white',
        fontSize: 20,
        fontWeight: 500,
    },
})