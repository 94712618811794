import { useNavigate, useParams } from 'react-router-dom'
import {
  Flex,
  Heading,
  Image,
  Button,
  Container,
  VStack,
} from '@chakra-ui/react'
import { Footer } from '../../components'
import logo from '../../assets/images/logo-color.svg'

export const LeavePage = () => {
  const navigate = useNavigate()
  const { meetingId } = useParams()

  const onClick = () => navigate(`/${meetingId}/preview`)

  const onHomeClick = () => navigate('/')

  return (
    <Flex
      flexDirection="column"
      height="100%"
      width="100%"
      backgroundColor="backgroundPrimary"
    >
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="calc(100% - 7rem)"
        userSelect="none"
      >
        <Image src={logo} width="50%" height="auto" marginBottom={12} />

        <VStack spacing={4}>
          <Heading
            size="2xl"
            fontWeight={800}
            color="textPrimary"
            textTransform="uppercase"
            textAlign="center"
          >
            Thank you for attending!
          </Heading>

          <Button
            backgroundColor="buttonPrimary"
            color="white"
            width="150px"
            onClick={onClick}
          >
            Rejoin Meeting
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            width="150px"
            onClick={onHomeClick}
          >
            Home
          </Button>
        </VStack>
      </Container>

      <Footer />
    </Flex>
  )
}