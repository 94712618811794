import { useState, memo } from 'react'
import {
  selectLocalPeerID,
  selectPeerByID,
  selectPeerMetadata,
  selectPermissions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Center, Avatar, Text } from '@chakra-ui/react'
import { TileOverlay } from './TileOverlay'
import { AudienceControl } from '../control'
import { CustomMetadata } from '../../models'
import { ControlButtonText } from '../button'

interface AudienceTileProps {
  peerId: string
}

const _AudienceTile = ({ peerId }: AudienceTileProps) => {
  const [isControlVisible, setIsControlVisible] = useState(false)
  const [isControlActive, setIsControlActive] = useState(false)

  const peer = useHMSStore(selectPeerByID(peerId))
  const metadata: CustomMetadata = useHMSStore(
    selectPeerMetadata(peer ? peer.id : '')
  )
  const localPeerId = useHMSStore(selectLocalPeerID)
  const permissions = useHMSStore(selectPermissions)

  const isLocal = peer !== null && peer.id === localPeerId
  const showAdminControls =
    permissions?.removeOthers && permissions.mute && permissions.changeRole

  const onMouseEnter = () => setIsControlVisible(true)
  const onMouseLeave = () => setIsControlVisible(false)

  const onOpen = () => setIsControlActive(true)
  const onClose = () => {
    setIsControlActive(false)
    setIsControlVisible(false)
  }

  if (!peer) {
    return null
  }

  return (
    <Center
      position="relative"
      flexDirection="column"
      height="80px"
      width="100px"
      onMouseEnter={showAdminControls ? onMouseEnter : undefined}
      onMouseLeave={
        showAdminControls && !isControlActive ? onMouseLeave : undefined
      }
    >
      <Avatar
        name={peer.name}
        src={metadata.avatarUrl}
        size={['xs', null, null, null, 'sm']}
        backgroundColor={
          metadata.avatarUrl ? 'black' : 'headingPrimary'
        }
        color="white"
      />

      <Text
        noOfLines={1}
        maxWidth="100%"
        fontSize={['3xs', null, null, null, null, '2xs']}
        color="textPrimary"
        marginTop={2}
        marginX={2}
      >
        {isLocal ? 'You' : peer.name}
      </Text>

      {metadata.isHandRaised ? (
        <TileOverlay placement="top-right">
          <ControlButtonText
            className="material-symbols-outlined"
            fontSize={16}
          >
            pan_tool
          </ControlButtonText>
        </TileOverlay>
      ) : null}

      {showAdminControls && !isLocal ? (
        <AudienceControl
          peerId={peer.id}
          isVisible={isControlVisible}
          onOpen={onOpen}
          onClose={onClose}
        />
      ) : null}
    </Center>
  )
}

export const AudienceTile = memo(_AudienceTile)