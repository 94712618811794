import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { BotType, Role } from '../models'
import {
  MOD_FUNC_LOWER_ALL_HANDS,
  MOD_FUNC_PIN_CHAT_MESSAGE,
  FRIENDLY_LOWER_ALL_HANDS,
  GUEST_LINK_DURATION,
} from '../constants'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const formatRole = (role?: string) => {
  switch (role) {
    case Role.Moderator:
      return 'Backstage Moderator'
    case Role.StageModerator:
      return 'Stage Moderator'
    case Role.BackstageViewer:
      return 'Backstage'
    case Role.StageMain:
      return 'Stage Main'
    case Role.StageViewer:
      return 'Stage Speaker'
    case Role.Viewer:
      return 'Audience'
    case Role.HLSViewer:
      return 'HLS Audience'
    case Role.ChatTimeOut:
      return 'Muted Audience'
    case Role.Host:
      return 'Host'
    case Role.Guest:
      return 'Guest'
    case Role.GuestHost:
      return 'Guest Host'
    default:
      return ''
  }
}

export const formatUnreadCount = (count: number) => {
  if (count > 9) {
    return '9+'
  }

  return `${count}`
}

export const formatMessage = (message: string) => {
  let formattedMessage = message

  if (formattedMessage === MOD_FUNC_LOWER_ALL_HANDS) {
    return FRIENDLY_LOWER_ALL_HANDS
  }

  if (formattedMessage.startsWith(MOD_FUNC_PIN_CHAT_MESSAGE)) {
    formattedMessage = formattedMessage.replace(
      MOD_FUNC_PIN_CHAT_MESSAGE,
      ''
    )
  }

  return formattedMessage
}

export const formatGuestLinkExpiration = (
  timestamp?: number | null,
  endDate?: string | null
) => {
  if (!timestamp) return 'expired'

  let expiration = timestamp + GUEST_LINK_DURATION
  if (endDate) {
    expiration = dayjs(endDate).unix() + GUEST_LINK_DURATION
  }

  const remaining = expiration - dayjs().unix()

  if (remaining < 0) return 'expired'

  // @ts-ignore
  return dayjs().to(dayjs.unix(expiration))
}

export interface PeerProps {
  moderator?: boolean
  guest?: boolean
  s1Holder?: boolean
  s1SpecHolder?: boolean
  s1AccessHolder?: boolean
  s1GgHolder?: boolean
  s2Holder?: boolean
}

export const formatPeerBadges = ({
  moderator = false,
  guest = false,
  s1SpecHolder = false,
  s1GgHolder = false,
  s1AccessHolder = false,
}: PeerProps) => {
  let badges = ''

  if (moderator) {
    badges += '👑 '
  }

  if (guest) {
    badges += '🎤 '
  }

  if (s1GgHolder) {
    badges += '🐐 '
  }

  if (s1SpecHolder) {
    badges += '💎 '
  }

  if (s1AccessHolder) {
    badges += '✨ '
  }

  if (badges !== '') {
    return (
      <Text as="span" fontSize="16px" marginRight={1}>
        {badges}
      </Text>
    )
  }

  return null
}

export const formatBotName = (botType?: BotType) => {
  switch (botType) {
    case 'hls':
      return 'HLS Bot'
    case 'recording':
      return 'Recording Bot'
    case 'rtmp':
    case 'rtmpUnbranded':
      return 'Streaming Bot'
    default:
      return 'Bot'
  }
}