import { useHMSActions } from '@100mslive/react-sdk'
import {
  Flex,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { ControlButton, ControlButtonText } from '../button'

interface ChatControlProps {
  peerId?: string
  isVisible: boolean
  onRemoveFromChatClick: () => void
  onOpen: () => void
  onClose: () => void
}

export const ChatControl = ({
  peerId,
  isVisible,
  onRemoveFromChatClick,
  onOpen,
  onClose,
}: ChatControlProps) => {
  const hmsActions = useHMSActions()

  const onRemoveFromMeetingClick = () => {
    if (!peerId) return

    try {
      hmsActions.removePeer(peerId, 'Goodbye!')
    } catch (error) {
      console.error(error)
    }
  }

  if (isVisible) {
    return (
      <Popover onOpen={onOpen} onClose={onClose}>
        <PopoverAnchor>
          <Flex
            position="absolute"
            top={0}
            bottom={0}
            alignItems="center"
            backgroundColor="rgba(0, 0, 0, 0.9)"
            borderRadius={20}
          >
            <PopoverTrigger>
              <ControlButton
                height="40px"
                width="40px"
                label="Options"
                aria-label="Options"
              >
                <ControlButtonText className="material-symbols-outlined">
                  settings
                </ControlButtonText>
              </ControlButton>
            </PopoverTrigger>
          </Flex>
        </PopoverAnchor>

        <Portal>
          <PopoverContent
            width="auto"
            backgroundColor="black"
            border="none"
            borderRadius="full"
          >
            <PopoverBody>
              <HStack>
                <ControlButton
                  height="40px"
                  width="40px"
                  label="Remove from chat"
                  aria-label="Remove from chat"
                  onClick={onRemoveFromChatClick}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    speaker_notes_off
                  </ControlButtonText>
                </ControlButton>

                <ControlButton
                  height="40px"
                  width="40px"
                  label="Remove from meeting"
                  aria-label="Remove from meeting"
                  backgroundColor="buttonDeny"
                  onClick={onRemoveFromMeetingClick}
                >
                  <ControlButtonText className="material-symbols-outlined">
                    gavel
                  </ControlButtonText>
                </ControlButton>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    )
  }

  return null
}