import { HMSNotificationTypes } from '@100mslive/react-sdk'

export const NOTIFICATION_TYPES = [
  HMSNotificationTypes.PEER_JOINED,
  HMSNotificationTypes.PEER_LEFT,
  HMSNotificationTypes.METADATA_UPDATED,
  HMSNotificationTypes.ROLE_UPDATED,
  HMSNotificationTypes.NEW_MESSAGE,
  HMSNotificationTypes.RECONNECTING,
  HMSNotificationTypes.RECONNECTED,
]

export type BotType = 'hls' | 'recording' | 'rtmp' | 'rtmpUnbranded'

export type DisplayMode = 'standard' | 'stage' | 'private-stage'

export enum Role {
  // Standard roles
  Guest = 'guest',
  GuestHost = 'guest-host',
  Host = 'host',

  // Stage roles
  StageModerator = 'stage-moderator',
  BackstageViewer = 'backstage-viewer',
  StageMain = 'stage-main',
  StageViewer = 'stage-viewer',
  Viewer = 'viewer',
  HLSViewer = 'hls-viewer',
  ChatTimeOut = 'chat-time-out',

  // Shared roles
  Moderator = 'moderator',
  Bot = 'stage-bot',
}

export const STAGE_ROLES: Role[] = [
  Role.StageModerator,
  Role.StageMain,
  Role.StageViewer,
  Role.Moderator,
  Role.BackstageViewer,
  Role.Viewer,
  Role.HLSViewer,
]

export const STANDARD_ROLES: Role[] = [
  Role.Moderator,
  Role.Host,
  Role.GuestHost,
  Role.Guest,
]

export interface JWT_100ms {
  access_key: string
  room_id: string
  user_id: string
  role: Role
}

export interface NftData {
  s1c: string[]
  s2c: string[]
  s1h: boolean
  s1spec: boolean
  s1a: boolean
  s1gg: boolean
  s2h: boolean
}

export interface CustomMetadata {
  avatarUrl: string
  participantSid: string
  nfts: string
  isHandRaised: boolean
  isGuestSpeaker: boolean
}

export const INITIAL_CUSTOM_METADATA: CustomMetadata = {
  avatarUrl: '',
  participantSid: '',
  nfts: '',
  isHandRaised: false,
  isGuestSpeaker: false,
}

export interface HLSQualityLevel {
  bitrate: number
  height: number | string
  width: number
  name: string
  url: string
  attrs: { [key: string]: string }
}