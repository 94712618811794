import { memo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid, GridChildComponentProps } from 'react-window'
import { HMSPeer } from '@100mslive/react-sdk'
import { AudienceTile } from '../tile'

const COLUMN_BREAKPOINT = 400

const _ParticipantGridItem = ({
  columnIndex,
  rowIndex,
  style,
  data,
}: GridChildComponentProps) => {
  const index = data.columnCount * rowIndex + columnIndex

  if (!data.peers[index]) {
    return null
  }

  return (
    <div key={data.peers[index].id} style={style}>
      <AudienceTile peerId={data.peers[index].id} />
    </div>
  )
}

const ParticipantGridItem = memo(_ParticipantGridItem)

interface ParticipantGridProps {
  peers: HMSPeer[]
  containerStyle?: React.CSSProperties
}

const _ParticipantGrid = ({ peers, containerStyle }: ParticipantGridProps) => (
  <AutoSizer>
    {({ height, width }: any) => {
      let columnCount = 4
      if (width < COLUMN_BREAKPOINT) {
        columnCount = 3
      }

      return (
        <FixedSizeGrid
          height={height}
          width={width}
          columnCount={columnCount}
          columnWidth={Math.floor(width / columnCount - 4)}
          rowCount={Math.ceil(peers.length / columnCount)}
          rowHeight={84}
          itemData={{ columnCount, peers }}
          style={containerStyle}
        >
          {ParticipantGridItem}
        </FixedSizeGrid>
      )
    }}
  </AutoSizer>
)

export const ParticipantGrid = memo(_ParticipantGrid)