import { memo, useEffect, useState, useCallback } from 'react'
import { HMSPeer } from '@100mslive/react-sdk'
import { Center, Flex, Text } from '@chakra-ui/react'
import { VideoTile } from '../tile'

const MOBILE_BREAKPOINT = 768
const MOBILE_TILE_LIMIT = 2
const DESKTOP_TILE_LIMIT = 5

interface PeerListProps {
  peers: HMSPeer[]
  additionalCount?: number
  stickyPeer?: HMSPeer
  onMouseEnterCallback?: () => void
  onMouseLeaveCallback?: () => void
}

const _PeerList = ({
  peers,
  stickyPeer,
  onMouseEnterCallback,
  onMouseLeaveCallback,
}: PeerListProps) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  )

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const tileLimit = isMobile ? MOBILE_TILE_LIMIT : DESKTOP_TILE_LIMIT

  let _peers = peers

  if (stickyPeer) {
    _peers = [
      stickyPeer,
      ...peers.filter((peer) => peer.id !== stickyPeer.id),
    ]
  }

  return (
    <Flex
      flexDirection={['row', null, 'column']}
      justifyContent={['center', null, 'flex-start']}
      flexWrap="wrap"
      gap={2}
      height="100%"
      width="100%"
    >
      {_peers.slice(0, tileLimit).map((peer) => (
        <VideoTile
          key={peer.videoTrack}
          peerId={peer.id}
          trackId={peer.videoTrack}
          containerStyle={{
            height: ['90px', null, null, null, '132px'],
            width: ['120px', null, null, null, '176px'],
          }}
          small
          onMouseEnterCallback={onMouseEnterCallback}
          onMouseLeaveCallback={onMouseLeaveCallback}
        />
      ))}
      {peers.length > tileLimit && (
        <Center
          key="titleLimit"
          height={['90px', null, null, null, '132px']}
          width={['120px', null, null, null, '176px']}
          borderRadius={8}
          backgroundColor="backgroundSecondary"
        >
          <Text
            color="textPrimary"
            fontSize={['xs', null, null, 'md']}
            fontWeight={600}
            textAlign="center"
          >
            {`+${peers.length - tileLimit} more`}
          </Text>
        </Center>
      )}
    </Flex>
  )
}

export const PeerList = memo(_PeerList)