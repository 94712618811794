export const COLOR_RED = '#FF4343'
export const COLOR_GREEN = '#00AD83'
export const COLOR_GRAY = '#AFAFAF'
export const COLOR_YELLOW = '#FFC107'
export const COLOR_BLUE = '#1DA1F2'

export const backgroundPrimary = '#202124'
export const backgroundPrimaryDark = 'rgba(0, 0, 0, 0.2)'
export const backgroundPrimaryExtraDark = 'rgba(0, 0, 0, 0.4)'
export const backgroundPrimaryExtraExtraDark = 'rgba(0, 0, 0, 0.6)'
export const backgroundPrimaryLight = 'rgba(255, 255, 255, 0.1)'
export const backgroundSecondary = '#101010'
export const backgroundOverlay = 'rgba(0, 0, 0, 0.5)'

export const textPrimary = 'white'
export const textPrimaryDark = 'black'
export const textSecondary = COLOR_GRAY

export const buttonPrimary = 'rgba(255, 255, 255, 0.1)'
export const buttonPrimaryHover = 'rgba(255, 255, 255, 0.2)'
export const buttonConfirm = COLOR_GREEN
export const buttonDeny = COLOR_RED

export const headingPrimary = COLOR_GREEN

export const statusSuccess = COLOR_GREEN
export const statusFailure = COLOR_RED
export const statusWarning = COLOR_YELLOW
export const statusInfo = COLOR_BLUE

export const colors = {
  backgroundPrimary,
  backgroundPrimaryDark,
  backgroundPrimaryExtraDark,
  backgroundPrimaryExtraExtraDark,
  backgroundPrimaryLight,
  backgroundSecondary,
  backgroundOverlay,
  textPrimary,
  textPrimaryDark,
  textSecondary,
  buttonPrimary,
  buttonPrimaryHover,
  buttonConfirm,
  buttonDeny,
  headingPrimary,
  statusSuccess,
  statusFailure,
  statusWarning,
  statusInfo,
}