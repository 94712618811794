// @ts-ignore
import zlib from 'react-zlib-js'
import { Buffer } from 'buffer'
import { useState, memo, useMemo } from 'react'
import { JSONObject } from '@twilio/conversations'
import {
  selectPeerByID,
  selectPeerMetadata,
  selectPermissions,
  useHMSStore,
} from '@100mslive/react-sdk'
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Link,
  Text,
  Image,
} from '@chakra-ui/react'
import Linkify from 'linkify-react'
import * as linkify from 'linkifyjs'
import { ChatControl, MessageControl } from '../control'
import { useAppSelector } from '../../store'
import { PeerProps, formatPeerBadges } from '../../utils'
import { CustomMetadata, NftData } from '../../models'

const socialIconMap: Record<string, string> = {
  Restream:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/restream-icon',
  Twitch: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/twitch-icon',
  YouTube:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/youtube-icon',
  Facebook_Profile:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/facebook-icon',
  Facebook_Page:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/facebook-icon',
  Facebook_Group:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/facebook-icon',
  DLive: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/restream-icon',
  Discord:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/discord-icon',
  LinkedIn:
    'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/linkedin-icon',
  Trovo: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/restream-icon',
  Twitter: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/twitter-icon',
  Instagram: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/instagram-icon',
  TikTok: 'https://media.veefriends.com/image/upload/c_scale,f_auto,h_32,q_auto/v1667582762/veefriends/specials/www/socials/tiktok-icon'
}

const renderLink = ({
  attributes,
  content,
}: linkify.IntermediateRepresentation) => {
  const { href, ...props } = attributes

  return (
    <Link href={href} {...props} rel="noopener noreferrer" target="_blank">
      {content}
    </Link>
  )
}

export interface MessageBubbleProps {
  participantSid: string | null
  body: string | null
  attributes: JSONObject
  didSenderChange: boolean
  showDeleteControl: boolean
  onRemoveMessage: () => void
  onRemoveUserFromChat: () => void
}

const _MessageBubble = ({
  participantSid,
  body,
  attributes,
  didSenderChange,
  showDeleteControl,
  onRemoveMessage,
  onRemoveUserFromChat,
}: MessageBubbleProps) => {
  const { twilioConfig } = useAppSelector((state) => state.config)

  const [isControlVisible, setIsControlVisible] = useState(false)
  const [isControlActive, setIsControlActive] = useState(false)
  const [isMessageControlVisible, setIsMessageControlVisible] =
    useState(false)

  const peerId = attributes['peerId'] as string
  const displayName = attributes['displayName'] as string
  const avatarUrl = attributes['avatarUrl'] as string
  const isExternal = attributes['isExternal']
    ? attributes['isExternal']
    : false
  const network = isExternal ? (attributes['network'] as string) : ''

  const permissions = useHMSStore(selectPermissions)
  const peer = useHMSStore(selectPeerByID(peerId))
  const metadata: CustomMetadata = useHMSStore(selectPeerMetadata(peerId))

  const isMe = twilioConfig?.participantSid === participantSid

  const showAdminControls =
    permissions?.removeOthers && permissions.mute && permissions.changeRole

  const linkData = body && linkify.find(body)

  const badgeConfig: PeerProps = useMemo(() => {
    const partial: PeerProps = {
      moderator: peer?.roleName?.toLowerCase().includes('moderator'),
      guest: metadata.isGuestSpeaker,
    }

    try {
      if (!metadata.nfts) {
        return partial
      }

      const data = JSON.parse(
        zlib.gunzipSync(Buffer.from(metadata.nfts, 'base64')).toString()
      ) as NftData

      return {
        ...partial,
        s1SpecHolder: data.s1spec,
        s1GgHolder: data.s1gg,
        s1AccessHolder: data.s1a,
      }
    } catch (error) {
      console.error(error)
      console.log('error unzipping: ', metadata)

      return partial
    }
  }, [metadata, peer])

  const onMouseEnter = () => setIsControlVisible(true)

  const onMouseLeave = () => setIsControlVisible(false)

  const onMessageMouseEnter = () => setIsMessageControlVisible(true)

  const onMessageMouseLeave = () => setIsMessageControlVisible(false)

  const onOpen = () => setIsControlActive(true)

  const onClose = () => {
    setIsControlActive(false)
    setIsControlVisible(false)
  }

  return (
    <Flex
      flexDirection="column"
      alignItems={isMe ? 'flex-end' : 'flex-start'}
      paddingBottom={1}
    >
      {didSenderChange ? (
        <Flex
          position="relative"
          flexDirection={isMe ? 'row-reverse' : 'row'}
          alignItems="center"
          marginTop={4}
          marginBottom={2}
          {...(isMe ? { paddingLeft: 3 } : { paddingRight: 3 })}
          onMouseEnter={
            showAdminControls && !isMe ? onMouseEnter : undefined
          }
          onMouseLeave={
            showAdminControls && !isControlActive && !isMe
              ? onMouseLeave
              : undefined
          }
        >
          <Avatar
            name={displayName}
            src={avatarUrl}
            {...(showAdminControls
              ? { height: '40px', width: '40px' }
              : { size: 'xs' })}
            {...(isMe ? { marginLeft: 2 } : { marginRight: 2 })}
            userSelect="none"
          >
            {isExternal && (
              <AvatarBadge
                placement="top-end"
                boxSize="1.25em"
                bg="black"
                border="none"
              >
                <Image src={socialIconMap[network]} />
              </AvatarBadge>
            )}
          </Avatar>

          <Text fontSize={[10, null, 12]} fontWeight={500}>
            {formatPeerBadges(badgeConfig)}
            {displayName ? displayName : 'Anonymous'}
          </Text>

          {showAdminControls && !isMe ? (
            <ChatControl
              peerId={peerId}
              isVisible={isControlVisible}
              onRemoveFromChatClick={onRemoveUserFromChat}
              onOpen={onOpen}
              onClose={onClose}
            />
          ) : null}
        </Flex>
      ) : null}

      <Box
        position="relative"
        maxWidth="100%"
        backgroundColor={
          isMe ? 'buttonConfirm' : 'backgroundPrimaryLight'
        }
        paddingY={1}
        paddingX={2}
        borderRadius={8}
        borderTopRightRadius={isMe ? 0 : 8}
        borderTopLeftRadius={isMe ? 8 : 0}
        overflow="hidden"
        onMouseEnter={
          showDeleteControl && showAdminControls
            ? onMessageMouseEnter
            : undefined
        }
        onMouseLeave={
          showDeleteControl && showAdminControls
            ? onMessageMouseLeave
            : undefined
        }
      >
        <Linkify options={{ render: renderLink }}>
          <Text fontSize={[12, null, 14]} color="white">
            {body}
          </Text>
        </Linkify>

        {linkData && linkData.length > 0 ? (
          <Text fontSize="xs" fontWeight={300}>
            Please use caution when visiting links from untrusted
            sources.
          </Text>
        ) : null}

        {showDeleteControl && showAdminControls ? (
          <MessageControl
            isVisible={isMessageControlVisible}
            onDeleteClick={onRemoveMessage}
          />
        ) : null}
      </Box>
    </Flex>
  )
}

export const MessageBubble = memo(_MessageBubble)