import { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  useDevices,
  useHMSStore,
  selectLocalVideoTrackID,
  selectIsLocalVideoEnabled,
  DeviceType,
  useHMSActions,
  selectIsLocalAudioPluginPresent,
} from '@100mslive/react-sdk'
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  FormLabel,
  Select,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import {
  useAppDispatch,
  useAppSelector,
  setShowSettingsModal,
  setMirrorVideo,
  setPeerGridMaxTiles,
} from '../../store'
import { VideoFeed } from '../tile'
import { HMSNoiseSuppressionPlugin } from '@100mslive/hms-noise-suppression'

const _SettingsModal = () => {
  const dispatch = useAppDispatch()

  const { showSettingsModal, mirrorVideo, peerGridMaxTiles } = useAppSelector(
    (state) => state.app
  )

  const trackId = useHMSStore(selectLocalVideoTrackID)
  const isVideoEnabled = useHMSStore(selectIsLocalVideoEnabled)
  const isPluginPresent = useHMSStore(
    selectIsLocalAudioPluginPresent('@100mslive/hms-noise-suppression')
  )
  const hmsActions = useHMSActions()

  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices()
  const { videoInput, audioInput, audioOutput } = allDevices

  const [isNSSupported, setIsNSSupported] = useState(false)

  const pluginRef = useRef<HMSNoiseSuppressionPlugin | null>(null)

  const createPlugin = useCallback(async () => {
    if (!pluginRef.current) {
      const { HMSNoiseSuppressionPlugin } = await import(
        '@100mslive/hms-noise-suppression'
      )
      pluginRef.current = new HMSNoiseSuppressionPlugin()
    }
  }, [])

  const addPlugin = useCallback(async () => {
    try {
      if (pluginRef.current) {
        await hmsActions.addPluginToAudioTrack(pluginRef.current)
      }
    } catch (error) {
      console.error(error)
    }
  }, [hmsActions])

  const removePlugin = useCallback(async () => {
    try {
      if (pluginRef.current) {
        await hmsActions.removePluginFromAudioTrack(pluginRef.current)
      }
    } catch (error) {
      console.error(error)
    }
  }, [hmsActions])

  const onMirrorVideoChange = (value: boolean) => {
    dispatch(setMirrorVideo(value))
  }

  const onMaxTilesChange = (_: string, value: number) => {
    dispatch(setPeerGridMaxTiles(value))
  }

  useEffect(() => {
    ; (async () => {
      if (!pluginRef.current) {
        await createPlugin()
      }

      const pluginSupport = hmsActions.validateAudioPluginSupport(
        pluginRef.current!
      )
      setIsNSSupported(pluginSupport.isSupported)
    })()
  }, [selectedDeviceIDs.audioInput, hmsActions, createPlugin])

  return (
    <Modal
      isOpen={showSettingsModal}
      size="xl"
      onClose={() => dispatch(setShowSettingsModal(false))}
    >
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>Settings</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody>
          {videoInput?.length ? (
            <Flex flexDirection="column">
              {isVideoEnabled ? (
                <VideoFeed
                  mirrorVideo={mirrorVideo}
                  trackId={trackId}
                  containerStyle={{
                    width: '75%',
                    alignSelf: 'center',
                    marginBottom: 4,
                  }}
                />
              ) : null}

              <FormLabel color="textSecondary">Camera</FormLabel>
              <Select
                defaultValue={selectedDeviceIDs.videoInput}
                onChange={({ currentTarget }) =>
                  updateDevice({
                    deviceType: DeviceType.videoInput,
                    deviceId: currentTarget.value,
                  })
                }
              >
                {videoInput.map((input) => (
                  <option
                    key={input.deviceId}
                    value={input.deviceId}
                  >
                    {input.label}
                  </option>
                ))}
              </Select>

              <Flex justifyContent="flex-end" marginTop={4}>
                <FormLabel htmlFor="mirror-video-switch">
                  Mirror Video
                </FormLabel>
                <Switch
                  id="mirror-video-switch"
                  size="lg"
                  sx={{
                    'span.chakra-switch__track[data-checked]':
                    {
                      backgroundColor:
                        'buttonConfirm',
                    },
                  }}
                  isChecked={mirrorVideo}
                  onChange={({ currentTarget }) =>
                    onMirrorVideoChange(
                      currentTarget.checked
                    )
                  }
                />
              </Flex>
            </Flex>
          ) : null}

          {audioInput?.length ? (
            <>
              <FormLabel color="textSecondary">
                Microphone
              </FormLabel>
              <Select
                defaultValue={selectedDeviceIDs.audioInput}
                onChange={({ currentTarget }) =>
                  updateDevice({
                    deviceType: DeviceType.audioInput,
                    deviceId: currentTarget.value,
                  })
                }
                marginBottom={isNSSupported ? 4 : 8}
              >
                {audioInput.map((input) => (
                  <option
                    key={input.deviceId}
                    value={input.deviceId}
                  >
                    {input.label}
                  </option>
                ))}
              </Select>

              {isNSSupported ? (
                <Flex justifyContent="flex-end">
                  <FormLabel htmlFor="noise-suppression-switch">
                    Enable noise suppression
                  </FormLabel>
                  <Switch
                    id="noise-suppression-switch"
                    size="lg"
                    sx={{
                      'span.chakra-switch__track[data-checked]':
                      {
                        backgroundColor:
                          'buttonConfirm',
                      },
                    }}
                    isChecked={isPluginPresent}
                    onChange={({ currentTarget }) => {
                      if (currentTarget.checked) {
                        addPlugin()
                      } else {
                        removePlugin()
                      }
                    }}
                  />
                </Flex>
              ) : null}
            </>
          ) : null}

          {audioOutput?.length ? (
            <>
              <FormLabel color="textSecondary">
                Speakers
              </FormLabel>
              <Select
                defaultValue={selectedDeviceIDs.audioOutput}
                onChange={({ currentTarget }) =>
                  updateDevice({
                    deviceType: DeviceType.audioOutput,
                    deviceId: currentTarget.value,
                  })
                }
                marginBottom={8}
              >
                {audioOutput.map((input) => (
                  <option
                    key={input.deviceId}
                    value={input.deviceId}
                  >
                    {input.label}
                  </option>
                ))}
              </Select>
            </>
          ) : null}

          <FormLabel color="textSecondary">Max video tiles</FormLabel>
          <NumberInput
            defaultValue={peerGridMaxTiles}
            min={2}
            max={40}
            marginBottom={6}
            onChange={onMaxTilesChange}
            focusInputOnChange
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const SettingsModal = memo(_SettingsModal)