import { memo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  Flex,
  Select,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { Role } from '../../models'

const validationSchema = yup.object().shape({
  role: yup.string().required(),
})

interface ClearStageModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (role: Role) => void
}

const _ClearStageModal = ({
  isOpen,
  onClose,
  onSubmit,
}: ClearStageModalProps) => (
  <Modal isOpen={isOpen} isCentered size="xl" onClose={onClose}>
    <ModalOverlay backdropFilter="blur(32px)" />

    <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
      <ModalHeader>
        <Heading>Clear Stage</Heading>
      </ModalHeader>

      <ModalCloseButton size="2xl" top={6} right={6} />

      <ModalBody paddingBottom={4}>
        <Text fontSize="lg" marginBottom={2}>
          To which role should current stage members be reassigned?
        </Text>

        <Formik
          initialValues={{ role: '' }}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values.role as Role)
          }}
        >
          {({ isValid, setValues }) => (
            <Form>
              <Select
                borderRadius="full"
                onChange={({ currentTarget }) => {
                  setValues({
                    role: currentTarget.value,
                  })
                }}
              >
                <option value="">Select role</option>
                <option value={Role.HLSViewer}>
                  HLS Viewer
                </option>
                <option value={Role.Viewer}>Viewer</option>
              </Select>

              <Flex flexDirection="row" marginTop={6}>
                <Button
                  backgroundColor="buttonDeny"
                  color="white"
                  marginRight={4}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  backgroundColor="buttonConfirm"
                  color="white"
                  disabled={!isValid}
                >
                  Clear Stage
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export const ClearStageModal = memo(_ClearStageModal)