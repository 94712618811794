import { memo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  Flex,
  FormLabel,
  Switch,
  ModalFooter,
} from '@chakra-ui/react'

interface StartHlsModalProps {
  isOpen: boolean
  onStartClick: (shouldRecord: boolean) => void
  onCloseClick: () => void
}

const _StartHlsModal = ({
  isOpen,
  onStartClick,
  onCloseClick,
}: StartHlsModalProps) => {
  const [shouldRecord, setShouldRecord] = useState(false)

  return (
    <Modal isOpen={isOpen} isCentered size="xl" onClose={onCloseClick}>
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>Start Livestream</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody paddingBottom={4}>
          <Text fontSize="lg" marginBottom={2}>
            Would you like to start the livestream?
          </Text>

          <Flex justifyContent="flex-start">
            <FormLabel
              htmlFor="should-record"
              color="textSecondary"
              fontSize="sm"
            >
              Also start recording
            </FormLabel>
            <Switch
              id="should-record"
              size="md"
              sx={{
                'span.chakra-switch__track[data-checked]': {
                  backgroundColor: 'buttonConfirm',
                },
              }}
              isChecked={shouldRecord}
              onChange={({ currentTarget }) =>
                setShouldRecord(currentTarget.checked)
              }
            />
          </Flex>
        </ModalBody>

        <ModalFooter gap={4}>
          <Button
            backgroundColor="buttonDeny"
            color="white"
            onClick={onCloseClick}
          >
            Cancel
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={() => onStartClick(shouldRecord)}
          >
            Start Livestream
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const StartHlsModal = memo(_StartHlsModal)