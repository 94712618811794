import { Heading, Text, Image, Container, Button, Link } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../components'
import { setToken, setWidgetbotToken, useAppDispatch } from '../../store'
import pig from '../../assets/images/pig.gif'

const PROFILE_URL = process.env.REACT_APP_PROFILE_URL

export const NotEligiblePage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  dispatch(setToken(''))
  dispatch(setWidgetbotToken(''))

  const onViewProfileClick = () =>
    PROFILE_URL && window.location.replace(`${PROFILE_URL}#wallets`)

  const onHomeClick = () => navigate('/')

  return (
    <Page
      accentColor="backgroundPrimary"
      containerStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        userSelect="none"
      >
        <Image
          height="250px"
          width="auto"
          objectFit="contain"
          src={pig}
          marginBottom={12}
        />

        <Heading
          size="2xl"
          fontWeight={800}
          color="textPrimary"
          textTransform="uppercase"
          textAlign="center"
          marginBottom={2}
        >
          Not eligible
        </Heading>

        <Text
          fontSize="lg"
          color="textSecondary"
          textAlign="center"
          marginBottom={2}
        >
          It looks like you do not meet the token ownership
          requirements to access this meeting.
        </Text>

        <Text fontSize="xs" color="textSecondary" textAlign="center">
          Please double check the meeting link and/or check to see if
          the correct wallet is connected to your VeeFriends account.
          If you believe you are seeing this message in error, please
          reach out to our support team at{' '}
          <Link
            href="mailto:support@veefriends.com"
            textColor="blue.400"
          >
            support@veefriends.com
          </Link>
          .
        </Text>

        <Button
          width="210px"
          color="white"
          marginTop={8}
          marginBottom={2}
          onClick={onViewProfileClick}
        >
          View VeeFriends Profile
        </Button>

        <Button
          width="210px"
          backgroundColor="buttonConfirm"
          color="white"
          onClick={onHomeClick}
        >
          Home
        </Button>
      </Container>
    </Page>
  )
}