import { ChakraProps, Circle, SquareProps } from '@chakra-ui/react'

interface TileOverlayProps extends ChakraProps {
  small?: boolean
  placement?: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left'
}

export const TileOverlay = ({
  children,
  small = false,
  placement = 'top-left',
  ...props
}: React.PropsWithChildren<TileOverlayProps>) => {
  const size = small ? '40px' : '50px'
  const positionOffset = small ? '0.5em' : '1em'

  let placementProps: SquareProps
  switch (placement) {
    case 'top-right':
      placementProps = {
        top: ['0.25em', null, null, positionOffset],
        right: ['0.25em', null, null, positionOffset],
      }
      break

    case 'bottom-right':
      placementProps = {
        bottom: ['0.25em', null, null, positionOffset],
        right: ['0.25em', null, null, positionOffset],
      }
      break

    case 'bottom-left':
      placementProps = {
        bottom: ['0.25em', null, null, positionOffset],
        left: ['0.25em', null, null, positionOffset],
      }
      break

    default:
      placementProps = {
        top: ['0.25em', null, null, positionOffset],
        left: ['0.25em', null, null, positionOffset],
      }
      break
  }

  return (
    <Circle
      position="absolute"
      backgroundColor="backgroundPrimaryExtraDark"
      size={['25px', null, null, size]}
      {...placementProps}
      {...props}
    >
      {children}
    </Circle>
  )
}