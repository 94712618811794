import { memo, ReactNode } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
} from '@chakra-ui/react'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  quantity: yup
    .number()
    .required('Quantity required')
    .min(1, 'Quantity must be greater than 0'),
  onlyHandsRaised: yup.bool().required(),
})

export interface PromoteGroupFormProps {
  quantity: number
  onlyHandsRaised: boolean
}

const INITIAL_VALUES: PromoteGroupFormProps = {
  quantity: 1,
  onlyHandsRaised: true,
}

interface PromoteGroupModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: PromoteGroupFormProps) => void
}

const _PromoteGroupModal = ({
  isOpen,
  onClose,
  onSubmit,
}: PromoteGroupModalProps) => (
  <Modal isOpen={isOpen} isCentered size="xl" onClose={onClose}>
    <ModalOverlay backdropFilter="blur(32px)" />

    <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
      <ModalHeader>
        <Heading>Promote Group to Stage</Heading>
      </ModalHeader>

      <ModalCloseButton size="2xl" top={6} right={6} />

      <ModalBody paddingBottom={4}>
        <Formik
          initialValues={INITIAL_VALUES}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form>
              <Field name="quantity">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isInvalid={
                      !!(
                        form.errors.quantity &&
                        form.touched.quantity
                      )
                    }
                  >
                    <FormLabel color="white">
                      Quantity
                    </FormLabel>
                    <Input
                      {...field}
                      type="number"
                      placeholder="1"
                      color="white"
                    />
                    {form.errors.quantity &&
                      form.touched.quantity ? (
                      <FormErrorMessage>
                        {
                          form.errors
                            .quantity as ReactNode
                        }
                      </FormErrorMessage>
                    ) : (
                      <FormHelperText>
                        Total number of randomized stage
                        invites to send out
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="onlyHandsRaised">
                {({ field }: FieldProps) => (
                  <Checkbox
                    {...field}
                    isChecked={field.value}
                    size="lg"
                    colorScheme="green"
                    marginTop={4}
                  >
                    <Text>Raised hands only</Text>
                  </Checkbox>
                )}
              </Field>

              <Flex flexDirection="row" marginTop={6}>
                <Button
                  backgroundColor="buttonDeny"
                  color="white"
                  marginRight={4}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  backgroundColor="buttonConfirm"
                  color="white"
                  disabled={!isValid}
                >
                  Promote Group
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export const PromoteGroupModal = memo(_PromoteGroupModal)