import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { chakra, Box, Center, Text } from '@chakra-ui/react'
import { selectHLSState, useHMSStore } from '@100mslive/react-sdk'
import Hls from 'hls.js'
import {
  HLSController,
  HLS_STREAM_NO_LONGER_LIVE,
  HLS_TIMED_METADATA_LOADED,
} from '../../controllers'
import { HLSQualityLevel } from '../../models'
import { HLSControl } from '../control'
import { AllowHlsAutoplayModal } from '../modal'

const HLSVideo = chakra('video', {
  baseStyle: {
    height: '100%',
    margin: '0 auto',
  },
})

let hlsController: HLSController | undefined

const _HLSView = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const hlsState = useHMSStore(selectHLSState)

  const [isVideoLive, setIsVideoLive] = useState(true)
  const [availableLevels, setAvailableLevels] = useState<HLSQualityLevel[]>(
    []
  )
  const [currentLevelText, setCurrentLevelText] = useState('Auto')
  const [isHlsAutoplayBlocked, setIsHlsAutoplayBlocked] = useState(false)
  const [isControlVisible, setIsControlVisible] = useState(false)
  const [isControlActive, setIsControlActive] = useState(false)

  const hlsUrl = hlsState.variants[0]?.url

  const onMouseEnter = () => setIsControlVisible(true)

  const onMouseLeave = () => setIsControlVisible(false)

  const onOpen = () => setIsControlActive(true)

  const onClose = () => {
    setIsControlActive(false)
    setIsControlVisible(false)
  }

  /**
   *
   * @param {the current quality level clicked by the user. It is the level object } qualityLevel
   * @returns an integer ranging from 0 to (availableLevels.length - 1).
   * (e.g) if 4 levels are available, 0 is the lowest quality and 3 is the highest.
   *
   * This function is used rather than just using availableLevels.findIndex(quality) because, HLS gives the
   * levels in reverse.
   * (e.g) if available levels in the m3u8 are 360p,480p,720p,1080p,
   *
   * hls.levels gives us an array of level objects in the order [1080p,720p,480p,360p];
   *
   * so setting hls.currentLevel = availableLevels.getIndexOf(1080p) will set the stream to 360p instead of 1080p
   * because availableLevels.getIndexOf(1080p) will give 0 but level 0 is 360p.
   */
  const getCurrentLevel = (level: HLSQualityLevel) => {
    if (level.height === 'auto') {
      return -1
    }
    const index = availableLevels.findIndex(({ url }) => url === level.url)

    return availableLevels.length - 1 - index
  }

  const onSelect = useCallback(
    (value: string) => {
      try {
        if (hlsController) {
          // @ts-ignore
          let level: HLSQualityLevel = { height: 'auto' }
          if (value !== 'auto') {
            level = availableLevels[parseInt(value)]
          }

          hlsController.setCurrentLevel(getCurrentLevel(level))
          const qualityText =
            level.height === 'auto' ? 'Auto' : `${level.height}p`
          setCurrentLevelText(qualityText)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsControlActive(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableLevels]
  )

  const onGoLiveClick = () => {
    if (hlsController) {
      hlsController.jumpToLive()
      setIsVideoLive(true)
    }
  }

  const unblockAutoplay = async () => {
    try {
      await videoRef.current?.play()
      setIsHlsAutoplayBlocked(false)
    } catch (error) {
      console.log('error unblocking autoplay: ', error)
    }
  }

  // Initialize HLSController and add event listeners
  useEffect(() => {
    const handleHlsNoLongerLive = () => setIsVideoLive(false)
    const handleHlsTimedMetadataLoaded = (payload: any) =>
      console.log('hls controler metadata loaded: ', payload)
    const handleManifestLoaded = (
      _: any,
      { levels }: { levels: HLSQualityLevel[] }
    ) => {
      setAvailableLevels(levels)
      setCurrentLevelText('Auto')
    }

    if (videoRef.current && hlsUrl) {
      if (Hls.isSupported()) {
        hlsController = new HLSController(hlsUrl, videoRef)
        hlsController.on(
          HLS_STREAM_NO_LONGER_LIVE,
          handleHlsNoLongerLive
        )
        hlsController.on(
          HLS_TIMED_METADATA_LOADED,
          handleHlsTimedMetadataLoaded
        )
        hlsController.on(
          Hls.Events.MANIFEST_LOADED,
          handleManifestLoaded
        )
      } else if (
        videoRef.current.canPlayType('application/vnd.apple.mpegurl')
      ) {
        videoRef.current.src = hlsUrl
      }
    }

    return () => {
      if (hlsController) {
        hlsController.off(
          HLS_STREAM_NO_LONGER_LIVE,
          handleHlsNoLongerLive
        )
        hlsController.off(
          HLS_TIMED_METADATA_LOADED,
          handleHlsTimedMetadataLoaded
        )
        hlsController.off(
          Hls.Events.MANIFEST_LOADED,
          handleManifestLoaded
        )
        hlsController = undefined
      }
    }
  }, [hlsUrl])

  // Attempt to autoplay video if paused
  useEffect(() => {
    const videoEl = videoRef.current

    if (!videoEl || !hlsUrl) {
      return
    }

    const playVideo = async () => {
      try {
        if (videoEl.paused) {
          await videoEl.play()
        }
      } catch (error) {
        console.log('Browser blocked autoplay with error', error)
        console.log('asking user to play the video manually...')
        // @ts-ignore
        if ('name' in error && error.name === 'NotAllowedError') {
          setIsHlsAutoplayBlocked(true)
        }
      }
    }

    playVideo()
  }, [hlsUrl])

  if (!hlsUrl) {
    return (
      <Center height="100%" width="100%" overflow="hidden">
        <Text fontSize={['lg', null, '2xl']} color="textSecondary">
          Meeting starting soon...
        </Text>
      </Center>
    )
  }

  return (
    <>
      <Box
        position="relative"
        backgroundColor="black"
        height="100%"
        width="100%"
        overflow="hidden"
        onMouseEnter={onMouseEnter}
        onMouseLeave={!isControlActive ? onMouseLeave : undefined}
      >
        <HLSVideo ref={videoRef} autoPlay controls playsInline />

        <HLSControl
          levels={availableLevels}
          currentLevelText={currentLevelText}
          isVideoLive={isVideoLive}
          isVisible={isControlVisible}
          onOpen={onOpen}
          onClose={onClose}
          onSelect={onSelect}
          onGoLiveClick={onGoLiveClick}
        />
      </Box>

      <AllowHlsAutoplayModal
        isOpen={isHlsAutoplayBlocked}
        unblockAutoPlay={unblockAutoplay}
      />
    </>
  )
}

export const HLSView = memo(_HLSView)