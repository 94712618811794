import { memo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
  ModalFooter,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Box,
} from '@chakra-ui/react'

interface EndRtmpModalProps {
  isOpen: boolean
  relayInProgress: boolean
  onEndMultistreamClick: () => void
  onCloseClick: () => void
  onStartChatRelayClick: (chatEmbedId?: string) => void
  onEndChatRelayClick: () => void
}

const _EndRtmpModal = ({
  isOpen,
  relayInProgress,
  onEndMultistreamClick,
  onCloseClick,
  onStartChatRelayClick,
  onEndChatRelayClick,
}: EndRtmpModalProps) => {
  const [chatEmbedId, setChatEmbedId] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatEmbedId(event.target.value)
  }

  return (
    <Modal isOpen={isOpen} isCentered size="xl" onClose={onCloseClick}>
      <ModalOverlay backdropFilter="blur(32px)" />

      <ModalContent backgroundColor="backgroundPrimary" borderRadius={20}>
        <ModalHeader>
          <Heading>End Multistream</Heading>
        </ModalHeader>

        <ModalCloseButton size="2xl" top={6} right={6} />

        <ModalBody>
          <Text fontSize="lg">
            Are you sure you want to end the multistream for all
            platforms?
          </Text>
        </ModalBody>

        <ModalFooter gap={4}>
          <Button
            backgroundColor="buttonDeny"
            color="white"
            onClick={onCloseClick}
          >
            Cancel
          </Button>

          <Button
            backgroundColor="buttonConfirm"
            color="white"
            onClick={onEndMultistreamClick}
          >
            End Multistream
          </Button>
        </ModalFooter>

        <Box width="100%" paddingTop={2} paddingX={6}>
          <Divider />
        </Box>

        <Box paddingTop={4} paddingX={6} paddingBottom={6}>
          {relayInProgress ? (
            <Flex justifyContent="flex-end">
              <Button
                backgroundColor="buttonPrimary"
                color="white"
                onClick={onEndChatRelayClick}
              >
                End Chat Relay
              </Button>
            </Flex>
          ) : (
            <Flex flexDirection="row" alignItems="flex-end" gap={4}>
              <FormControl flex={1}>
                <FormLabel userSelect="none" color="white">
                  Chat Relay
                </FormLabel>
                <Input
                  placeholder="Optional embed ID"
                  value={chatEmbedId}
                  onChange={handleChange}
                />
              </FormControl>
              <Button
                backgroundColor="buttonPrimary"
                color="white"
                onClick={() =>
                  onStartChatRelayClick(chatEmbedId)
                }
              >
                Start Chat Relay
              </Button>
            </Flex>
          )}
        </Box>
      </ModalContent>
    </Modal>
  )
}

export const EndRtmpModal = memo(_EndRtmpModal)