import { ReactNode } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
} from '@chakra-ui/react'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as yup from 'yup'
import { Card } from '../../components'

export interface PreviewFormValues {
  displayName: string
}

interface PreviewFormProps {
  onSubmit: (values: PreviewFormValues) => void
  disabled: boolean
  initialValues?: PreviewFormValues
}

const validationSchema = yup.object().shape({
  displayName: yup.string().required('Display name required'),
})

export const PreviewForm = ({
  onSubmit,
  disabled,
  initialValues = { displayName: '' },
}: PreviewFormProps) => {
  return (
    <Card width="100%" padding={8} marginTop={8}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          await onSubmit(values)

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Field name="displayName">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isInvalid={
                    !!(
                      form.errors.displayName &&
                      form.touched.displayName
                    )
                  }
                >
                  <FormLabel color="white">
                    Display name
                  </FormLabel>
                  <Input
                    {...field}
                    placeholder="Gary Bee"
                    color="white"
                  />
                  {form.errors.displayName &&
                    form.touched.displayName ? (
                    <FormErrorMessage>
                      {
                        form.errors
                          .displayName as ReactNode
                      }
                    </FormErrorMessage>
                  ) : (
                    <FormHelperText>
                      Your display name will be visible to
                      others in the meeting
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>

            <Button
              type="submit"
              backgroundColor="buttonConfirm"
              color="white"
              isLoading={isSubmitting}
              disabled={disabled || !isValid}
              marginTop={8}
            >
              Join Meeting
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  )
}