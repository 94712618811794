/**
 * 
 * @param {Array[][]} tagList are the fragment tags sent
 * from backend. Their follow the following structure
 * [
 *   [
 *     "INF",
 *     "2"
 *   ],
 *   [
 *     "EXT-PROGRAM-TIME",
 *     "2022-07-01T08:41:46+0000"
 *   ],
 *   [
 *     "EXT-DATE-RANGE",
 *     "somethignsomething;duration='2';payload='Hello'"
 *   ]
 * ]
 * @returns an object with parsed tags. Original
 * tags are available in rawTags property as Key/Value pairs.
 * @throws {Error} if tagList is not provided or is not in the correct format.
 */
export function parseTagsList(tagList) {
  const tagMap = {}
  for (const tags of tagList) {
    if (tagMap[tags[0]]) {
      tagMap[tags[0]].push(removeQuotes(tags[1]))
    } else {
      tagMap[tags[0]] = [removeQuotes(tags[1])]
    }
  }

  const result = {
    rawTags: {
      ...tagMap,
    },
    duration: Number(tagMap['INF'][0]),
    fragmentStartAt: tagMap['PROGRAM-DATE-TIME']
      ? new Date(tagMap['PROGRAM-DATE-TIME'][0])
      : undefined,
  }
  return result
}

/**
 * Parses attributes from the raw EXT-X-DATERANGE value.
 *
 * @param {string} metadatastring - The raw EXT-X-DATERANGE value to parse.
 * @returns {Object} - An object containing parsed data with the following properties:
 *   - duration: The duration attribute value.
 *   - id: The ID attribute value.
 *   - startTime: A Date object representing the START-DATE attribute value.
 *   - payload: The X-100MSLIVE-PAYLOAD attribute value.
 * @throws {Error} - If any error occurs during parsing or data extraction.
 */
export function parseAttributesFromMetadata(metadatastring) {
  const metadataAttributes = metadatastring.split(',')

  const attributesByKey = {}
  for (const attribute of metadataAttributes) {
    const [attribKey, attribValue] = attribute.split('=')
    attributesByKey[attribKey] = removeQuotes(attribValue)
  }

  return {
    duration: attributesByKey['DURATION'],
    id: attributesByKey['ID'],
    startTime: new Date(attributesByKey['START-DATE']),
    payload: attributesByKey['X-100MSLIVE-PAYLOAD'],
  }
}

/**
 * Calculates the total seconds from 00:00:00 to the specified time.
 * 
 * @param {Date} time - The time for which to calculate the total seconds.
 * @returns {number} The total seconds from 00:00:00 to the specified time.
 * @throws {Error} If the input time is not provided.
 * @throws {TypeError} If any of the called functions 'getHours', 'getMinutes', 'getSeconds' are not available.
 */
export function getSecondsFromTime(time) {
  if (time) {
    return (
      time.getHours() * 60 * 60 +
      time.getMinutes() * 60 +
      time.getSeconds()
    )
  }
}

/**
 * Check if tagMetadata is already in the metadata map based on id.
 * 
 * @param {Array} fragsTimeStamps - Array of timestamps fragments.
 * @param {Object} tagMetadata - Metadata object to check.
 * @throws {Error} Will throw an error if fragsTimeStamps is not an array or tagMetadata is not an object.
 * @returns {boolean} Returns true if tagMetadata is already in the metadata map, false otherwise.
 */
export function isAlreadyInMetadataMap(fragsTimeStamps, tagMetadata) {
  const alreadyExistingmetadata = fragsTimeStamps.filter(
    (fragsTimeStamp) => fragsTimeStamp.id === tagMetadata.id
  )

  return !!alreadyExistingmetadata.length
}

/**
 * Removes quotes in a string.
 * 
 * Example:
 * removeQuotes('Hello "Ram"!') // returns 'Hello Ram!'
 * 
 * @param {string} str - The string to remove the quotes from.
 * @returns {string} - The string with quotes removed.
 * @throws {Error} If the input is not a string.
 */
function removeQuotes(str) {
  return str.replace(/^"(.*)"$/, '$1')
}