import { memo } from 'react'
import {
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeerMetadata,
  selectRoleChangeRequest,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react'
import screenfull from 'screenfull'
import { formatRole } from '../../utils'
import { CustomMetadata, Role } from '../../models'

const _RoleChangeModal = () => {
  const request = useHMSStore(selectRoleChangeRequest)
  const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName)
  const metadata: CustomMetadata = useHMSStore(
    selectPeerMetadata(localPeerId)
  )

  const hmsActions = useHMSActions()

  // Exit fullscreen mode so modal can be seen
  if (request && screenfull.isEnabled && screenfull.isFullscreen)
    screenfull.exit()

  // Bots should ignore all role change requests
  if (localPeerRoleName === Role.Bot) return null

  if (request) {
    return (
      <Modal
        isOpen
        isCentered
        size="xl"
        onClose={() => hmsActions.rejectChangeRole(request)}
      >
        <ModalOverlay backdropFilter="blur(32px)" />

        <ModalContent
          backgroundColor="backgroundPrimary"
          borderRadius={20}
        >
          <ModalHeader>
            <Heading>Role Change Request</Heading>
          </ModalHeader>

          <ModalCloseButton size="2xl" top={6} right={6} />

          <ModalBody paddingBottom={4}>
            <Text fontSize="lg" marginBottom={4}>
              {request?.requestedBy?.name} has requested to change
              your role to {formatRole(request?.role.name)}.
            </Text>

            <Button
              backgroundColor="buttonDeny"
              color="white"
              marginRight={4}
              onClick={() => hmsActions.rejectChangeRole(request)}
            >
              Reject
            </Button>

            <Button
              backgroundColor="buttonConfirm"
              color="white"
              onClick={() => {
                hmsActions.acceptChangeRole(request)

                if (metadata.isHandRaised) {
                  hmsActions.changeMetadata({
                    ...metadata,
                    isHandRaised: false,
                  })
                }
              }}
            >
              Accept
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return null
}

export const RoleChangeModal = memo(_RoleChangeModal)