export interface VeeFriendsConfig {
  id: string
  username: string
  emailAddress: string
  avatarUrl: string
  externalUserId: string
  hasCompletedProfile: boolean
  phoneNumber: string
  sessionNonce: string
  bearerToken: string
  expiresUtc: string
  roles: string[]
}

export const ADMIN_ROLE = 'Admin'
export const VEEFRIENDS_ROLE = 'VeeFriends'