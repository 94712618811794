import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  HStack,
  Heading,
  Text,
  VStack,
  Grid,
  GridItem,
  Switch,
} from '@chakra-ui/react'
import { Checkbox, ControlButton, ControlButtonText } from '../../components'
import {
  AdminDropdownModel,
  NftRuleModel,
  TokenAttributeModel,
} from '../../services/meetApi'

interface TokenGateItemProps extends NftRuleModel {
  onDeleteClick: () => void
}

const TokenGateItem = ({
  network,
  contract,
  friendlyContractName,
  attributes,
  onDeleteClick,
}: TokenGateItemProps) => (
  <Flex
    flexDirection="row"
    alignItems="flex-start"
    gap={4}
    width="100%"
    backgroundColor="black"
    paddingY={4}
    paddingX={6}
    borderRadius={20}
  >
    <Flex flex={1} flexDirection="column" justifyContent="center">
      <Text fontSize="xs" color="textSecondary">
        Network
      </Text>
      <Text fontSize="md" color="white">
        {network}
      </Text>
    </Flex>

    <Flex flex={1} flexDirection="column" justifyContent="center">
      <Text fontSize="xs" color="textSecondary">
        Contract
      </Text>
      <Text fontSize="md" color="white" noOfLines={1} maxWidth="180px">
        {friendlyContractName ? friendlyContractName : contract}
      </Text>
    </Flex>

    <Flex flex={1} flexDirection="column" justifyContent="center">
      <Text fontSize="xs" color="textSecondary">
        Attributes
      </Text>

      {attributes && attributes.length > 0 ? (
        <>
          {attributes.map((attribute, index) => (
            <Text key={index} fontSize="md" color="white">
              {`${attribute.traitType}: ${attribute.traitValue}`}
            </Text>
          ))}
        </>
      ) : (
        <Text fontSize="md" color="white">
          Any
        </Text>
      )}
    </Flex>

    <ControlButton
      label="Delete rule"
      aria-label="Delete rule"
      backgroundColor="buttonDeny"
      onClick={onDeleteClick}
    >
      <ControlButtonText
        className="material-symbols-outlined"
        fontSize="sm"
      >
        delete
      </ControlButtonText>
    </ControlButton>
  </Flex>
)

const networkSelectorValidationSchema = yup.object().shape({
  networkId: yup.string().required('Blockchain network required'),
})

interface NetworkSelectorProps {
  options: string[]
  onSelectNetwork: (networkId: string) => void
  onCancel: () => void
}

const NetworkSelector = ({
  options,
  onSelectNetwork,
  onCancel,
}: NetworkSelectorProps) => {
  const formik = useFormik({
    initialValues: { networkId: '' },
    validationSchema: networkSelectorValidationSchema,
    onSubmit: (values) => onSelectNetwork(values.networkId),
  })

  return (
    <Flex flexDirection="column">
      <FormControl
        isRequired
        isInvalid={
          !!(formik.touched.networkId && formik.errors.networkId)
        }
      >
        <FormLabel>Blockchain network</FormLabel>

        <Select
          id="networkId"
          name="networkId"
          placeholder="Select network"
          value={formik.values.networkId}
          onChange={formik.handleChange}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>

        <FormErrorMessage>{formik.errors.networkId}</FormErrorMessage>
      </FormControl>

      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        gap={4}
        marginTop={4}
      >
        <Button width="100px" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          width="100px"
          backgroundColor="buttonConfirm"
          color="white"
          onClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

const contractSelectorValidationSchema = yup.object().shape({
  contract: yup
    .object()
    .shape({
      value: yup.string().required('Contract required'),
      display: yup.string().required('Contract required'),
    })
    .required('Contract required'),
})

interface ContractSelectorProps {
  options: { value: string; display: string }[]
  onSelectContract: (contract: { value: string; display: string }) => void
  onCancel: () => void
}

const ContractSelector = ({
  options,
  onSelectContract,
  onCancel,
}: ContractSelectorProps) => {
  const formik = useFormik({
    initialValues: { contract: { value: '', display: '' } },
    validationSchema: contractSelectorValidationSchema,
    onSubmit: (values) => onSelectContract(values.contract),
  })

  return (
    <Flex flexDirection="column">
      <FormControl
        isRequired
        isInvalid={
          !!(formik.touched.contract && formik.errors.contract)
        }
      >
        <FormLabel>Contract</FormLabel>

        <Select
          id="contract"
          name="contract"
          placeholder="Select contract"
          value={formik.values.contract.value}
          onChange={(e) => {
            const option = options.find(
              (opt) => opt.value === e.currentTarget.value
            )
            formik.setFieldValue('contract', option)
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.display}
            </option>
          ))}
        </Select>

        <FormErrorMessage>
          {formik.errors.contract?.display}
        </FormErrorMessage>
      </FormControl>

      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        gap={4}
        marginTop={4}
      >
        <Button width="100px" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          width="100px"
          backgroundColor="buttonConfirm"
          color="white"
          onClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

const TRAIT_BLACKLIST = [
  'edition #',
  'access remaining',
  'gifts remaining',
  'redemptions',
]

interface TraitSelectorProps {
  options: { key: string; values: string[] }[]
  onSelectTraits: (props: {
    combineTraits: boolean
    selectedAttributes: { [key: string]: string[] }
  }) => void
  onCancel: () => void
}

const initialValues: {
  combineTraits: boolean
  selectedAttributes: { [key: string]: string[] }
} = {
  combineTraits: true,
  selectedAttributes: {},
}

const TraitSelector = ({
  options,
  onSelectTraits,
  onCancel,
}: TraitSelectorProps) => {
  const formik = useFormik({
    initialValues,
    onSubmit: onSelectTraits,
  })

  const onSwitch = (e: any) => {
    formik.handleChange(e)
    formik.setFieldValue('selectedAttributes', {})
  }

  const onChange = (key: string, value: string, isChecked: boolean) => {
    const clone = cloneDeep(formik.values.selectedAttributes)

    if (isChecked) {
      if (clone[key]) {
        clone[key].push(value)
      } else {
        clone[key] = [value]
      }
      formik.setFieldValue('selectedAttributes', clone)
      return
    }

    clone[key] = clone[key].filter((val) => val !== value)
    formik.setFieldValue('selectedAttributes', clone)
  }

  const onSelect = (key: string, value: string) => {
    const clone = cloneDeep(formik.values.selectedAttributes)
    clone[key] = [value]
    formik.setFieldValue('selectedAttributes', clone)
  }

  return (
    <Flex flexDirection="column">
      <FormControl
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={4}
        marginBottom={4}
      >
        <FormLabel
          userSelect="none"
          color="white"
          htmlFor="combineTraits"
          marginBottom={0}
        >
          Combine traits
        </FormLabel>
        <Switch
          id="combineTraits"
          colorScheme="green"
          isChecked={formik.values.combineTraits}
          onChange={onSwitch}
        />
      </FormControl>

      <VStack spacing={8}>
        {options
          .filter(
            (trait) =>
              !TRAIT_BLACKLIST.includes(trait.key.toLowerCase())
          )
          .map((trait) => (
            <FormControl key={trait.key}>
              <FormLabel>{trait.key}</FormLabel>
              {formik.values.combineTraits ? (
                <Select
                  placeholder={`Select ${trait.key.toLowerCase()}`}
                  onChange={(e) =>
                    onSelect(trait.key, e.target.value)
                  }
                >
                  {[...trait.values].sort().map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              ) : (
                <Grid
                  gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                  rowGap={2}
                >
                  {[...trait.values].sort().map((option) => (
                    <GridItem key={option}>
                      <Checkbox
                        label={option}
                        onChange={(e) =>
                          onChange(
                            trait.key,
                            option,
                            e.target.checked
                          )
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
              )}
            </FormControl>
          ))}
      </VStack>

      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        gap={4}
        marginTop={4}
      >
        <Button width="100px" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          width="100px"
          backgroundColor="buttonConfirm"
          color="white"
          onClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

const STEPS = ['Select network', 'Select contract', 'Select traits']

interface TokenGateWizardProps {
  data: AdminDropdownModel[]
  nftGates: NftRuleModel[]
  isDisabled?: boolean
  onCreateGates: (gates: NftRuleModel[]) => void
  onDeleteGate: (index: number) => void
}

export const TokenGateWizard = ({
  data,
  nftGates,
  isDisabled = false,
  onCreateGates,
  onDeleteGate,
}: TokenGateWizardProps) => {
  const [showBuilder, setShowBuilder] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)

  const [networkOptions, setNetworkOptions] = useState<string[]>([])

  const [selectedNetwork, setSelectedNetwork] = useState<string | undefined>()

  const [contractOptions, setContractOptions] = useState<
    { value: string; display: string }[]
  >([])

  const [selectedContract, setSelectedContract] = useState<
    { contractAddress: string; contractName: string } | undefined
  >()

  const [traitOptions, setTraitOptions] = useState<
    { key: string; values: string[] }[]
  >([])

  const onSelectNetwork = (networkId: string) => {
    setSelectedNetwork(networkId)
    setCurrentStep(1)
  }

  const onSelectContract = (
    contractAddress: string,
    contractName: string
  ) => {
    setSelectedContract({ contractAddress, contractName })
    setCurrentStep(2)
  }

  const onSelectTraits = ({
    combineTraits,
    selectedAttributes,
  }: {
    combineTraits: boolean
    selectedAttributes: {
      [key: string]: string[]
    }
  }) => {
    if (combineTraits) {
      const attributes: TokenAttributeModel[] = []

      Object.keys(selectedAttributes).forEach((key) => {
        selectedAttributes[key].forEach((value) => {
          attributes.push({ traitType: key, traitValue: value })
        })
      })

      onCreateGates([
        {
          network: selectedNetwork,
          contract: selectedContract?.contractAddress,
          friendlyContractName: selectedContract?.contractName,
          attributes,
        },
      ])
    } else {
      const gates: NftRuleModel[] = []

      if (Object.keys(selectedAttributes).length > 0) {
        Object.keys(selectedAttributes).forEach((key) => {
          selectedAttributes[key].forEach((value) => {
            gates.push({
              network: selectedNetwork,
              contract: selectedContract?.contractAddress,
              friendlyContractName:
                selectedContract?.contractName,
              attributes: [{ traitType: key, traitValue: value }],
            })
          })
        })
      } else {
        gates.push({
          network: selectedNetwork,
          contract: selectedContract?.contractAddress,
          friendlyContractName: selectedContract?.contractName,
          attributes: [],
        })
      }

      onCreateGates(gates)
    }

    setSelectedNetwork(undefined)
    setSelectedContract(undefined)
    setCurrentStep(0)
    setShowBuilder(false)
  }

  const onCancel = () => {
    setSelectedNetwork(undefined)
    setSelectedContract(undefined)
    setCurrentStep(0)
    setShowBuilder(false)
  }

  // Set network options
  useEffect(() => {
    if (networkOptions.length > 0) return

    const item = data.find((i) => i.key === 'nft')
    if (item && item.values) {
      setNetworkOptions(item.values.map((value: any) => value.key))
    }
  }, [networkOptions, data])

  // Set contract options
  useEffect(() => {
    if (selectedNetwork) {
      const item = data.find((i) => i.key === 'nft')
      if (item && item.values) {
        const contracts = (item.values as any).find(
          (j: any) => j.key === selectedNetwork
        )

        if (contracts && contracts.values) {
          setContractOptions(
            contracts.values.map((value: any) => {
              return { value: value.key, display: value.display }
            })
          )
        }
      }
    }
  }, [selectedNetwork, data])

  // Set trait options
  useEffect(() => {
    if (selectedNetwork && selectedContract) {
      const item = data.find((i) => i.key === 'nft')
      if (item && item.values) {
        const contracts = (item.values as any).find(
          (j: any) => j.key === selectedNetwork
        )

        if (contracts && contracts.values) {
          const traits = (contracts.values as any).find(
            (k: any) => k.key === selectedContract.contractAddress
          )

          if (traits && traits.values) {
            setTraitOptions(
              traits.values.map((value: any) => {
                return {
                  key: value.key,
                  values: value.values.values,
                }
              })
            )
          }
        }
      }
    }
  }, [selectedNetwork, selectedContract, data])

  return (
    <Flex flexDirection="column">
      <Heading
        as="h3"
        size="md"
        fontWeight={800}
        textTransform="uppercase"
        userSelect="none"
      >
        Or Add Custom Token Access Rules
      </Heading>

      {showBuilder ? (
        <Flex flex={1} flexDirection="column" marginTop={8}>
          <HStack marginBottom={8}>
            {STEPS.map((step, index) => (
              <Flex
                key={step}
                flex={1}
                flexDirection="column"
                borderTop="4px solid"
                borderColor={
                  currentStep >= index
                    ? 'headingPrimary'
                    : 'whiteAlpha.500'
                }
                paddingTop={4}
              >
                <Text
                  fontSize="xs"
                  fontWeight="600"
                  color={
                    currentStep === index
                      ? 'headingPrimary'
                      : 'whiteAlpha.500'
                  }
                  style={{
                    fontVariant: 'all-small-caps',
                    letterSpacing: '0.05em',
                  }}
                >{`STEP ${index + 1}`}</Text>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={
                    currentStep === index
                      ? 'white'
                      : 'whiteAlpha.500'
                  }
                >
                  {step}
                </Text>
              </Flex>
            ))}
          </HStack>

          {currentStep === 0 ? (
            <NetworkSelector
              options={networkOptions}
              onSelectNetwork={onSelectNetwork}
              onCancel={onCancel}
            />
          ) : null}

          {currentStep === 1 ? (
            <ContractSelector
              options={contractOptions}
              onSelectContract={(contract) =>
                onSelectContract(
                  contract.value,
                  contract.display
                )
              }
              onCancel={onCancel}
            />
          ) : null}

          {currentStep === 2 ? (
            <TraitSelector
              options={traitOptions}
              onSelectTraits={onSelectTraits}
              onCancel={onCancel}
            />
          ) : null}
        </Flex>
      ) : (
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          marginTop={4}
        >
          {nftGates.length === 0 ? (
            <Text fontSize="md" color="whiteAlpha.600">
              No custom rules created
            </Text>
          ) : (
            <VStack width="100%" spacing={2}>
              {nftGates.map((props, index) => (
                <TokenGateItem
                  key={`${props.network}-${props.contract}-${props.attributes &&
                    props.attributes.length > 0 &&
                    props.attributes[0].traitValue
                    }-${index}`}
                  {...props}
                  onDeleteClick={() => onDeleteGate(index)}
                />
              ))}
            </VStack>
          )}

          <Button
            isDisabled={isDisabled}
            marginTop={8}
            onClick={() => setShowBuilder(true)}
          >
            Add Rule
          </Button>
        </Flex>
      )}
    </Flex>
  )
}