import { emptySplitApiMeet as api } from './emptyApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    closeMeetRoomEndpoint: build.mutation<
      CloseMeetRoomEndpointApiResponse,
      CloseMeetRoomEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.id}`,
        method: 'DELETE',
        body: queryArg.deleteTResponseModel,
      }),
    }),
    getMeetRoomEndpoint: build.query<
      GetMeetRoomEndpointApiResponse,
      GetMeetRoomEndpointApiArg
    >({
      query: (queryArg) => ({ url: `/v1/meet/${queryArg.id}` }),
    }),
    createMeetingEndpoint: build.mutation<
      CreateMeetingEndpointApiResponse,
      CreateMeetingEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet`,
        method: 'POST',
        body: queryArg.createMeetRequestModel,
      }),
    }),
    createMeetingPrivateLinkEndpoint: build.mutation<
      CreateMeetingPrivateLinkEndpointApiResponse,
      CreateMeetingPrivateLinkEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.id}/private`,
        method: 'POST',
        body: queryArg.createPrivateLinkRequestModel,
      }),
    }),
    deleteMeetingPrivateLinkEndpoint: build.mutation<
      DeleteMeetingPrivateLinkEndpointApiResponse,
      DeleteMeetingPrivateLinkEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.id}/private/${queryArg.code}`,
        method: 'DELETE',
        body: queryArg.createPrivateLinkRequestModel,
      }),
    }),
    getMeetRoomPrivateEndpoint: build.query<
      GetMeetRoomPrivateEndpointApiResponse,
      GetMeetRoomPrivateEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.id}/private/${queryArg.code}`,
        params: {
          friendlyName: queryArg.friendlyName,
          emailAddress: queryArg.emailAddress,
          timestamp: queryArg.timestamp,
        },
      }),
    }),
    endRelayEndpoint: build.query<
      EndRelayEndpointApiResponse,
      EndRelayEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.roomId}/multicast/disable-chat-relay`,
      }),
    }),
    getActiveRoomsEndpoint: build.query<
      GetActiveRoomsEndpointApiResponse,
      GetActiveRoomsEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/active`,
        params: { IsLive: queryArg.isLive },
      }),
    }),
    getBotRoomIdEndpoint: build.query<
      GetBotRoomIdEndpointApiResponse,
      GetBotRoomIdEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/bot`,
        params: { roomId: queryArg.roomId },
      }),
    }),
    getChatRoomEndpoint: build.query<
      GetChatRoomEndpointApiResponse,
      GetChatRoomEndpointApiArg
    >({
      query: (queryArg) => ({ url: `/v1/meet/${queryArg.id}/chat` }),
    }),
    getChatRoomPrivateEndpoint: build.query<
      GetChatRoomPrivateEndpointApiResponse,
      GetChatRoomPrivateEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.id}/chat/private/${queryArg.code}`,
        params: {
          friendlyName: queryArg.friendlyName,
          emailAddress: queryArg.emailAddress,
          timestamp: queryArg.timestamp,
        },
      }),
    }),
    getLiveRoomsEndpoint: build.query<
      GetLiveRoomsEndpointApiResponse,
      GetLiveRoomsEndpointApiArg
    >({
      query: () => ({ url: `/v1/meet/live` }),
    }),
    getMeetDropdownsEndpoint: build.query<
      GetMeetDropdownsEndpointApiResponse,
      GetMeetDropdownsEndpointApiArg
    >({
      query: () => ({ url: `/v1/meet/dropdowns` }),
    }),
    getMeetRoomAdminEndpoint: build.query<
      GetMeetRoomAdminEndpointApiResponse,
      GetMeetRoomAdminEndpointApiArg
    >({
      query: (queryArg) => ({ url: `/v1/meet/${queryArg.id}/admin` }),
    }),
    getMeetTokenAccessEndpoint: build.query<
      GetMeetTokenAccessEndpointApiResponse,
      GetMeetTokenAccessEndpointApiArg
    >({
      query: () => ({ url: `/v1/meet/token-access` }),
    }),
    getRelayIsListeningEndpoint: build.query<
      GetRelayIsListeningEndpointApiResponse,
      GetRelayIsListeningEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.roomId}/is-listening`,
        params: { chatEmbedId: queryArg.chatEmbedId },
      }),
    }),
    meetingWebhooksEndpoint: build.mutation<
      MeetingWebhooksEndpointApiResponse,
      MeetingWebhooksEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/webhooks`,
        method: 'POST',
        body: queryArg.meetWebhookRequestModel,
      }),
    }),
    startRelayEndpoint: build.query<
      StartRelayEndpointApiResponse,
      StartRelayEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.roomId}/multicast/enable-chat-relay`,
        params: { chatEmbedId: queryArg.chatEmbedId },
      }),
    }),
    updateMeetEndpoint: build.mutation<
      UpdateMeetEndpointApiResponse,
      UpdateMeetEndpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/meet/${queryArg.roomId}`,
        method: 'PUT',
        body: queryArg.updateMeetRequestModel,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as meetApi }
export type CloseMeetRoomEndpointApiResponse =
    /** status 200 Success */ DeleteTResponseModel
export type CloseMeetRoomEndpointApiArg = {
  id: string | null
  deleteTResponseModel: DeleteTResponseModel
}
export type GetMeetRoomEndpointApiResponse =
    /** status 200 Success */ GetMeetResponseModel
export type GetMeetRoomEndpointApiArg = {
  id: string | null
}
export type CreateMeetingEndpointApiResponse =
    /** status 200 Success */ CreateMeetResponseModel
export type CreateMeetingEndpointApiArg = {
  createMeetRequestModel: CreateMeetRequestModel
}
export type CreateMeetingPrivateLinkEndpointApiResponse =
    /** status 200 Success */ CreatePrivateLinkRequestModel
export type CreateMeetingPrivateLinkEndpointApiArg = {
  id: string | null
  createPrivateLinkRequestModel: CreatePrivateLinkRequestModel
}
export type DeleteMeetingPrivateLinkEndpointApiResponse =
    /** status 200 Success */ CreatePrivateLinkRequestModel
export type DeleteMeetingPrivateLinkEndpointApiArg = {
  id: string | null
  code: string | null
  createPrivateLinkRequestModel: CreatePrivateLinkRequestModel
}
export type GetMeetRoomPrivateEndpointApiResponse =
    /** status 200 Success */ GetMeetResponseModel
export type GetMeetRoomPrivateEndpointApiArg = {
  id: string | null
  code: string | null
  friendlyName?: string | null
  emailAddress?: string | null
  timestamp?: number | null
}
export type EndRelayEndpointApiResponse =
    /** status 200 Success */ EmptyResponse
export type EndRelayEndpointApiArg = {
  roomId: string | null
}
export type GetActiveRoomsEndpointApiResponse =
    /** status 200 Success */ GetActiveMeetRoomResponseModel
export type GetActiveRoomsEndpointApiArg = {
  isLive: boolean
}
export type GetBotRoomIdEndpointApiResponse =
    /** status 200 Success */ MeetBotRoomIdResponseModel
export type GetBotRoomIdEndpointApiArg = {
  roomId?: string | null
}
export type GetChatRoomEndpointApiResponse =
    /** status 200 Success */ GetMeetResponseModel
export type GetChatRoomEndpointApiArg = {
  id: string | null
}
export type GetChatRoomPrivateEndpointApiResponse =
    /** status 200 Success */ GetMeetResponseModel
export type GetChatRoomPrivateEndpointApiArg = {
  id: string | null
  code: string | null
  friendlyName?: string | null
  emailAddress?: string | null
  timestamp?: number | null
}
export type GetLiveRoomsEndpointApiResponse =
    /** status 200 Success */ ResponseCollectionModelOfGetLiveRoomResponseModel
export type GetLiveRoomsEndpointApiArg = void
export type GetMeetDropdownsEndpointApiResponse =
    /** status 200 Success */ AdminDropdownModel[]
export type GetMeetDropdownsEndpointApiArg = void
export type GetMeetRoomAdminEndpointApiResponse =
    /** status 200 Success */ GetMeetResponseModel
export type GetMeetRoomAdminEndpointApiArg = {
  id: string | null
}
export type GetMeetTokenAccessEndpointApiResponse =
    /** status 200 Success */ ResponseCollectionModelOfTokenAccessModel
export type GetMeetTokenAccessEndpointApiArg = void
export type GetRelayIsListeningEndpointApiResponse =
    /** status 200 Success */ ResponseModelOfIsRelayListeningResponseModel
export type GetRelayIsListeningEndpointApiArg = {
  roomId: string | null
  chatEmbedId?: string | null
}
export type MeetingWebhooksEndpointApiResponse =
    /** status 200 Success */ CreateMeetResponseModel
export type MeetingWebhooksEndpointApiArg = {
  meetWebhookRequestModel: MeetWebhookRequestModel
}
export type StartRelayEndpointApiResponse =
    /** status 200 Success */ EmptyResponse
export type StartRelayEndpointApiArg = {
  roomId: string | null
  chatEmbedId?: string | null
}
export type UpdateMeetEndpointApiResponse =
    /** status 200 Success */ EmptyResponse
export type UpdateMeetEndpointApiArg = {
  roomId: string | null
  updateMeetRequestModel: UpdateMeetRequestModel
}
export type BaseResponseDataModel = {
  correlationId?: string
  tags?: string[] | null
  createdAt?: string
  modifiedAt?: string
  deletedAt?: string | null
  deleted?: boolean | null
}
export type DeleteTResponseModel = BaseResponseDataModel & object
export type MeetRoomConfigBase = {
  title?: string | null
  subTitle?: string | null
  description?: string | null
  type?: string
  displayMode?: string
  chatEnabled?: boolean
  chatId?: string | null
  start?: string | null
  end?: string | null
  creator?: string
  slowModeTimer?: number | null
  meetingType?: string | null
}
export type MeetRoomConfigResponse = MeetRoomConfigBase & {
  primaryColor?: string
  bannerImage?: string
}
export type GetMeetResponseModel = BaseResponseDataModel & {
  userId?: string | null
  issuer?: string
  jwt?: string | null
  config?: MeetRoomConfigResponse | null
  chatParticipantId?: string | null
  code?: string | null
}
export type CreateMeetResponseModel = {
  urlRoleDict?: {
    [key: string]: string
  } | null
  roomId?: string | null
  roomName?: string | null
}
export type MeetRoomConfigRequest = MeetRoomConfigBase & {
  themeId?: string | null
}
export type TokenAttributeModel = {
  traitType?: string | null
  traitValue?: string | null
}
export type NftGate = {
  network?: string | null
  contract?: string | null
  attributes?: TokenAttributeModel[]
}
export type CreateMeetRequestModel = {
  description?: string | null
  templateId?: string | null
  region?: string | null
  tokenAccessId?: string | null
  roomConfig?: MeetRoomConfigRequest | null
  adminInvitees?: string[] | null
  invitees?: string[] | null
  nftGates?: NftGate[] | null
  saveRule?: boolean
  type?: string | null
}
export type CreatePrivateLinkRequestModel = object
export type EmptyResponse = object
export type PrivateLink = {
  friendlyName?: string | null
  timestamp?: number | null
}
export type RoomRoleLinkModel = {
  id?: string | null
  role?: string | null
  name?: string | null
  privateCodes?: {
    [key: string]: PrivateLink
  } | null
}
export type RuleCategory = 0 | 1 | 2 | 3
export type BaseRuleModel = {
  amount?: number
  category?: RuleCategory | null
}
export type NftRequirements = {
  startTime?: string
  endTime?: string
  startPrice?: number | null
  endPrice?: number | null
  acceptedMarketplaces?: string[] | null
  required?: boolean
}
export type NftRuleModel = BaseRuleModel & {
  network?: string | null
  contract?: string | null
  friendlyContractName?: string | null
  attributes?: TokenAttributeModel[]
  purchaseRequirements?: NftRequirements | null
  listingRequirements?: NftRequirements | null
  category?: RuleCategory | null
}
export type RoomDataModel = {
  roomRoles?: RoomRoleLinkModel[] | null
  invitees?: string[] | null
  admins?: string[] | null
  nftRules?: NftRuleModel[] | null
  roomConfig?: MeetRoomConfigRequest | null
  isLive?: boolean
  templateId?: string | null
  tokenAccessId?: string | null
}
export type GetActiveMeetRoomResponseModel = {
  data?: {
    [key: string]: RoomDataModel
  } | null
}
export type MeetBotRoomIdResponseModel = {
  id?: string | null
}
export type GetLiveRoomResponseModel = BaseResponseDataModel & {
  roomName?: string | null
  link?: string | null
}
export type HttpMethod = {
  method?: string
}
export type LinkResponseModel = {
  rel?: string
  href?: string | null
  action?: HttpMethod
  types?: string[] | null
}
export type ResponseModelOfGetLiveRoomResponseModel = {
  data?: GetLiveRoomResponseModel
  links?: LinkResponseModel[]
}
export type ResponseCollectionModelOfGetLiveRoomResponseModel =
  ResponseModelOfGetLiveRoomResponseModel & {
    data?: GetLiveRoomResponseModel[]
    length?: number | null
    messages?: string[] | null
  }
export type AdminDropdownModel = {
  key?: string | null
  name?: string | null
  display?: string | null
  values?: any | null
}
export type TokenAccessModel = BaseResponseDataModel & {
  id?: string | null
  name?: string | null
  description?: string | null
  emailTemplateId?: string | null
  blogLink?: LinkResponseModel | null
  startDateTime?: string | null
  endDateTime?: string | null
  inventoryId?: string | null
}
export type ResponseModelOfTokenAccessModel = {
  data?: TokenAccessModel
  links?: LinkResponseModel[]
}
export type ResponseCollectionModelOfTokenAccessModel =
  ResponseModelOfTokenAccessModel & {
    data?: TokenAccessModel[]
    length?: number | null
    messages?: string[] | null
  }
export type IsRelayListeningResponseModel = BaseResponseDataModel & {
  roomId?: string | null
  isListening?: boolean
}
export type ResponseModelOfIsRelayListeningResponseModel = {
  data?: IsRelayListeningResponseModel
  links?: LinkResponseModel[]
}
export type MeetWebhookRecordingSingleFiles = {
  asset_id?: string | null
  recording_presigned_url?: string | null
  width?: number
  height?: number
}
export type MeetWebhookData = {
  joined_at?: string | null
  peer_id?: string | null
  role?: string | null
  room_id?: string | null
  room_name?: string | null
  session_id?: string | null
  template_id?: string | null
  user_id?: string | null
  user_name?: string | null
  user_data?: string | null
  session_started_at?: string | null
  duration?: number | null
  left_at?: string | null
  reason?: string | null
  message?: string | null
  recording_presigned_url?: string | null
  recording_single_files?: MeetWebhookRecordingSingleFiles[] | null
}
export type MeetWebhookRequestModel = {
  version?: string | null
  id?: string | null
  account_id?: string | null
  titmestamp?: string | null
  type?: string | null
  data?: MeetWebhookData | null
}
export type UpdateMeetRequestModel = {
  description?: string | null
  region?: string | null
  tokenAccessId?: string | null
  roomConfig?: MeetRoomConfigRequest | null
  adminInvitees?: string[] | null
  invitees?: string[] | null
  nftGates?: NftGate[] | null
  saveRule?: boolean
  type?: string | null
  templateId?: string | null
}
export const {
  useCloseMeetRoomEndpointMutation,
  useGetMeetRoomEndpointQuery,
  useCreateMeetingEndpointMutation,
  useCreateMeetingPrivateLinkEndpointMutation,
  useDeleteMeetingPrivateLinkEndpointMutation,
  useGetMeetRoomPrivateEndpointQuery,
  useEndRelayEndpointQuery,
  useGetActiveRoomsEndpointQuery,
  useGetBotRoomIdEndpointQuery,
  useGetChatRoomEndpointQuery,
  useGetChatRoomPrivateEndpointQuery,
  useGetLiveRoomsEndpointQuery,
  useGetMeetDropdownsEndpointQuery,
  useGetMeetRoomAdminEndpointQuery,
  useGetMeetTokenAccessEndpointQuery,
  useGetRelayIsListeningEndpointQuery,
  useMeetingWebhooksEndpointMutation,
  useStartRelayEndpointQuery,
  useUpdateMeetEndpointMutation,
} = injectedRtkApi