import { CSSProperties, memo, useState } from 'react'
import { Box, ChakraProps } from '@chakra-ui/react'
import {
  HMSPeer,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectScreenShareByPeerID,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Video } from '@100mslive/roomkit-react'
import screenfull from 'screenfull'
import { ControlButton, ControlButtonText } from '../button'
import { Role } from '../../models'
import { MOD_FUNC_END_SCREENSHARE } from '../../constants'

interface ScreenShareTileProps {
  presenter: HMSPeer
  height?: number
  width?: number
  hideFullscreenButton?: boolean
  containerStyle?: ChakraProps
  videoStyle?: CSSProperties
}

const _ScreenShareTile = ({
  presenter,
  height,
  width,
  hideFullscreenButton = false,
  containerStyle,
  videoStyle,
}: ScreenShareTileProps) => {
  const screenShareVideoTrack = useHMSStore(
    selectScreenShareByPeerID(presenter.id)
  )
  const localPeerId = useHMSStore(selectLocalPeerID)
  const localPeerRole = useHMSStore(selectLocalPeerRoleName)
  const hmsActions = useHMSActions()

  const [showControls, setShowControls] = useState(false)

  const isHost =
    localPeerRole === Role.Moderator ||
    localPeerRole === Role.StageModerator ||
    localPeerRole === Role.Host

  const onFullscreenClick = () => {
    if (screenfull.isEnabled) {
      const element = document.getElementById(
        `video-${screenShareVideoTrack?.id}`
      )
      if (element) {
        screenfull.request(element)
      }
    }
  }

  const onRemoteEndShareClick = () => {
    try {
      hmsActions.sendDirectMessage(MOD_FUNC_END_SCREENSHARE, presenter.id)
    } catch (error) {
      console.error(error)
    }
  }

  const onMouseEnter = () => setShowControls(true)
  const onMouseLeave = () => setShowControls(false)

  return (
    <Box
      position="relative"
      height={height}
      width={width}
      maxHeight="100%"
      maxWidth="100%"
      backgroundColor="backgroundSecondary"
      borderRadius={8}
      overflow="hidden"
      {...containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Video
        id={`video-${screenShareVideoTrack?.id}`}
        trackId={screenShareVideoTrack?.id}
        degraded={screenShareVideoTrack?.degraded}
        style={{
          objectFit: 'contain',
          borderRadius: 8,
          ...videoStyle,
        }}
      />

      {!hideFullscreenButton &&
        showControls &&
        screenfull.isEnabled &&
        localPeerId !== presenter.id ? (
        <ControlButton
          position="absolute"
          top={2}
          right={2}
          height="40px"
          width="40px"
          label="Fullscreen"
          aria-label="Fullscreen"
          backgroundColor="backgroundPrimaryExtraExtraDark"
          _hover={{ backgroundColor: 'backgroundPrimaryExtraDark' }}
          onClick={onFullscreenClick}
        >
          <ControlButtonText className="material-symbols-outlined">
            fullscreen
          </ControlButtonText>
        </ControlButton>
      ) : null}

      {showControls && isHost && localPeerId !== presenter.id ? (
        <ControlButton
          position="absolute"
          top={2}
          right={14}
          height="40px"
          width="40px"
          label="End screenshare"
          aria-label="End screenshare"
          backgroundColor="backgroundPrimaryExtraExtraDark"
          _hover={{ backgroundColor: 'backgroundPrimaryExtraDark' }}
          onClick={onRemoteEndShareClick}
        >
          <ControlButtonText className="material-symbols-outlined">
            stop_screen_share
          </ControlButtonText>
        </ControlButton>
      ) : null}
    </Box>
  )
}

export const ScreenShareTile = memo(_ScreenShareTile)