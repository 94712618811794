import { memo } from 'react'
import { Box, Flex, Text, Avatar } from '@chakra-ui/react'
import { HMSPeer, selectLocalPeerID, useHMSStore } from '@100mslive/react-sdk'
import { PeerList } from './PeerList'
import { ScreenShareTile } from '../tile'
import { CustomMetadata } from '../../models'

interface ScreenShareViewProps {
  presenter: HMSPeer
  peers: HMSPeer[]
  hidePeerList?: boolean
}

const _ScreenShareView = ({
  presenter,
  peers,
  hidePeerList = false,
}: ScreenShareViewProps) => {
  const localPeerId = useHMSStore(selectLocalPeerID)
  const metadata: CustomMetadata = presenter.metadata
    ? JSON.parse(presenter.metadata)
    : {}

  return (
    <Flex height="100%" width="100%">
      <Flex flex={1} flexDirection="column">
        <Flex
          flexDirection="row"
          alignItems="center"
          width="100%"
          paddingY={2}
          paddingX={4}
          backgroundColor="backgroundPrimary"
          border="2px solid black"
          borderRadius={12}
          marginBottom={4}
        >
          <Avatar
            name={presenter.name}
            src={metadata.avatarUrl}
            size="xs"
            backgroundColor={
              metadata.avatarUrl ? 'black' : 'headingPrimary'
            }
            color="white"
            marginRight={3}
          />

          <Text fontSize="sm" fontWeight={600}>
            {localPeerId === presenter.id
              ? 'You are presenting'
              : `${presenter.name} is presenting`}
          </Text>
        </Flex>

        <ScreenShareTile presenter={presenter} />
      </Flex>

      {!hidePeerList ? (
        <Box
          display={['none', null, null, 'block']}
          height="100%"
          width={
            peers.length - 1 > 5
              ? ['250px', null, null, null, '360px']
              : ['125px', null, null, null, '180px']
          }
          marginLeft={2}
        >
          <PeerList peers={peers} stickyPeer={presenter} />
        </Box>
      ) : null}
    </Flex>
  )
}

export const ScreenShareView = memo(_ScreenShareView)