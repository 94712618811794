import { useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { HMSRoomProvider } from '@100mslive/react-sdk'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store'
import { theme } from './theme'
import { Router } from './navigation'
import { FullPageLoader } from './components'
import packageJson from '../package.json'

import './index.css'
import 'material-symbols'

function App() {
  useEffect(() => {
    const localVersion = localStorage.getItem('version')

    if (!localVersion || localVersion !== packageJson.version) {
      if ('caches' in window) {
        caches
          .keys()
          .then((keys) => keys.forEach((key) => caches.delete(key)))
      }

      localStorage.clear()
      localStorage.setItem('version', packageJson.version)
      window.location.reload()
    }
  }, [])

  return (
    <HMSRoomProvider>
      <PersistGate persistor={persistor} loading={<FullPageLoader />}>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <Router />
          </ChakraProvider>
        </Provider>
      </PersistGate>
    </HMSRoomProvider>
  )
}

export default App