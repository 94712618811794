import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HMSLogLevel, useHMSActions } from '@100mslive/react-sdk'
import {
  HomePage,
  LandingPage,
  PreviewPage,
  MeetingPage,
  LeavePage,
  HLSBotPage,
  AdminPage,
  ErrorPage,
  NotEligiblePage,
  NotFoundPage,
} from '../pages'
import {
  SettingsModal,
  EndMeetingModal,
  AllowAutoplayModal,
  RoleChangeModal,
} from '../components'

export const Router = () => {
  const hmsActions = useHMSActions()

  if (process.env.NODE_ENV === 'production') {
    hmsActions.setLogLevel(HMSLogLevel.ERROR)
  } else {
    hmsActions.setLogLevel(HMSLogLevel.DEBUG)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:meetingId" element={<LandingPage />} />
        <Route path="/:meetingId/preview" element={<PreviewPage />} />
        <Route path="/:meetingId/join" element={<MeetingPage />} />
        <Route path="/:meetingId/leave" element={<LeavePage />} />
        <Route path="/:meetingId/bot" element={<HLSBotPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/not-eligible" element={<NotEligiblePage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <SettingsModal />
      <RoleChangeModal />
      <EndMeetingModal />
      <AllowAutoplayModal />
    </BrowserRouter>
  )
}